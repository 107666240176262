import { 
  // eslint-disable-next-line no-unused-vars
  getFilteringColorIdentity, 
  ColorCardFilter
} from '../services/card-filters'

import { 
  BasicFetchLands, 
  BasicLand,
  BasicLandColors, 
  BasicLandTypes, 
  BasicTypeFetchLands, 
  Colorless,
  Land,
  Multicolor, 
  // eslint-disable-next-line no-unused-vars
  OrderedColorGroups, 
  // eslint-disable-next-line no-unused-vars
  OrderedColorsAndGroups,
  Special, 
  Unknown, 
  WUBRG
} from "../constants";

/**
 * Generate an Arena-legal card name for the provided card
 * 
 * @param {Object} card the card
 * @returns the Arena-legal card name
 */
export function arenaCardName(card) {
  if (isAftermath(card)) {
    // Arena expects a triple-slash for Aftermath cards        
    // 'Commit /// Memory'
    return card.name.replace(/\s*\/+\s*/, " /// ");
  }
  else if (isSplit(card)) {
    // For all other split cards, Arena expects a double-slash
    // (which should already be in the full card name)
    // 'Assure // Assemble'
    return card.name;
  }

  // extract the name, up to the first '//' for DFCs (Arena format)
  // 'Mountain'
  // 'Esika, God of the Tree' (without ' // The Prismatic Bridge')
  return card.name.split("//", 1)[0].trim();
}

/**
 * Standardize the provided card name so that internal comparisons are using the same format.
 * 
 * In particular, this normalizes slashes (//) between card face names and converts to lower case.
 * 
 * @param {string} cardName the card name
 * @returns the standardized card name
 */
export function standardizeCardName(cardName) {
  // Unlike MTGO's double-slash, Arena uses a triple-slash for _some_ cards with 
  // multiple faces (aftermath cards); make them consistent before further parsing
  const cleaned = cardName.replace(/\s*\/+\s*/, " // ")
    // normalize single (i.e., curly) quotes to apostrophes
    .replace(/[\u2018\u2019]/g, "'");
  // remove accents (and other diacritics) from the card name (e.g., Lothlórien -> Lothlorien)
  //FIXME does not convert 'Æther' -> 'AEther', see String.localeCompare:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
  const normed = cleaned.normalize('NFKD').replace(/\p{Diacritic}/gu, '')
  return normed.toLowerCase().trim();
}

/**
 * Lookup a value in the provided map using the name of the provided card, 
 * considering individual card faces if applicable.
 * 
 * @param {Object} map the key-value map
 * @param {Object} card the card
 * @returns the value from the map that matches the card name, or undefined.
 */
export function lookupByLowerCaseCardName(map, card) {
  // check map for card name
  const name = card.name.toLowerCase();
  if (map.hasOwnProperty(name)) {
    return map[name];
  }
  // if no matching name, check each card.card_faces
  else if (card.hasOwnProperty("card_faces")) {
    const matchingFace = card.card_faces.find(face => 
      map.hasOwnProperty(face.name.toLowerCase())
    );
    if (matchingFace) return map[matchingFace.name.toLowerCase()];
  }
  // else...
  return undefined;
}

/**
 * Return the card's color identity that matches {@link OrderedColorGroups}.
 * 
 * NOTE: this differs from {@link getFilteringColorIdentity}
 */
export function getSortColorIdentityGroup(card) {
  const cardColor = getSortColor(card, /* allCombinations = */ false);

  // if the card is Colorless, check its color identity
  if (cardColor === Colorless) {
    // if no color_identity defined for the card front, check the general card
    // This might happen when the card has multiple card faces with the same colors:
    // e.g., 'Animating Faerie' https://scryfall.com/card/eld/38/animating-faerie-bring-to-life
    const cardFront = getCardFront(card);
    let cardColors = cardFront.color_identity ?? card.color_identity;

    // return single-color identity or Multicolor (to match `constants.OrderedColorGroups`)
    if (cardColors.length === 1) return cardColors[0];
    else if (cardColors.length > 1) return Multicolor;
  }

  return cardColor;
}

/**
 * Return the card colors that match {@link OrderedColorGroups}, 
 * with {@link Multicolor} grouping instead of individual color combinations.
 */
export function getSortColorGroup(card) {
  return getSortColor(card, /* allCombinations = */ false);
}

/**
 * Return the card colors that match {@link OrderedColorsAndGroups} , 
 * or {@link OrderedColorGroups} if `allCombinations` is false.
 */
export function getSortColor(card, allCombinations = true) {
  // by default, sort by the visible card face
  const cardFront = getVisibleCardFront(card);

  // basic land? (using card front) but not snow basic
  if (isStandardBasicLand(cardFront)) return BasicLand;
  // nonbasic land?
  if (isLand(cardFront)) return Land;
  // sort 'Lesson' cards in their own separate `Special` column, which
  // is in both `OrderedColorGroups` and `OrderedColorsAndGroups`
  if (isLesson(cardFront)) return Special;

  // if no colors defined for the card front, explicitly get `card.colors`
  // This might happen when the card has multiple card faces with the same colors,
  // for example: 'Animating Faerie // Bring to Life'
  let cardColors = cardFront.colors ?? card.colors;

  // Aftermath cards are strange and do not have individual card_face colors so
  // when sorting we cannot directly distinguish colors between:
  //  'Destined // Lead' which is B/G and should sort as black
  //  'Driven // Despair' which is G/B and should sort as green
  if (isAftermath(card)) {
    // filter mana_cost to WUBRG symbols
    const symbols = cardFront.mana_cost.replace(/[^WUBRG]/g, '');
    // remove duplicate color symbols
    //  'Dusk // Dawn' has 2WW front and should sort as W
    cardColors = [...new Set(symbols)];
  }

  if (!cardColors) {
    // no card colors found
    return undefined;
  }
  // handle actual card colors
  else if (!allCombinations && cardColors.length > 1) {
    // specific to `constants.OrderedColorGroups`
    return Multicolor;
  }
  else {
    return toColorCombination(cardColors);
  }
}

/**
 * Get the card colors, sorted and joined to match {@link OrderedColorsAndGroups}.
 */
export function getColorCombination(card) {
  return toColorCombination(getColors(card));
}

/**
 * Sort the given colors and join together, corresponding to {@link OrderedColorsAndGroups} .
 * 
 * @param {String[]} colors 
 * @returns the corresponding color from {@link OrderedColorsAndGroups} 
 */
export function toColorCombination(colors) {
  if (!colors) return Unknown;
  else if (!colors.length) return Colorless;
  else {
    return [...new Set(colors)].sort().join('');
  }
}

/**
 * @param {Object} card 
 * @returns an array of the individual card colors.
 */
export function getColors(card) {
  let cardColors = card.colors;

  // no colors? the card colors might be on the individual card faces (e.g., MDFCs)
  if (!cardColors && card.card_faces) {
    const allFaceColors = card.card_faces.flatMap(face => face.colors);
    // remove duplicate color symbols
    //  'Dusk // Dawn' has 2WW front and should sort as W
    //  'Extus, Oriq Overlord // Awaken the Blood Avatar' should be BRW
    return [...new Set(allFaceColors)];
  }

  return cardColors;
}

/**
 * @param {*} card 
 * @returns the front face of the card or the original card if only one face.
 */
export function getCardFront(card) {
  return card.hasOwnProperty("card_faces") ? card.card_faces[0] : card;
}

/**
 * Returns the visible, *castable* front card face for the card.  If the card is transformed
 * and the back face is castable (i.e., it is a {@link getCastableCardFaces}), then return
 * the back face.  Otherwise, return the {@link getCardFront}.
 * 
 * @param {*} card 
 * @returns the current visible, *castable* front face of the card.
 */
export function getVisibleCardFront(card) {
  // use card front unless it's transformed and has multiple castable faces
  const castable = getCastableCardFaces(card);
  return (card.transformed && castable.length > 1) ? castable[1] : getCardFront(card);
}

/**
 * @param {*} card 
 * @returns an array containing the `card_faces` that have a `mana_cost` or the card itself.
 */
export function getCastableCardFaces(card) {
  if (isMdfc(card)) {
    // all faces of an MDFC are castable (even lands without a mana cost)
    return card.card_faces;
  }
  else if (card.card_faces?.length) {
    // otherwise all card faces that include a mana cost
    return card.card_faces?.filter(face => face.mana_cost?.length);
  }
  else if (card.mana_cost?.length) {
    return [card];
  }
  else {
    // no castable card face
    return [];
  }
}

/**
 * @param {*} card 
 * @returns the CMC of the visible card front face from {@link getVisibleCardFront}.
 */
export function getVisibleCardCmc(card) {
  const cardFront = getVisibleCardFront(card);
  // does the card front have CMC?
  if (cardFront.cmc) {
    return cardFront.cmc;
  }
  // does the card front have a mana_cost?
  else if (cardFront.mana_cost) {
    return cmcFromManaCost(cardFront.mana_cost);
  }
  // fallback to the high-level card CMC or zero
  return card.cmc ?? 0;
};

export function getLandColors(card) {
  // Use `card.produced_mana` for lands
  // `produced_mana` is on the card itself, not the card face
  // 'Aether Hub' is colorless but produces all colors
  //   https://api.scryfall.com/cards/named?exact=aether%20hub
  // 'Academy Ruins' has color identity U but produces colorless
  //   https://api.scryfall.com/cards/named?exact=academy%20ruins
  let produced = card.produced_mana ? card.produced_mana : [];

  // also check if this card has a standard basic land name
  // (don't need to wait to retrieve card data)
  if (isStandardBasicLand(card)) {
    produced = BasicLandColors[card.name.toLowerCase()];
  }

  // Also use card `color_identity` or `colors` for lands
  // 'Academy Ruins' produces 'C' and has color identity 'U'.
  //   https://api.scryfall.com/cards/named?exact=Academy%20Ruins
  let colors = ColorCardFilter.getFilteringColorIdentity(card);
  if (typeof colors === 'undefined') colors = [];

  // Check colors found by fetch lands
  const fetchColors = ColorCardFilter.getFetchLandColors(card);

  // return early with distinct land colors, mapped to 
  // nested arrays for each individual color for filtering
  const landColors = [...new Set([...produced, ...colors, ...fetchColors])].map(c => [c]);
  // if still no colors (i.e., lands that don't produce - Evolving Wilds)
  // then explicitly return colorless as a nested array
  return !landColors.length && isLand(card) ? [["C"]] : landColors;
}

/**
 * Calculate the CMC (mana value) from the given mana cost string.
 * 
 * @param {string} mana_cost 
 * @returns the calculated CMC (mana value)
 */
export function cmcFromManaCost(mana_cost) {
  if (mana_cost) {
    // split by pip
    const pips = mana_cost.split(/[{}]/).filter(Boolean);
    const counts = pips.map(pip => {
      const digits = pip.match(/\d+/);
      // any digit is directly the cmc value
      // this should capture each of: {1}, {10}, and {B/2} (2-brid)
      if (digits?.length > 0) return parseInt(digits[0]);
      // X (and Y and Z) have zero cmc
      // https://scryfall.com/search?q=m%3Ay+or+m%3Az
      else if (pip === 'X' || pip === 'Y' || pip === 'Z') return 0;
      // Half-mana symbol start with H (i.e., {HW} for 'Little Girl')
      else if (pip.startsWith('H')) return 0.5;
      // that leaves other pips (WUBRGC) and hybrid {B/G} and phyrexian {B/P}, {R/W/P}
      // each counts as exactly 1 cmc
      else return 1;
    });
    // sum all of the pip counts
    return counts.reduce((a, b) => a + b, 0);
  }
  else return undefined;
}

/**
 * Determine the {@link OrderedColorsAndGroups}  from the given mana cost string.
 * 
 * @param {string} mana_cost 
 * @returns the color combination from {@link OrderedColorsAndGroups} 
 */
export function colorCombinationFromManaCost(mana_cost) {
  if (mana_cost) {
    const pips = getPips(mana_cost);
    // flatten and clean half-mana color 'H' prefix (i.e., {HW} for 'Little Girl')
    const clean = pips.flat().flatMap(c => c.replace(/^H/, ''))
    return toColorCombination(clean);

  }
  else return undefined;
}

/**
 * Gets the result of  {@link getPips} for each "castable" card face of the card. 
 * A castable card face is a card face (or the card itself) that has its own `mana_cost`.
 * 
 * @param {Object} card the card 
 * @returns {string[][]} returns an array representing each castable card face, each 
 * containing a 2D array, with each nested array representing a single colored pip 
 * for the `mana_cost`.  For example:
 *  - a {1}{R} red mana cost will return `[ [ [R] ] ]`
 *  - a {2}{B}{R} multicolor card will return `[ [ [B], [R] ] ]`
 *  - a colorless card will return an empty array `[ [] ]`
 *  - a hybrid {W}{U/G} mana cost will return `[ [ [W], [U,G] ] ]`
 *  - a split card or MDFC with mana costs {1}{R} and {2}{W} will return `[ [ [R] ], [ [W] ] ]`
 */
export function getCardFacePips(card) {
  // Special case cards can be cast on either side;
  // they should be visible if either side is valid by that side's color(s)
  //  MDFC - 'Augmenter Pugilist' is G one side and U on the other
  //   https://api.scryfall.com/cards/named?exact=augmenter%20pugilist
  //  Split - 'Alive // Well' is G on one side W on the other
  //   https://api.scryfall.com/cards/named?exact=alive
  //  Aftermath - 'Appeal // Authority' is G on one side W on the other
  //   https://api.scryfall.com/cards/named?exact=appeal
  //  Adventure - 'Animating Faerie' is U with U adventure card face
  //   https://api.scryfall.com/cards/named?exact=Animating%20Faerie
  // NOTE: This is now simplified by getting pips for all of the 'castable' card faces
  return getCastableCardFaces(card).flatMap(cardFace => {
    // extract the card front's colored pips
    const pips = getPips(cardFace.mana_cost);

    // if no mana cost? (Land, Suspend-only, DFCs)
    // (also incomplete card definitions, i.e., missing cost data)
    if (typeof pips === 'undefined' || !pips.length) {
      return [ ];
    }
    else {
      return [ pips ];
    }
  });
}

/**
 * Retreieve the colored pips for the given card face. 
 * No special handling for cards with mulitple card_faces
 * 
 * @param {Object} mana_cost the card's mana cost
 * @returns {string[][]} returns an array of arrays, with each nested array 
 * representing a single colored pip for the `card.mana_cost`.  For example:
 *  - a {1}{R} red mana cost will return `[ [R] ]`
 *  - a multicolor card will return a nested array of all colors `[ [B], [R] ]`
 *  - a colorless card will return an empty array `[]`
 *  - a hybrid {W}{U/G} mana cost will return `[ [W], [U,G] ]`
 *  - a card with no `mana_cost` will return undefined (i.e., Land, Suspend-only)
 *  - a {2}{C} card with a colorless pip will return `[ [C] ]`
 */
export function getPips(mana_cost) {
  if (mana_cost) {
    // split by pip
    const pips = mana_cost.split(/[{}]/);
    // only keep WUBRG and C pips
    const wubrgc = new Set(WUBRG.concat(Colorless));
    // (and half-WUBRG) pip colors (i.e., {HW})
    WUBRG.forEach(c => wubrgc.add(`H${c}`))
    // split hybrid pips and drop post-regex empty strings and pip arrays
    const colors = pips.map(pip => pip.split(/\//).filter(Boolean))
      // keep only known colors/colorless (no digits, phyrexian, X)
      .map(pips => pips.filter(color => wubrgc.has(color)))
      // drop any empty pip "arrays" post-regex and post-color filtering
      .filter(pips => pips.length);

    return colors;
  }
  else return undefined;
}

/**
 * See also {@link isOutlaw} and {@link refersToOutlaws}
 * 
 * @param {*} card 
 * @returns true if the card creates a token that {@link isOutlaw}
 */
export function createsOutlawToken(card) {
  // expect that a related 'token' card part is created by this card
  return card.all_parts?.some(part => part.component === "token" && isOutlaw(part));
}

export function isAdventure(card) {
  return card.layout?.includes("adventure");
}

export function isAftermath(card) {
  return card.keywords?.includes("Aftermath");
}

export function isArtifact(card) {
  return card.type_line?.includes("Artifact");
}

export function isBargain(card) {
  return card.keywords?.includes("Bargain");
}

export function isBargainable(card) {
  // artifact, enchantment or (creates a) token
  return isArtifact(card) || isEnchantment(card) || 
    // if the card creates a token, the `token` should among the cards' `all_parts`
    //NOTE: this might cast too wide of a net, but is probably close and likely better than 
    //      trying to accuratel parse the `oracle_text` for "create(s)...token(s)"
    card.all_parts?.some(part => part.component === "token");
}

export function isBattle(card) {
  return card.type_line?.includes("Battle");
}

export function isCreature(card) {
  return card.type_line?.includes("Creature");
}

export function isDoubleFaced(card) {
  // Transforming DFC or Modal DFC (not split)
  return card.layout && (
    card.layout === "transform" || isMdfc(card) || isBattle(card));
}

export function isEnchantment(card) {
  return card.type_line?.includes("Enchantment");
}

export function isFetchLand(card) {
  return card.name && 
    (BasicTypeFetchLands.hasOwnProperty(card.name.toLowerCase())
      || BasicFetchLands.hasOwnProperty(card.name.toLowerCase()));
}

export function isFlip(card) {
  return card.layout?.includes("flip");
}

export function isInstantSpeed(card) {
  return card.type_line?.includes("Instant") || card.keywords?.includes("Flash");;
}

export function isLand(card) {
  return isStandardBasicLand(card) || isFetchLand(card) || card.type_line?.includes("Land");
}

export function isLearn(card) {
  return card.keywords?.includes("Learn");
}

export function isLesson(card) {
  return card.type_line?.includes("Lesson");
}

export function isLessonOrLearn(card) {
  return isLesson(card) || isLearn(card);
}

export function isMdfc(card) {
  return card.layout?.includes("modal_dfc");
}

export function isMeld(card) {
  return card.layout?.includes("meld");
}

/**
 * See also {@link createsOutlawToken} and {@link refersToOutlaws}
 * 
 * @param {*} card 
 * @returns true if the card has a creature type of Assassin, Mercenary, Pirate, Rogue, 
 * or Warlock. (Outlaws of Thunder Junction mechanic)
 */
export function isOutlaw(card) {
  // check card types: Assassin, Mercenary, Pirate, Rogue, or Warlock
  return ["Warlock", "Assassin", "Rogue", "Mercenary", "Pirate"].some(
      type => card.type_line?.includes(type)) ||
    // also consider if the card is a Changeling (any type)
    card.keywords?.includes("Changeling");
}

export function isSaga(card) {
  return card.type_line?.includes("Saga");
}

export function isSplit(card) {
  // do NOT consider it a split card if it is an Aftermath card
  // (which also have 'split' layout)
  return !isAftermath(card) && card.layout?.includes("split");
}

/**
 * Determine if the card is a standard basic land (excluding snow lands and Wastes)
 */
export function isStandardBasicLand(card) {
  // BasicLandTypes names are lower-cased, the card name may be any case;
  // convert the card name to lower case when matching
  return card.name && BasicLandTypes.some(basicLand => basicLand === card.name.toLowerCase());
}

/**
 * @param {*} card 
 * @returns true if the card text includes a reference to energy counters.
 */
export function refersToEnergy(card) {
  // check if the card contains the energy symbol {E} in full `oracle_text`
  return card.oracle_text?.includes("{E}");
}

/**
 * @param {*} card 
 * @returns true if the card cares about outlaws.
 */
export function refersToOutlaws(card) {
  // check if the card contains " are outlaws" in full `oracle_text` as part of the reminder
  // text: (Assassins, Mercenaries, Pirates, Rogues, and Warlocks are outlaws.)
  return card.oracle_text?.includes(" are outlaws");
}
