import { 
  getCardFront, 
  getLandColors,
  getPips, 
  isAdventure,
  isAftermath, 
  isInstantSpeed,
  isLand, 
  isMeld,
  isMdfc, 
  isSplit,
  isLessonOrLearn,
  isBargain,
  isBargainable,
  isOutlaw,
  refersToOutlaws,
  createsOutlawToken,
  refersToEnergy
} from '../helpers/card.helpers';
import { 
  BasicFetchLands, 
  BasicLandColors, 
  BasicTypeFetchLands
} from '../constants';

/**
 * Card Filter Types
 */
export class FilterType {
  /**
   * NOTE: These FilterTypes must be implemented in {@link CardFilter.fromJSON}
   */
  static Color = new FilterType('Color');
  static ColorStrict = new FilterType('ColorStrict');
  static CardType = new FilterType('CardType');
  static InstantSpeed = new FilterType('InstantSpeed');
  static Rarity = new FilterType('Rarity');

  static Bargain = new FilterType('Bargain');
  static Bargainable = new FilterType('Bargainable');
  static Energy = new FilterType('Energy');
  static LessonLearn = new FilterType('Lesson+Learn');
  static Outlaw = new FilterType('Outlaw');
  
  constructor(name) {
    this.name = name;
  }

  toString() {
    return `FilterType:${this.name}`;
  }
}

/**
 * The CardFilter base class.
 */
export default class CardFilter {

  /**
   * Deserialize the appropriate CardFilter from the provided card filter JSON data.
   * 
   * @param {Object} filterJson  
   * @returns the deserialized CardFilter
   */
  static fromJSON(filterJson) {
    if (filterJson.type.name === FilterType.Color.name) {
      return new ColorCardFilter(filterJson.value);
    } else if (filterJson.type.name === FilterType.ColorStrict.name) {
      return new StrictColorCardFilter();
    } else if (filterJson.type.name === FilterType.CardType.name) {
      return new TypeCardFilter(filterJson.value);
    } else if (filterJson.type.name === FilterType.InstantSpeed.name) {
      return new InstantSpeedCardFilter();
    } else if (filterJson.type.name === FilterType.Rarity.name) {
      return new RarityCardFilter(filterJson.value);
    } else if (filterJson.type.name === FilterType.LessonLearn.name) {
      return new LessonLearnCardFilter();
    } else if (filterJson.type.name === FilterType.Bargain.name) {
      return new BargainCardFilter();
    } else if (filterJson.type.name === FilterType.Bargainable.name) {
      return new BargainableCardFilter();
    } else if (filterJson.type.name === FilterType.Outlaw.name) {
      return new OutlawCardFilter();
    } else if (filterJson.type.name === FilterType.Energy.name) {
      return new EnergyCardFilter();
    }
  }

  constructor(type, value) {
    this.type     = type;
    this.value    = value;
  }

  isVisible = (card) => true;

  /**
   * Determine if this filter matches on the filter type and value (if value is provided)
   */
  matches = (type, value) => (this.type === type && (!value || this.value === value));

  toString() {
    return `CardFilter.${this.type}.${this.value}`;
  }
}

/**
 * A CardFilter that filters by card color symbol (e.g., WUBRGC)
 */
export class ColorCardFilter extends CardFilter {

  static evaluateAny(card, ...colorFilters) {
    const filters = colorFilters.flat();
    if (!filters.length) return true;

    // calculate all of the filtering color combinations (i.e., considering hybrid premutations)
    // this will be an array of arrays, where each nested array is one valid card color
    const manaCostOptions = ColorCardFilter.getFilteringColorCombinations(card);

    // card is visible if visible by ANY active ColorCardFilter
    return manaCostOptions.some(cardColors =>
      cardColors.length && cardColors.some(color => filters.some(f => f.isVisible(color)))
    );
  }

  static evaluateOnly(card, ...colorFilters) {
    // flatten filters in case passed individually or as an array
    const filters = colorFilters.flat();
    if (!filters.length) return true;

    // handle the colorless filter separately, first partition the filters
    const colorlessIdx = filters.findIndex(f => f.value === "C");
    const colorlessFilter = colorlessIdx >= 0 ? filters[colorlessIdx] : undefined;
    const wubrgFilters = filters.slice(0, colorlessIdx).concat(filters.slice(colorlessIdx+1));

    // helper method to check that every card color is represented by some active filter
    const checkAllColors = (cardColors) => {
      return cardColors.length && cardColors.every(color => wubrgFilters.some(f => f.isVisible(color)));
    }

    // helper method to check that every active filter is represented by some card color
    const checkAllFilters = (cardColors) => {
      return wubrgFilters.every(f => cardColors.some(f.isVisible));
    }

    // calculate all of the filtering color combinations (i.e., considering hybrid premutations)
    // this will be an array of arrays, where each nested array is one valid card color
    const manaCostOptions = ColorCardFilter.getFilteringColorCombinations(card);

    // if the colorless filter is active, check the card's mana costs
    const isColorlessValid = colorlessFilter && manaCostOptions.some(colors => colors.some(colorlessFilter.isVisible));

    // either every active filter is valid (but more card colors are allowed)..
    const everyFilterValid = manaCostOptions.some(checkAllFilters);
    // ..or every card color (for one permutation) is an active filter (but more filters are allowed)
    const allColorsValid = manaCostOptions.some(checkAllColors);

    if (colorlessFilter) {
      // if the colorless filter is active, check colorless or color filters (if any color filters active)
      return isColorlessValid || (wubrgFilters.length && everyFilterValid) || allColorsValid;
    }
    else {
      return (wubrgFilters.length && everyFilterValid) || allColorsValid;
    }
  }

  /**
   * 
   * @param {Object} card the card
   * @returns {string[][]} returns an array of arrays, with each nested array 
   * representing all combinations of colors for the card.  For example:
   *  - a {1}{R} red mana cost will return `[ [R] ]`
   *  - a multicolor card will return a single nested array with all colors `[ [B,R] ]`
   *  - a colorless card will return an array with 'C' for colorless `[ [C] ]`
   *  - a devoid card will return two options: the color and 'C' for colorless: `[ [W], [C] ]`
   *  - a hybrid {W}{U/G} mana cost will return a nested array for each option: `[ [W,U], [W,G] ]`
   *  - a {G} // {U}{B} MDFC will return a nested arrays for each option `[ [G], [U,B] ]`
   */
  static getFilteringColorCombinations(card) {
    // Special handling for cards that can be cast on either side;
    // they should be visible if either side is valid by that side's color(s)
    //  MDFC - 'Augmenter Pugilist' is G one side and U on the other
    //   https://api.scryfall.com/cards/named?exact=augmenter%20pugilist
    //  Split - 'Alive // Well' is G on one side W on the other
    //   https://api.scryfall.com/cards/named?exact=alive
    //  Aftermath - 'Appeal // Authority' is G on one side W on the other
    //   https://api.scryfall.com/cards/named?exact=appeal
    //   only filter aftermath cards by the "front" color
    //  Adventure - 'Callous Sell-Sword // Burn Together' is a multicolor adventure
    //   with B for the main card and R for the adventure.
    if (isSplit(card) || isMdfc(card) || isAftermath(card) || isAdventure(card)) {
      // explicitly check for MDFC dual lands, which have colors in `card.color_identity`
      if (card.type_line && card.type_line === "Land // Land") {
        return card.color_identity.map(c => [c]);
      }

      // get combinations from the mana_cost of each individual card face
      // if a card face is a land, get the land filtering colors for the card
      // not the card face because that is where `produced_mana` is located
      const frontFace = card.card_faces[0];
      const frontColors = isLand(frontFace) ?
        getLandColors(card) : ColorCardFilter.getFilteringColorCombinations(frontFace);
      const backFace = card.card_faces[1];
      const backColors = isLand(backFace) ?
        getLandColors(card) : ColorCardFilter.getFilteringColorCombinations(backFace);

      // combine options and return early
      return frontColors.concat(backColors);
    }

    // if not handled above, consider only the card front
    const cardFront = getCardFront(card);
    // extract the card front's colored pips
    const pips = getPips(cardFront.mana_cost);

    // if no mana cost? (Land, Suspend-only, Meld)
    // (also incomplete card definitions, i.e., missing cost data)
    if (typeof pips === 'undefined') {
      // Get colors for lands
      if (isLand(card)) {
        return getLandColors(card);
      }

      let noCostColors = undefined;
      if (isMeld(card)) {
        // If card is melded, get the meld card's color (NOT color identity)
        noCostColors = cardFront.colors;
      }
      else {
        // If not a Land or Meld card, use the `color_identity` for filtering
        noCostColors = ColorCardFilter.getFilteringColorIdentity(card);
      }

      // if still `undefined`, return an empty array (different from colorless)
      if (typeof noCostColors === 'undefined') return [];

      // Still colorless?  Make it explicit (not an empty array)
      //  'Lotus Bloom' is colorless with colorless suspend cost
      //  https://api.scryfall.com/cards/named?exact=lotus%20bloom
      if (!noCostColors.length) noCostColors.push('C')

      // map colors to nested arrays for each individual color for filtering
      return noCostColors.map(c => [c]);
    }

    // calculate all permutations from the card/front's colored pips
    // clean half-mana color 'H' prefix (i.e., {HW} for 'Little Girl')
    const clean = pips.map(pip => pip.map(c => c.replace(/^H/, '')));
    const pipPermutations = ColorCardFilter.cartesian(...clean);

    // if the card (front) has 'colors' defined, but empty then it is colorless/devoid; 
    // include its color identity and also explicitly include 'C' for colorless
    if (cardFront.hasOwnProperty("colors") && !cardFront.colors.length) {
      // specifically concat ['C'] for colorless
      // with concat extracted/permuted pips here (e.g., hybrid devoid?)
      const colorless = pipPermutations.concat([ ['C'] ])

      // If there are no colors in the mana cost (pipPermutations is empty; 
      // to avoid including a devoid card with color requirements in mana cost)
      // AND the colorless card produces mana, then include those colors individually:
      //  'Zagoth Crystal' is a colorless artifact that produces B,G,U:
      //   https://api.scryfall.com/cards/named?exact=zagoth%20crystal
      // 
      // BUT do not include cards that produce *all* colors; filtering is only
      // useful when it matches a subset of the colors, i.e., NOT:
      //  'Careening Mine Cart' which produces a Treasure (and all colors):
      //   https://api.scryfall.com/cards/named?exact=careening%20mine%20cart
      //  'Treasure Map' which produces all colors AND colorless:
      //   https://api.scryfall.com/cards/named?exact=treasure%20map
      //NOTE: `produced_mana` appears to always be on the card itself
      // See: https://scryfall.com/search?q=is%3Adfc+c%3Dc+is%3Abooster+-t%3Aland+produces%3Ar
      const produced = card.produced_mana?.sort().join('');
      if (!pipPermutations.length && produced && produced !== "BGRUW" && produced !== "BCGRUW") {
        // concat the individual produced colors
        const producedColors = card.produced_mana.map(c => [c]);
        return colorless.concat(producedColors);
      }

      // Other colorless examples:
      // 'Weldfast Monitor' is a colorless artifact creature with red color identity:
      //   https://api.scryfall.com/cards/named?exact=Weldfast%20Monitor
      //  filter as red and colorless
      // 'Blisterpod' is a colorless (devoid) card with green color identity:
      //   https://api.scryfall.com/cards/named?exact=Blisterpod
      //  filter as green and colorless
      // 'Farfinder' is a colorless card with undefined `color_identity`:
      //   https://api.scryfall.com/cards/named?exact=farfinder
      //  filter as colorless, don't include color_identity
      // 'Writhing Chrysalis' is a colorless (devoid) card with multicolor (RG) identity:
      //   https://scryfall.com/card/mh3/208/writhing-chrysalis
      // 'Wastescape Battlemage' is a colorless card with multicolor identity (U/G kicker costs):
      //   https://scryfall.com/card/mh3/208/writhing-chrysalis
      return cardFront.color_identity ? colorless.concat([ cardFront.color_identity ]) : colorless;
    }

    return pipPermutations;
  }

  /**
   * @returns an array of card colors that match the desired filtering behavior.  
   * Returns `undefined` when both `color_identity` and `colors` are undefined.
   * 
   * NOTE: this differs from `getCardColorIdentityCategories` in card.helpers.js
   */
  static getFilteringColorIdentity(card) {
    // Use card/front `color_identity` or `colors`
    const cardFront = getCardFront(card);
    // 'Westvale Abbey // Ormendahl, Profane Prince' is a colorless land 
    //  that transforms into a black creature:
    //   https://api.scryfall.com/cards/named?exact=Westvale%20Abbey
    //  to filter as colorless we need `cardFront.colors` (NOT `card.colors` or `card.color_identity`)
    // 'Ancestral Vision' is a blue card with no mana cost and suspend for {U}, filter as blue
    //   https://api.scryfall.com/cards/named?exact=ancestral-vision
    return cardFront.color_identity ? cardFront.color_identity : cardFront.colors;
  }

  static getFetchLandColors(card) {
    const colors = new Set();
    const name = card.name.toLowerCase();
    // if this is a fetch land for basic lands, add the mana colors
    if (BasicFetchLands.hasOwnProperty(name)) {
      const basicfetches = BasicFetchLands[name];
      basicfetches.forEach(land => {
        const mana = BasicLandColors[land];
        colors.add(mana);
      });
    }
    // if this card is a fetch land for basic land *types*, add the mana colors
    if (BasicTypeFetchLands.hasOwnProperty(name)) {
      const basictypefetches = BasicTypeFetchLands[name];
      basictypefetches.forEach(land => {
        const mana = BasicLandColors[land];
        colors.add(mana);
      });
    }
    return [...colors];
  }

  static cartesian(...args) {
    if (!args.length) return [];

    var r = [], max = args.length-1;
    const helper = (arr, i) => {
      for (let j=0, l=args[i].length; j<l; j++) {
        let a = arr.slice(0); // clone arr
        a.push(args[i][j]);
        if (i===max)
          r.push(a);
        else
          helper(a, i+1);
      }
    }
    helper([], 0);
    return r;
  }

  constructor(symbol) {
    super(FilterType.Color, symbol);
  }

  /**
   * CardColorFilter.isVisible expects a color not a card
   * 
   * @param {string} color a single character representing WUBRG or C
   * @returns true if the color matches the filter value
   */
  isVisible = (color) => this.value === color;

}

/**
 * A CardFilter that indicates that cards should be strict-filtered by color.
 * 
 * This is kind of a *HACK* because it doesn't actually filter cards, it just 
 * indicates that a certain type of filtering is active.
 */
 export class StrictColorCardFilter extends CardFilter {

  constructor() {
    super(FilterType.ColorStrict);
  }

}


/**
 * A CardFilter that filters by card type line
 */
export class TypeCardFilter extends CardFilter {

  constructor(cardType) {
    super(FilterType.CardType, cardType);
  }

  isVisible = (card) => {
    // `true` means visible
    return card.type_line && 
      // convert types to upper case before comparing (case insensitive)
      card.type_line.toUpperCase().includes(this.value.toUpperCase());
  }

}

/**
 * A CardFilter that filters by card rarity
 */
 export class RarityCardFilter extends CardFilter {

  constructor(cardRarity) {
    super(FilterType.Rarity, cardRarity);
  }

  isVisible = (card) => {
    // `true` means visible
    return card.type_line && 
      // convert types to upper case before comparing (case insensitive)
      card.rarity.toLowerCase() === this.value.toLowerCase();
  }

}

/**
 * A CardFilter that filters by cards that can be cast at instant speed 
 * (Instant card types or cards with Flash).
 */
export class InstantSpeedCardFilter extends CardFilter {

  constructor() {
    super(FilterType.InstantSpeed);
  }

  // `true` means visible
  isVisible = (card) => isInstantSpeed(card);

}

/**
 * A CardFilter that filters by Lesson or Learn card types
 */
export class LessonLearnCardFilter extends CardFilter {

  constructor() {
    super(FilterType.LessonLearn);
  }

  // `true` means visible
  isVisible = (card) => isLessonOrLearn(card);

}

/**
 * A CardFilter that filters by cards with the 'Bargain' keyword
 */
export class BargainCardFilter extends CardFilter {

  constructor() {
    super(FilterType.Bargain);
  }

  // `true` means visible
  isVisible = (card) => isBargain(card);

}

/**
 * A CardFilter that filters by cards that can be used as bargain fodder.
 */
export class BargainableCardFilter extends CardFilter {

  constructor() {
    super(FilterType.Bargainable);
  }

  // `true` means visible
  isVisible = (card) => isBargainable(card);

}

/**
 * A CardFilter that filters by cards with creature type of Assassin, Mercenary,
 * Pirate, Rogue, or Warlock AND cards that care about outlaws.
 * (Outlaws of Thunder Junction mechanic)
 */
export class OutlawCardFilter extends CardFilter {

  constructor() {
    super(FilterType.Outlaw);
  }

  // `true` means visible
  isVisible = (card) => isOutlaw(card) || createsOutlawToken(card) || refersToOutlaws(card);

}

/**
 * A CardFilter that filters by cards that produce or use Energy.
 */
export class EnergyCardFilter extends CardFilter {

  constructor() {
    super(FilterType.Energy);
  }

  // `true` means visible
  isVisible = (card) => refersToEnergy(card);

}
