import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';

import ButtonLink from './ButtonLink';
import Scryfall from '../../services/scryfall';

import './ScryfallSetInputGroup.css';

/**
 * Custom dropdown indicator for opening the select that uses
 * the FontAwesome caret pointing down to match {@link DropDownButton}
 */
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FontAwesomeIcon icon={faCaretDown} />
    </components.DropdownIndicator>
  );
};

/**
 * A group of input controls for creating a new set-specific card pool 
 * (i.e., full set card pool or tier list) based on known Scryfall draft sets.
 */
export default function ScryfallSetInputGroup({ disabled }) {

  const [scryfallSets, setScryfallSets] = useState();

  const [selectedSet, setSelectedSet] = useState();

  useEffect(() => {
    Scryfall.fetchAllDraftSets().then(sets => {
      const options = sets.map(set =>
        // map to value and label for react-select
        ({
          value: set.code,
          label: `${set.code?.toUpperCase()} - ${set.name}`
        })
      );
      setScryfallSets(options);
    });
  }, []);

  const onSetSelected = (option) => {
    setSelectedSet(option.value);
  }

  // create the 'Full Set' and 'Tier List' links
  // Full Set
  const linkFullSet = <ButtonLink
      className="set-link-full-set"
      title="Open Full Set"
      disabled={!selectedSet}
      href={`/sets/${selectedSet}`}
    >Full Set</ButtonLink>;
  // Tier List
  const linkTierList = <ButtonLink 
      className="set-link-tier-list"
      title="Open Tier List"
      disabled={!selectedSet}
      href={`/sets/${selectedSet}/tierlists`}
    >Tier List</ButtonLink>;

  return (
    <div className="set-input-group">
      <Select
        className="set-input-select"
        classNamePrefix="set-input-select"
        placeholder={scryfallSets?.length ? "Choose Set..." : "Loading..."}
        components={{ DropdownIndicator }}
        isDisabled={disabled || !scryfallSets?.length}
        isLoading={!scryfallSets?.length}
        options={scryfallSets}
        onChange={onSetSelected}
        // necessary for changing the z-index of the react-select dropdown menu
        // in CSS at .set-input-select__menu-portal
        // https://stackoverflow.com/a/63898744/186818
        menuPortalTarget={document.body} 
      />
      <div className="set-input-actions">
        {linkFullSet} {linkTierList}
      </div>
    </div>
  );
};
