import React from 'react';

import Column, { LastColumn } from './Column';
import DropDownButton, { DropDownButtonItem } from '../components/widgets/DropDownButton';

import { 
  cardsToColumnsByCmc,
  cardsToColumnsByColor,
  cardsToColumnsByColorCombination,
  cardsToColumnsByColorIdentity,
  cardsToColumnsByGihwr,
  cardsToColumnsByRarity,
  cardsToColumnsByRating
} from '../helpers/card-pool.helpers';
import { GIH_WR_PROPERTY, RATING_SCORES_PROPERTY, RATING_TIER_PROPERTY } from '../constants';
import { CARD_WIDTH_PX } from '../components/Card';

const LOCAL_STORAGE_COLOR_SORT_PREFIX = 'color-dropdown-index-';

/**
 * Timeout value used to delay recalculating/rendering columns after window resize
 */
const TIMEOUT_RESIZE_DELAY = 100;

export default class SortableColumnList extends React.Component {

  constructor(props) {
    super(props);
    this.name = "Sortable Column List";

    // create a ref for the card pool <div/>
    this.cardPoolDivRef = React.createRef();
  }

  componentDidMount() {
    // register to handle the window resize event
    window.addEventListener("resize", () => {
      // use a timer so that the resize event doesn't trigger too frequently
      clearTimeout(this.windowResizeTimer);
      this.windowResizeTimer = setTimeout(this.recalculateColumnCount, TIMEOUT_RESIZE_DELAY);
    });
    // also calcualte the column count now!
    this.recalculateColumnCount();
  }

  componentWillUnmount() {
    // make sure to clear the window resize timer
    clearTimeout(this.windowResizeTimer);
  }
  
  recalculateColumnCount = () => {
    // determine the number of columns based on the space available
    const columnsThatFit = Math.floor(this.cardPoolDivRef?.current?.clientWidth / CARD_WIDTH_PX);
    this.setState({ minimumColumnCount: columnsThatFit })
  }

  render() {
    return (
      <React.Fragment>
        <div className="pool-header">
          <span className="pool-title">
            {this.renderTitle()}
          </span>
          {this.renderPoolDetails()}
          {this.renderPoolControls()}
        </div>
        <div ref={this.cardPoolDivRef} className="pool-scrollable">
          {this.renderPool()}
        </div>
      </React.Fragment>
    );
  }

  renderTitle() {
    return <>{this.name}: {this.renderCardCount()}</>;
  }

  renderCardCount() {
    if (this.props.isFiltering) {
      const numVisibleCards = this.allPoolCards().length;
      return <>
        <span className="pool-filtered-count">{this.props.totalCardCount}</span> {numVisibleCards}
      </>;
    }
    else if (this.props.totalCardCount !== undefined) {
      return this.props.totalCardCount;
    }
    else {
      return this.allPoolCards().length;
    }
  }

  renderPoolDetails() {
    return <></>;
  }

  renderPoolControls() {
    return this.renderSortControls();
  }

  renderSortControls() {
    return (
      <span className="pool-controls">
        Sort by: 
        <button disabled={this.props.disabled} onClick={this.sortByCMC}>Mana Value</button>
        {this.renderSortByColorControl()}
        <button disabled={this.props.disabled} onClick={this.sortByRarity}>Rarity</button>
        {this.maybeRenderSortByGihwrControl()}
        {this.maybeRenderSortByRatingsControl()}
      </span>
    );
  }

  renderSortByColorControl() {
    // specify the index of the desired selected item (from local storage)
    const selectedColorItemIndex = this.getSavedColorDropDownIndex();
    return <>
      <DropDownButton 
          disabled={this.props.disabled}
          selectedItemIndex={selectedColorItemIndex}
          onChange={this.handleChangeColorSort}>
        <DropDownButtonItem onClick={this.sortByColor} >
          Color
        </DropDownButtonItem>
        <DropDownButtonItem onClick={this.sortByColorIdentity} 
            // default to Color+Identity if no other index specified
            selected={!selectedColorItemIndex}>
          Color+Identity
        </DropDownButtonItem>
        <DropDownButtonItem onClick={this.sortByColorCombination}>
          Multicolor
        </DropDownButtonItem>
      </DropDownButton>
    </>;
  }

  maybeRenderSortByGihwrControl() {
    if (this.gameInHandWinRateVisible()) {
      return <button 
          disabled={this.props.disabled} 
          onClick={this.sortByGihwr} 
          title="Games in Hand Win Rate"
        >GIH WR</button>;
    }
  }

  maybeRenderSortByRatingsControl() {
    if (this.ratingsVisible()) {
      return <button 
          disabled={this.props.disabled} 
          onClick={this.sortByRating}
        >Rating</button>;
    }
  }

  renderPool() {
    if (this.props.splitColumns) {
      return (
        <React.Fragment>
          {this.renderSplitTopColumns(this.props.columns)}
          {this.renderSplitBottomColumns(this.props.splitColumns)}
        </React.Fragment>
      );
    }
    else {
      return this.renderColumns(this.props.columns);
    }
  }

  renderSplitTopColumns = (columns) => this.renderColumns(columns, "columns", "split-top");

  renderSplitBottomColumns = (columns) => this.renderColumns(columns, "splitColumns", "split-bottom");

  renderColumns(columns, columnGroup = "columns", ...extraClassNames) {
    const poolClassNames = extraClassNames.concat("pool-columns");
    if (this.props.disabled) poolClassNames.push("pool-disabled");
    if (this.props.isFiltering) poolClassNames.push("pool-filtered");
    if (this.props.error) poolClassNames.push("error");

    // skip render processing for error state (still render empty columns)
    if (this.props.error) {
      return <div className={poolClassNames.join(" ")}></div>;
    }

    const columnsToRender = this.prepareColumnsToRender(columns);
    // Generate an ID for a 'last' column, which is always added at the end and styled differently
    const lastColumnId = this.props.createDroppableId(this.props.id, columnGroup, columnsToRender.length);

    // create a Column for each visible column and always add one "last" empty column droppable
    return (
      // the wrapping cardpool <div/>
      <div className={poolClassNames.join(" ")}>
        {columnsToRender.map((col, idx) => {
          const columnId = this.props.createDroppableId(this.props.id, columnGroup, idx);
          return this.renderColumn(col, columnId);
        })}
        <LastColumn 
            className="last" 
            key={lastColumnId}
            id={lastColumnId}
            cards={[]} />
      </div>
    );
  }

  prepareColumnsToRender(columns) {
    // add extra empty columns up to one less than the minimum (because always add a <LastColumn />);
    // the minimum is calculated from the browser width (see `recalculateColumnCount`)
    const normalColumnCount = this.state?.minimumColumnCount - 1;
    const columnsPadded = [...columns];
    while (!!normalColumnCount && columnsPadded.length < normalColumnCount) {
      columnsPadded.push({ cards: [] });
    }

    return columnsPadded;
  }

  renderColumn(column, columnId) {
    return <Column
        key={columnId} 
        id={columnId} 
        cards={column.cards} 
        onCardTransform={this.props.onCardTransform}
        onCardDoubleClick={this.props.onCardDoubleClick} 
      />;
  }

  gameInHandWinRateVisible() {
    return this.allPoolCards().some(card => 
      !!card[GIH_WR_PROPERTY]
    );
  }

  ratingsVisible() {
    return this.allPoolCards().some(card => 
      card[RATING_TIER_PROPERTY] || (card[RATING_SCORES_PROPERTY]?.length)
    );
  }

  allPoolCards() {
    const columnCards = this.columnCards();
    const splitCards = this.splitColumnCards();
    return !splitCards ? columnCards : columnCards.concat(splitCards);
  }

  columnCards() {
    return this.props.columns.flatMap(col => col && col.cards ? col.cards : []);
  }

  splitColumnCards() {
    if (!this.props.splitColumns) return undefined;

    return this.props.splitColumns.flatMap(col => col.cards)
  }

  sortByCMC = () => {
    this.props.onSortBy(cardsToColumnsByCmc);    
  }
  
  sortByColor = () => {
    this.props.onSortBy(cardsToColumnsByColor);
  }

  sortByColorCombination = () => {
    this.props.onSortBy(cardsToColumnsByColorCombination);
  }
  
  sortByColorIdentity = () => {
    this.props.onSortBy(cardsToColumnsByColorIdentity);
  }
  
  sortByGihwr = () => {
    this.props.onSortBy(cardsToColumnsByGihwr);
  }

  sortByRarity = () => {
    this.props.onSortBy(cardsToColumnsByRarity);
  }

  sortByRating = () => {
    this.props.onSortBy(cardsToColumnsByRating);
  }

  handleChangeColorSort = (index) => {
    localStorage.setItem(`${LOCAL_STORAGE_COLOR_SORT_PREFIX}${this.props.id}`, index);
  }

  getSavedColorDropDownIndex() {
    return localStorage.getItem(`${LOCAL_STORAGE_COLOR_SORT_PREFIX}${this.props.id}`);
  }

}
