import { SET_RATINGS as DARKEST_MAGE_SET_RATINGS } from './constants/darkest-mage';

/**
 * Expected Darkest Mage column headers (lower case)
 */
const DARKEST_MAGE_CARD_NAME    = "card name";
const DARKEST_MAGE_RATING       = "rating";
const DARKEST_MAGE_REVISED_COLS = ["revised", "revised rating", "new rating"];

const GOOGLE_SHEETS_URI = 'https://sheets.googleapis.com/v4/spreadsheets'

const bent = require('bent')
const sheetsGET = bent(GOOGLE_SHEETS_URI, 'json', 200);

export default class GoogleSheets {

  static spreadsheetUrl(spreadsheet_id) {
    return `https://docs.google.com/spreadsheets/d/${spreadsheet_id}`;
  }

  static async darkestMageRatings(set) {
    if (!set) {
      return Promise.reject(new Error('No Darkest Mage tier list set code provided'));
    }
    else {
      // check if the ratings are already saved for this set (from the session)
      const sessionKey = `darkest-mage-${set}`;
      const savedSetRatings = sessionStorage.getItem(sessionKey);
      if (savedSetRatings) {
        console.info(`Restoring [${set.toUpperCase()}] card ratings data from session state ${sessionKey}`);
        const json = JSON.parse(savedSetRatings);
        return Promise.resolve(json);
      }
      else if (!DARKEST_MAGE_SET_RATINGS[set]?.ratings_id) {
        return Promise.reject(
          new Error(`No Google spreadsheet configured for DarkestMage set [${set.toUpperCase()}]`,
            { set }));
      }
      else {
        const spreadsheetId = DARKEST_MAGE_SET_RATINGS[set].ratings_id;
        console.info(`Retrieving DarkestMage set [${set.toUpperCase()}] card ratings ` + 
          `from Google spreadsheet ${spreadsheetId}`);
        const spreadsheetPath = `/${spreadsheetId}?key=${process.env.REACT_APP_GOOGLE_AUTH_KEY}`;

        // find the individual sheet names/titles for this set
        const sheetNames = sheetsGET(spreadsheetPath)
          .then(spreadsheets => spreadsheets.sheets.map(sheet => sheet.properties.title));

        // get the column headers on each sheet
        const sheetHeaders = sheetNames.then(sheetTitles => 
          GoogleSheets.sheetHeaders(spreadsheetId, sheetTitles)
        );

        // find the columns that contain ratings and card names; retrieve the full columns
        return Promise.all([sheetNames, sheetHeaders])
          .then(([sheetNames, sheetHeaders]) =>
            GoogleSheets.ratingsColumns(spreadsheetId, sheetNames, sheetHeaders))
          // convert ratings column groups into JSON ratings, i.e., { "name": xxx, ratings: [xxx] }
          .then(GoogleSheets.toRatingsJson)
          .then((json) => {
            // store the json ratings for this set in session storage
            try {
              sessionStorage.setItem(sessionKey, JSON.stringify(json));
            }
            catch (error) {
              console.warn("Unable to save card ratings data to session storage.", error);
            }
            return json;
          })
          .catch((error) => {
            return Promise.reject(
              new Error(`Unable to load card ratings data for set [${set.toUpperCase()}] ` +
                  `from Google spreadsheet '${spreadsheetId}'`,
                  { set, spreadsheetId, cause: error })
            );
          });
      }
    }
  }

  static async toRatingsJson(ratingsColumnsGroups) {
    return ratingsColumnsGroups.flatMap(columns => {
      const safeSlice = (arr, idx) => (idx >= 0 && idx < arr.length) ? arr[idx].slice(1) : [];
      // find the card name and ratings columns
      const cardNameColumnIdx = columns.findIndex(column => column[0].toLowerCase() === DARKEST_MAGE_CARD_NAME);
      const cardNames = safeSlice(columns, cardNameColumnIdx);
      const ratingColumnIdx = columns.findIndex(column => column[0].toLowerCase() === DARKEST_MAGE_RATING);
      const ratings = safeSlice(columns, ratingColumnIdx);
      // Find first among 'Revised' or 'Revised Rating' or 'New Rating'
      const revisedColumnIdx = columns.findIndex(column => DARKEST_MAGE_REVISED_COLS.includes(column[0].toLowerCase()));
      const revisedRatings = safeSlice(columns, revisedColumnIdx);

      // helper function to clean ratings:
      // split on whitespace, parentheses, and forward-slash (KHM)
      // grab *all* non-empty values - assume the first is the regular rating; second is synergy
      const cleanRatings = (rating) => rating.trim().split(/[\s()/]/).filter(r => r);

      // map card names with corresponding ratings (if any)
      return cardNames.map((name, index) => {
        const ratingsJson = { name: name };
        const rating = ratings[index];
        const revised = revisedRatings[index];
        // if the revised rating exists use it, otherwise the normal rating
        if (revised) {
          ratingsJson.ratings = cleanRatings(revised);
        }
        else if (rating) {
          ratingsJson.ratings = cleanRatings(rating);
        }
        return ratingsJson;
      }).filter(json => json.ratings); // filter any empty ratings
    });
  }

  static async ratingsColumns(spreadsheetId, sheetTitles, sheetHeaders) {
    const RATINGS_COLUMNS = new Set([DARKEST_MAGE_CARD_NAME, DARKEST_MAGE_RATING, ...DARKEST_MAGE_REVISED_COLS]);
  
    const sheetRanges = sheetTitles.map((title, idx) => {
      // get the header columns corresponding to this sheet (by index)
      const columns = sheetHeaders[idx];

      // identify which column indexes have the ratings columns that we want
      const ratingsColumnIndexes = columns?.map((column, index) => {
          if (RATINGS_COLUMNS.has(column.toLowerCase())) return index;
          return undefined;
        })
        .filter(index => index !== undefined);

      if (ratingsColumnIndexes?.length) {
        // construct the columns ranges for selecting the desired columns
        return GoogleSheets.allColumnsRange(title, ratingsColumnIndexes);
      }
      else return [];
    });

    const queryRanges = sheetRanges.flat().map(range => `ranges=${range}`).join('&');
    const batchRatingsColumns = `/${spreadsheetId}/values:batchGet?${queryRanges}&majorDimension=COLUMNS` +
      `&key=${process.env.REACT_APP_GOOGLE_AUTH_KEY}`;
    return sheetsGET(batchRatingsColumns)
      // extract the returned column values from each sheet's result range
      .then(results => results.valueRanges.map(ranges => ranges.values));
  }

  static async sheetHeaders(spreadsheetId, sheetTitles) {
    // using the title of each individual sheet, get the headers (first row)
    const FIRST_ROW = "1:1";
    const sheetRanges = sheetTitles.map(sheet => `ranges=${sheet}!${FIRST_ROW}`)
    const batchFirstRows = `/${spreadsheetId}/values:batchGet?${sheetRanges.join("&")}` +
      `&key=${process.env.REACT_APP_GOOGLE_AUTH_KEY}`;
    return sheetsGET(batchFirstRows)
      // extract the returned first row values (headers) from each sheet's results
      .then(results => results.valueRanges.map(ranges => ranges.values?.[0]));
  }

  static allColumnsRange = (sheet, columnIndexes) => {
    const firstColumn = GoogleSheets.columnLetter(Math.min(...columnIndexes));
    const lastColumn = GoogleSheets.columnLetter(Math.max(...columnIndexes));
    return `${sheet}!${firstColumn}:${lastColumn}`;
  }

  static columnLetter = (columnIdx) => String.fromCharCode(columnIdx + 'A'.charCodeAt(0));
  
}