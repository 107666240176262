/**
 * Google Spreadsheets metadata to retrieve Darkest Mage card ratings.
 * 
 * The actual spreadsheet URL is of the pattern: https://docs.google.com/spreadsheets/d/[ratings_id]
 */
export const SET_RATINGS = Object.freeze({
  khm: { // Kaldheim - 'New Rating'
    //youtube: "https://www.youtube.com/playlist?list=PL67qBli6QyKsF5dCyJfiS6fHgBN4qROVp",
    ratings_id: "1Xt2rwjevuKjoIVkTSyVAD5yDlNmPbG86MdbD177U6oY"
  },
  stx: { // Strixhaven - 'Revised Rating'
    //youtube: "https://www.youtube.com/playlist?list=PL67qBli6QyKvmlS1vPwVrfRfY2kKpelyj",
    ratings_id: "1xrJYnyMjplvdvYh8NipA6uOHnBw75zZGJWrhKibQ86M"
  },
  afr: { // Adventures in the Forgotten Realms - 'Revised Rating'
    //youtube: "https://www.youtube.com/playlist?list=PL67qBli6QyKveH1IGHHCxgmBHfsAFYbIA",
    ratings_id: "1wLt4GfBUO_Ng4PqzIKutuXqi_2RzClwDvsmUa1dp5J4"
  },
  mid: { // Midnight Hunt - 'Revised'
    ratings_id: "1nP205Yl9Gc4MrBlC8otHpCLrZAb4jIETzChezaYQufk"
  },
  // Crimson Vow - Did not rate
  neo: { // Kamigawa: Neon Dynasty - 'Revised'
    //youtube: "https://www.youtube.com/playlist?list=PL67qBli6QyKui0r1MalRS2VcCe-EhHNT4",
    ratings_id: "1w4kE6WM8DM97WiXDDx2f8PD-1X-R9bdnsGpNHeDZO1I"
  },
  snc: { // Streets of New Capenna - 'Revised'
    //youtube: "https://www.youtube.com/playlist?list=PL67qBli6QyKuALueI11DyXrqb3jm7wXGU",
    ratings_id: "1M52Pbqg_A7Ce1adhSEc6Sr0UaEBHwzV9cVhPejap_ac"
  },
  // Baldur's Gate - Did not rate
  dmu: { // Dominaria United - 'Revised' (?)
    ratings_id: "1_LJiW7D0QQUrgrfIozjcud4nzjxisPz3YTIo2rCEjjo"
  }
});