import React, { useState } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';

import Expan      from '../components/widgets/Expan';
import LabelRadio from '../components/widgets/LabelRadio';
import Switch     from '../components/widgets/LabelSwitch';

import SeventeenLands from '../services/seventeen-lands';
import GoogleSheets from '../services/google-sheets';
import { SET_RATINGS as CHORD_SET_RATINGS } from '../services/constants/chord-o-calls';
import { SET_RATINGS as DARKEST_MAGE_SET_RATINGS } from '../services/constants/darkest-mage';
import { SET_RATINGS as LOL_SET_RATINGS} from '../services/constants/lords-of-limited';

import { lookupByLowerCaseCardName } from '../helpers/card.helpers';
import { RATING_SCORES_PROPERTY, RATING_SET_PROPERTY, RATING_TIER_PROPERTY } from '../constants';

const SOURCE_DARKEST_MAGE = "darkestmage";
const SOURCE_LORDS_OF_LIMITED = "lordsoflimited"
const SOURCE_CHORD_O_CALLS = "chord_o_calls"

export default function TierListRatings({ sets, cards, disabled, initializing, updateCardMetadataFn }) {
  const [isRatingsSwitchOn, setRatingsSwitch] = useState(false);
  const [selectedRatingsSource, setSelectedRatingsSource] = useState();
  const [isLoadingRatings, setLoadingRatings] = useState(false);
  // output messages
  const [warning, setWarning] = useState();
  const [error, setError] = useState();

  // Use a reference for the value of `isRatingsSwitchOn` and `selectedRatingsSource` to see the freshest value
  // https://stackoverflow.com/a/61957390
  const ratingsSwitchRef = React.useRef(isRatingsSwitchOn);
  ratingsSwitchRef.current = isRatingsSwitchOn;
  const selectedRatingsSourceRef = React.useRef(selectedRatingsSource);
  selectedRatingsSourceRef.current = selectedRatingsSource;

  // after initializing, always reset the switch and default source
  useDeepCompareEffect(() => {
    // only reset if the effect has changed `initializing` to true
    if (initializing) {
      // set state values and perform any corresponding state updates
      resetState(); // uses `sets` property
    }
  }, [initializing, sets]);

  // reset the default state of the component
  const resetState = () => {
    const sources = availableRatingsSources(sets);
    setRatingsSwitch(false);
    setSelectedRatingsSource(sources[0]);
    setLoadingRatings(false);
    // clear warning and error messages
    setWarning(/* undefined */);
    setError(/* undefined */);
  }

  // refresh the default selected ratings source when the sets change
  useDeepCompareEffect(() => {
    const sources = availableRatingsSources(sets);
    if (sources?.length) {
      resetState();
    }
    else if (!initializing) {
      // show message if no longer `initializing` and still no sources
      setWarning('No card ratings' + 
        // only add "for: ..." if sets exist
        ((sets.length > 0) ? ` for: ${sets.map(set => set.toUpperCase()).join(', ')}` : ''));
    }
  }, /* dependencies = */ [sets]);

  // helper function to visually indicate that card GIH WR is loading, 
  // both in this widget and on the individual cards
  const showLoadingRatings = () => {
    // show 'Loading...' message on this component
    setLoadingRatings(true);
    // set each individual card rating to `null` to indicate it is loading but has no value
    // See `Card.maybeRenderRating`
    updateCardMetadataFn(
      // [ { cardId: 0, RATING_TIER_PROPERTY: null, RATING_SCORES_PROPERTY: null } ]
      cards.map(card => (
        {
          cardId: card.cardId,
          [RATING_TIER_PROPERTY]: null,
          [RATING_SCORES_PROPERTY]: null
        }
      ))
    );
  }

  // helper function to clear the visual indicators that card ratings are loading
  // both in this widget and on the individual cards that do NOT have `cardRatingData`
  const hideLoadingRatings = (cardRatingData) => {
    setLoadingRatings(false);
    // find those cardIds that were NOT in the results; clear their GIHWR status
    const cardIdsWithRatings = new Set(cardRatingData.map(data => data.cardId));
    const cardIdsToClear = cards.flatMap(card => {
      if (cardIdsWithRatings.has(card.cardId)) return [];
      else return [card.cardId];
    });

    return clearCardRatings(cardIdsToClear);
  }

  // helper function to clear the card ratings
  const clearCardRatings = (cardIdsToClear = cards.map(card => card.cardId)) => {
    // set all ratings to `undefined` to overwrite the attributes:
    // [ { cardId: 0, ratings: undefined, tier: undefined }, ... ]
    const idsWithoutRatings = cardIdsToClear.map(cardId => 
      ({
        cardId, 
        [RATING_TIER_PROPERTY]: undefined,
        [RATING_SCORES_PROPERTY]: undefined,
        [RATING_SET_PROPERTY]: undefined
      }));
    return updateCardMetadataFn(idsWithoutRatings);
  }
  
  // helper function to determine if the given format is currently the active selection
  const isActive = (source) =>
    // is the switch still on and does the selected ratings source match?
    ratingsSwitchRef.current && (source === selectedRatingsSourceRef.current);

  // helper function to apply the retrieved card ratings, if still active
  const maybeApplyRatings = (results, loadedSource) => {
    return Promise.resolve(mapCardIdsToRatings(cards, results.ratings))
      .then((cardRatingData) => {
        // before updating, verify that the switch is not off and tier list ratings should 
        // still be shown AND that the `selectedRatingsSource` is the same as the loaded data
        if (isActive(loadedSource)) {
          updateCardMetadataFn(cardRatingData);
        }
        else {
          console.info("Dropping tier list rating data from now-inactive request");
        }
        // return the card rating data (for access to the cardIds being updated)
        // NOTE that this is NOT chained off of the actual call to `updateCardMetadataFn`
        return cardRatingData;
      });
  }

  // helper function to show error/warning messages, if still active
  const maybeShowMessages = (results, loadedSource) => {
    // exit early and show no messages if the switch is toggled *off*
    // or if the selected format is different from the loaded data
    if (!isActive(loadedSource)) {
      console.info("Dropping error messages from now-inactive request:", loadedSource);
      return; // exit
    }

    if (results.missing.length) {
      setWarning('No card ratings found for: ' + 
        results.missing.map(set => set.toUpperCase()).join(', '));
    }
    // No card ratings - set an error message and be done
    else if (!Object.keys(results.ratings).length) {
      setError('Failed to load card ratings');
      return; // exit
    }

    // Partial card ratings - set error and/or warning messages
    if (results.errors.length) {
      setError('Failed to load card ratings: ' + 
        results.errors.map(set => set.toUpperCase()).join(', '));
    }
  }

  const refreshCardRatings = (isRatingsSwitchOn, ratingsSource) => {
    setRatingsSwitch(isRatingsSwitchOn);
    // set the new value for the `selectedRatingsSource`
    setSelectedRatingsSource(ratingsSource);
    // clear warning and error messages
    setWarning(/* undefined */);
    setError(/* undefined */);
    // always clear any previous card ratings when triggered
    clearCardRatings();
    // then, if the switch is on, load the card ratings
    if (isRatingsSwitchOn) {
      // visually indicate that card ratings are loading
      showLoadingRatings();
      // only fetch card ratings for those card pool sets that have ratings from the selected source
      const availableSets = availableSetsForSource(sets, ratingsSource);
      const cardRatingsAndErrors = fetchCardRatings(ratingsSource, availableSets);
      // handle results (card ratings and errors)
      const showRatings = cardRatingsAndErrors.then(results => maybeApplyRatings(results, ratingsSource));
      const handleErrors = cardRatingsAndErrors.then(results => maybeShowMessages(results, ratingsSource));      
      // set to no longer loading if this load is still active (i.e., no changes in selected format)
      Promise.all([showRatings, handleErrors]).then(([cardRatingData, _]) => {
        if(isActive(ratingsSource)) {
          // hide visual indicators that card ratings are loading
          console.debug("No longer loading from", ratingsSource);
          hideLoadingRatings(cardRatingData);
        }
      });
    }
    // otherwise just set loading status to false
    else setLoadingRatings(false);
  }

  // helper method to render error/warning messages when the specified source is selected/active
  const renderMessages = (ratingsSource) => {
    const noSourceSelected = !(selectedRatingsSource || ratingsSource);
    const selectedAndActive = selectedRatingsSource === ratingsSource && isActive(ratingsSource);
    if (noSourceSelected || selectedAndActive) {
      return <>
        {isLoadingRatings && <div className="message pulse"><span>Loading...</span></div>}
        {error && <div className="message error"><span>{error}</span></div>}
        {warning && <div className="message warning"><span>{warning}</span></div>}
      </>;
    }
  }

  // check if there are any ratings available for the current card sets
  const availableSources = availableRatingsSources(sets);

  /*
    * Determine if this tier list ratings component is disabled:
    *  - Component is `disabled` or `initializing` (in props)
    *  - No available ratings sources for the current sets
    */
  const isDisabled = disabled || initializing || !availableSources?.length;
    
  // render the component
  return <div className="ratings">
    <Switch 
        className="ratings-show-switch"
        disabled={isDisabled} 
        onChange={(selected) => refreshCardRatings(selected, selectedRatingsSource)} 
        checked={isRatingsSwitchOn}>
      Show card ratings
    </Switch>
    {renderMessages()}
    {maybeRenderRatingsSources(sets, selectedRatingsSource, 
      /* disabled = */ (isDisabled || !isRatingsSwitchOn),
      /* renderMessagesFn = */ (ratingsSource) => renderMessages(ratingsSource),
      /* setRatingsSourceFn = */ (ratingsSource) => refreshCardRatings(isRatingsSwitchOn, ratingsSource))}
  </div>;
}

function maybeRenderRatingsSources(sets, selectedSource, disabled, renderMessagesFn, setRatingsSourceFn) {
  return <>
    {maybeRenderDarkestMage(sets, selectedSource, disabled, renderMessagesFn, setRatingsSourceFn)}
    {maybeRenderChordOCalls(sets, selectedSource, disabled, renderMessagesFn, setRatingsSourceFn)}
    {maybeRenderLordsOfLimited(sets, selectedSource, disabled, renderMessagesFn, setRatingsSourceFn)}
  </>
}

function maybeRenderChordOCalls(sets, selectedSource, disabled, renderMessagesFn, setRatingsSourceFn) {
  const setsLinks = sets.filter(set => CHORD_SET_RATINGS.hasOwnProperty(set))
    .map((set, idx) => {
      const setUrl = SeventeenLands.tierListUrl(CHORD_SET_RATINGS[set].tier_list_id);
      return <React.Fragment key={idx}>
        {/* eslint-disable-line */}<a className='ratings-set-code' 
            href={setUrl} target="_blank" rel="noopener">
          {set.toUpperCase()}
        </a>
      </React.Fragment>;
    });

  if (setsLinks.length) {
    // wrap in an Expan if more than one source link
    const setsSpan = (setsLinks.length === 1) ?
      <span>{setsLinks}</span>
      : <Expan className="ratings-sources" title="Show sources by set" initial="[&hellip;]">{setsLinks}</Expan>;

    return <>
      <div className="ratings-chord_o_calls">
        <LabelRadio
            name="ratings"
            id="draft-chords_o_calls"
            disabled={disabled}
            value={SOURCE_CHORD_O_CALLS}
            checked={selectedSource === SOURCE_CHORD_O_CALLS}
            onChange={() => setRatingsSourceFn(SOURCE_CHORD_O_CALLS)} >
          <span>
            <em>Draft</em> Ratings from
            <br/>
            {/* eslint-disable-line */} <a href="https://twitter.com/Chord_O_Calls"
              target="_blank" 
              rel="noopener">Alex Nikolic</a>:
          </span> {setsSpan}
        </LabelRadio>
        {renderMessagesFn(SOURCE_CHORD_O_CALLS)}
      </div>
    </>;
  }
  else return <></>;
}

function maybeRenderDarkestMage(sets, selectedSource, disabled, renderMessagesFn, setRatingsSourceFn) {
  const setsLinks = sets.filter(set => DARKEST_MAGE_SET_RATINGS.hasOwnProperty(set))
    .map((set, idx) => {
      const setUrl = GoogleSheets.spreadsheetUrl(DARKEST_MAGE_SET_RATINGS[set].ratings_id);
      return <React.Fragment key={idx}>
        {/* eslint-disable-line */}<a className='ratings-set-code' 
            href={setUrl} target="_blank" rel="noopener">
          {set.toUpperCase()}
        </a>
      </React.Fragment>;
    });

  if (setsLinks.length) {
    // wrap in an Expan if more than one source link
    const setsSpan = (setsLinks.length === 1) ?
      <span>{setsLinks}</span>
      : <Expan className="ratings-sources" title="Show sources by set" initial="[&hellip;]">{setsLinks}</Expan>;

    return <>
      <div className="ratings-darkestmage">
        <LabelRadio
            name="ratings"
            id="sealed-darkestmage"
            disabled={disabled}
            value={SOURCE_DARKEST_MAGE}
            checked={selectedSource === SOURCE_DARKEST_MAGE}
            onChange={() => setRatingsSourceFn(SOURCE_DARKEST_MAGE)} >
          <span>
            <em>Sealed</em> Ratings from 
            <br/>
            {/* eslint-disable-line */} <a href="https://twitch.tv/Darkest_Mage/videos"
              target="_blank" 
              rel="noopener">Darkest Mage</a>:
          </span> {setsSpan}
        </LabelRadio>
        {renderMessagesFn(SOURCE_DARKEST_MAGE)}
      </div>
    </>;
  }
  else return <></>;
}

function maybeRenderLordsOfLimited(sets, selectedSource, disabled, renderMessagesFn, setRatingsSourceFn) {
  const setsLinks = sets.filter(set => LOL_SET_RATINGS.hasOwnProperty(set))
    .map((set, idx) => {
      const setUrl = SeventeenLands.tierListUrl(LOL_SET_RATINGS[set].tier_list_id);
      return <React.Fragment key={idx}>
        {/* eslint-disable-line */}<a className='ratings-set-code' 
            href={setUrl} target="_blank" rel="noopener">
          {set.toUpperCase()}
        </a>
      </React.Fragment>;
    });

  if (setsLinks.length) {
    // wrap in an Expan if more than one source link
    const setsSpan = (setsLinks.length === 1) ?
      <span>{setsLinks}</span>
      : <Expan className="ratings-sources" title="Show sources by set" initial="[&hellip;]">{setsLinks}</Expan>;

    return <>
      <div className="ratings-lordsoflimited">
        <LabelRadio
            name="ratings"
            id="draft-lordsoflimited"
            disabled={disabled}
            value={SOURCE_LORDS_OF_LIMITED}
            checked={selectedSource === SOURCE_LORDS_OF_LIMITED}
            onChange={() => setRatingsSourceFn(SOURCE_LORDS_OF_LIMITED)} >
          <span>
            <em>Draft</em> Ratings from 
            <br/>
            {/* eslint-disable-line */} <a href="https://www.lordsoflimited.com"
              target="_blank" 
              rel="noopener">Lords of Limited</a>:
          </span> {setsSpan}
        </LabelRadio>
        {renderMessagesFn(SOURCE_LORDS_OF_LIMITED)}
      </div>
    </>;
  }
  else return <></>;
}

/**
 * Determine which card rating sources are available for the given sets.
 * 
 * @param {string[]} sets
 * @returns an array of ratings source names, in order of which is preferred (default)
 */
function availableRatingsSources(sets) {
  const sources = [];
  // Darkest Mage
  if (sets?.some(set => DARKEST_MAGE_SET_RATINGS.hasOwnProperty(set))) {
    sources.push(SOURCE_DARKEST_MAGE);
  }
  // Chord_O_Calls
  if (sets?.some(set => CHORD_SET_RATINGS.hasOwnProperty(set))) {
    sources.push(SOURCE_CHORD_O_CALLS);
  }
  // Lords of Limited
  if (sets?.some(set => LOL_SET_RATINGS.hasOwnProperty(set))) {
    sources.push(SOURCE_LORDS_OF_LIMITED);
  }
  return sources;
}

/**
 * Determine which of the given sets are available for the given ratings source.
 * 
 * @param {string[]} sets
 * @param {string} ratingsSource
 * @returns an array of those sets available for the given ratings source.
 */
function availableSetsForSource(sets, ratingsSource) {
  let ratings = {};
  // Darkest Mage
  if (ratingsSource === SOURCE_DARKEST_MAGE) {
    ratings = DARKEST_MAGE_SET_RATINGS;
  }
  // Chord_O_Calls
  else if (ratingsSource === SOURCE_CHORD_O_CALLS) {
    ratings = CHORD_SET_RATINGS;
  }
  // Lords of Limited
  else if (ratingsSource === SOURCE_LORDS_OF_LIMITED) {
    ratings = LOL_SET_RATINGS;
  }

  return sets.filter(set => ratings.hasOwnProperty(set));
}

/**
 * Retrieve the tier list card ratings from 17Lands for each set.
 * 
 * @param {String[]} sets the set codes for which to fetch 17Lands tier list ratings
 * @returns a {@link Promise} containing an array of `ratings` retrieved for each set and an
 * array of `errors` containing the set codes whose card ratings could not be retrieved:
 * ```
   {
     ratings: { 'mh3' : [ { 'name': 'Nulldrifter', ...}, ...], 'set': ...}, 
     errors: [ 'otj', ...]
   }
   ```
 */
function fetchCardRatings(ratingsSource, sets) {
  // wait for all card set stats promises to complete, regardless of whether or not one rejects
  return Promise.allSettled(
    sets.map(set => {
      // helper function to add the `set` and `source` to the promise result
      const withSetAndSource = (promise) => promise
        .then(json => ({ set, source: ratingsSource, ratings: json}))
        .catch(error => {
          error.set = set;
          error.source = ratingsSource;
          throw error;
        });

      // construct a Promise to retrieve the card tiers/ratings
      if (ratingsSource === SOURCE_CHORD_O_CALLS) {
        return withSetAndSource(SeventeenLands.cardTiersChordOCalls(set));
      }
      else if (ratingsSource === SOURCE_LORDS_OF_LIMITED) {
        return withSetAndSource(SeventeenLands.cardTiersLordsOfLimited(set));
      }
      else if (ratingsSource === SOURCE_DARKEST_MAGE) {
        return withSetAndSource(GoogleSheets.darkestMageRatings(set));
      }
      // else; create an error for unknown set
      const error = new Error(`Tried to load unknown ratings source '${ratingsSource}' `
          + `for set ${set.toUpperCase()}`);
      return withSetAndSource(Promise.reject(error));
    }))
    .then(extractRatingsAndErrors)
}

/**
 * Extract the 17Lands tier list ratings and any errors from an array of results 
 * from `Promise.allSettled`.
 * 
 * @param {Object[]} allSettledResults 
 * @returns a {@link Promise} containing an array of `ratings` retrieved for each set and an
 * array of `errors` containing the set codes whose card ratings could not be retrieved:
 * ```
   {
     ratings: { 'mh3' : [ { 'name': 'Nulldrifter', ...}, ...], 'set': ...}, 
     errors: [ 'otj', ...]
   }
   ```
 */
function extractRatingsAndErrors(allSettledResults) {
  const cardRatingsBySet = allSettledResults.reduce((combinedResults, result) => {
    if (result.status === 'fulfilled') {
      const set = result.value?.set;
      const source = result.value?.source;
      // filter out any empty tier list ratings and log a warning
      if (result.value?.ratings?.length) {
        if (combinedResults.ratings.hasOwnProperty(set)) {
          console.warn(`Retrieved multiple tier list ratings from ${source} for [${set?.toUpperCase()}]`, 
          combinedResults.ratings[set], result.value.ratings);
          combinedResults.ratings[set] = combinedResults.ratings[set].concat(result.value.ratings);
        }
        else {
          combinedResults.ratings[set] = result.value.ratings;
        }
      }
      else {
        combinedResults.missing = combinedResults.missing.concat(set);
        console.warn(`17Lands tier list ratings not found from ${source} for [${set?.toUpperCase()}]`);
      }
    } else { // result.status === 'rejected'
      // track any sets that had an error while fetching and log an error
      combinedResults.errors = combinedResults.errors.concat(result.reason.set);
      console.error(`Error while loading tier list ratings from ${result.reason.source} ` +
        `for [${result.reason.set?.toUpperCase()}] -`, result.reason);
    }        
    return combinedResults;
  }, /* initialValue = */ { ratings: {}, errors: [], missing: [] });

  return Promise.resolve(cardRatingsBySet);
}

/**
 * Map the card Ids for every provided card to the corresponding card ratings 
 * in the provided `cardRatingsBySet` (matched by lower case card name).
 * 
 * @param {Object[]} cards the cards in the card pool
 * @param {Object} cardRatingsBySet mapping from card set to an array of card tier ratings
 * @returns a {@link Promise} containing an array of each `cardId` and the corresponding 
 *          card rating data (e.g., RATING_TIER_PROPERTY, RATING_SCORES_PROPERTY)
 */
function mapCardIdsToRatings(cards, cardRatingsBySet) {
  if (Object.keys(cardRatingsBySet).length === 0) {
    console.warn(`No tier list card ratings loaded!`);
    return Promise.resolve([]);
  }
  // determine if there are multiple card sets represented in the pool; considering
  // sanitized sets to *not* consider, e.g., special guests 'SPG' as a distinct set
  // (used when determining if the set icon is shown on the Card)
  const sanitizedCardSets = SeventeenLands.sanitizeAllCardSets(cards);
  const hasMultipleSanitizedSets = sanitizedCardSets.length > 1;

  // organize all tier list ratings first by card name, then by set
  const cardRatingsByNameAndSet = Object.entries(cardRatingsBySet).reduce((acc, [set, setRatings]) => {
    setRatings.forEach(cardRating => {
      const name = cardRating.name.toLowerCase();
      acc[name] = acc[name] || {}; // grab the ratings already stored at this `name` or empty
      if (acc[name][set]) {
        console.warn(`Found multiple tier list ratings for ${cardRating.name} in [${set?.toUpperCase()}]`);
      }
      acc[name][set] = cardRating;
    });
    return acc;
  }, /* initialValue = */ {});

  const cardIdsWithRatings = cards.flatMap(card => {
    // try to lookup the card ratings for this card by it's name and set;
    // `SeventeenLands.normalizeCardSet` will resolve bonus sheet sets, etc.
    let ratingsSet = SeventeenLands.normalizeCardSet(card);
    const cardRatingsBySet = lookupByLowerCaseCardName(cardRatingsByNameAndSet, card);

    if (!cardRatingsBySet) {
      console.debug(`No tier list card ratings for card named [${card.name}]`);
      return []; // exit early; filtered out by flatMap
    }

    // identify the ratings for this card's specific set (if available)
    let cardRatings = cardRatingsBySet[ratingsSet];
    // cardRatings could still be undefined!
    if (!cardRatings) {
      console.warn(`No tier list card ratings for card named [${card.name}] ` +
        `from set [${card.set?.toUpperCase()}]`);

      // identify ratings for this card from *any* set already retrieved
      const cardRatingSets = Object.keys(cardRatingsBySet);
      if (cardRatingSets.length) {
        ratingsSet = cardRatingSets[0];
        console.debug(`Using tier list card ratings for card named [${card.name}] ` +
          `from set [${ratingsSet?.toUpperCase()}]` +
          ((cardRatingSets.length > 1)
            ? '; other options: ' + cardRatingSets.map(set => `[${set.toUpperCase()}]`).join(', ')
            : ''));
        cardRatings = cardRatingsBySet[ratingsSet];
      }
      else {
        console.warn(`No tier list card ratings for card named [${card.name}]`);
        return []; // exit early; filtered out by flatMap
      }
    }

    // check if the card set ratings is different from the actual card's set
    const isCardSetMismatch = ratingsSet !== card.set;

    return [{
      cardId: card.cardId,
      [RATING_TIER_PROPERTY]: cardRatings.tier,
      [RATING_SCORES_PROPERTY]: cardRatings.ratings,
      // only include the set if there are multiple sets to discriminate among
      [RATING_SET_PROPERTY]: (hasMultipleSanitizedSets || isCardSetMismatch) ? ratingsSet : undefined
    }];
  });

  return Promise.resolve(cardIdsWithRatings);
}
