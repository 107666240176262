import React, { Children } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';

import './DropDownButton.css';

/**
 * A custom button with a drop-down multi select
 */
export default class DropDownButton extends React.Component {

  constructor(props) {
    super(props);
    this.buttonRef = React.createRef();
    this.arrowButtonRef = React.createRef();

    // initialize the selected item
    let selectedItemIndex = 0;
    const arrayChildren = Children.toArray(this.props.children);
    if (this.props.selectedItemIndex 
        && this.props.selectedItemIndex >= 0
        && this.props.selectedItemIndex < arrayChildren.length) {
      // was the selectedItemIndex specified on the DropDownButton itself?
      selectedItemIndex = this.props.selectedItemIndex;
    }
    // is the selectedItemIndex overridden by an individual DropDownButtonItem?
    const selectedChildItemIndex = arrayChildren.findIndex(child => child.props.selected);
    if (selectedChildItemIndex >= 0) {
      selectedItemIndex = selectedChildItemIndex;
    }

    this.state = { isDropDownVisible: false, selectedItemIndex: selectedItemIndex };
  }

  render() {
    const selectedItem = Children.toArray(this.props.children)[this.state.selectedItemIndex];

    const isDropDownVisibleClass = this.state.isDropDownVisible ? 'expanded' : '';

    return <div className="dropdown-button">
      <button 
          ref={this.buttonRef}
          className="dropdown-button-button"
          onClick={(e) => this.handleClickItem(e, selectedItem)}
          disabled={this.props.disabled}>
        {selectedItem.props.children}
      </button>
      <button 
          ref={this.arrowButtonRef}
          className={`dropdown-button-arrow ${isDropDownVisibleClass}`}
          onClick={this.toggleDropDown}
          disabled={this.props.disabled}>
        <FontAwesomeIcon icon={faCaretDown} />
      </button>
      {this.maybeRenderItems()}
    </div>;
  }

  maybeRenderItems = () => {
    if (this.state.isDropDownVisible) {
      // configure the props for the child DropDownButtonItems
      const childrenWithProps = React.Children.map(this.props.children, (child, index) => {
        if (React.isValidElement(child)) {
          // pass a callback function for the child item's onClick event
          const handleSelectFn = (e) => this.handleSelectItem(e, child, index);
          return React.cloneElement(child, { onClick: handleSelectFn });
        }
        return child;
      });
    
      // calculate the relative position of the item menu
      const buttonElement = this.buttonRef.current;
      const menuTop = buttonElement.offsetTop + buttonElement.offsetHeight;
      return <>
        <DropDownButtonMenu top={menuTop} 
            hideDropDown={this.toggleDropDown} 
            // ignore outside clicks on the arrow button, which will already hide the menu
            // (otherwise handling the click twice will re-open the menu)
            ignoreOutsideClickOn={this.arrowButtonRef.current}>
          {childrenWithProps}
        </DropDownButtonMenu>
        </>;
    }
    return <></>;
  }

  handleSelectItem = (e, item, index) => {
    this.setState({ selectedItemIndex: index });
    if (this.props.onChange) {
      this.props.onChange(index);
    }
    this.handleClickItem(e, item);
  }

  handleClickItem = (e, item) => {
    // hide the drop down menu
    this.setState({ isDropDownVisible: false });

    // if the clicked item has an onClick handler, trigger it
    if (item.props.onClick) {
      item.props.onClick(e);
    }
  }

  toggleDropDown = () => {
    this.setState(prevState => { return { isDropDownVisible: !prevState.isDropDownVisible } });
  }

}


/**
 * The drop-down menu for the DropDownButton, which hides itself 
 * when a click occurs outside of the component.
 */
 class DropDownButtonMenu extends React.Component {
  
  constructor(props) {
    super(props);
    this.menuRef = React.createRef();

    // register/unregister document-wide event listeners here because 
    // using the 'onBlur' event of the drop-down button arrow will occur
    // *before* the click is triggered for the buttons within the menu.
    // https://stackoverflow.com/a/42234988/186818
    // Favor this to the react-outside-click-handler library which 
    // pulls in a HUGE number of unnecessary dependencies.
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    const menuPosition = this.props.top ? { top: `${this.props.top}px` } : undefined;
    return <>
      <div 
          ref={this.menuRef}
          className="dropdown-button-items" 
          style={menuPosition}>
        {this.props.children}
      </div>
      </>;
  }

  handleClickOutside(event) {
    if (this.menuRef && !this.menuRef.current.contains(event.target)
        // also ignore the outside click if on the specified element to ignore
        && this.props.ignoreOutsideClickOn && !this.props.ignoreOutsideClickOn.contains(event.target)) {
      // check that the hide function is specified
      if (this.props.hideDropDown) {
        this.props.hideDropDown();
      }
      else {
        console.warn("No 'hideDropDown' function specified for this drop down menu.", this.menuRef)
      }
    } 
  }

}


export class DropDownButtonItem extends React.Component {

  render = () => 
    <button className="dropdown-button-item" onClick={this.props.onClick}>
      {this.props.children}
    </button>;

}