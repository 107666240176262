import React from 'react';

import SplitPane from 'react-split-pane'

import Hideboard    from './Hideboard';
import SortableColumnList from './SortableColumnList';

/**
 * The default Sideboard view which contains a Hidden card pane.
 * 
 * Also see specialized versions: {@link FullSetSideboard}
 */
export default class Sideboard extends SortableColumnList {

  constructor(props) {
    super(props);
    this.name = "Sideboard";

    this.state = { 
      // by default do not show hidden cards
      showHidden: false
    };
  }

  _render = SortableColumnList.prototype.render;

  render() {
    if (this.state.showHidden) {
      return <>
        <SplitPane
            className="topPanes"
            split="vertical"
            pane2Style={{width: 0}} // this makes it not trigger outer scrollbars!
            defaultSize="70%">
          {this._render()}
          {this.renderHideboard()}
        </SplitPane>
      </>;
    }
    else {
      return this._render();
    }
  }

  renderHideboard = () => {
    return <Hideboard id="hidden" 
      className="hidden" 
      disabled={this.props.disabled}
      error={this.props.error}
      isFiltering={false}

      columns={this.props.hiddenColumns}
      createDroppableId={this.props.createDroppableId}

      onCardTransform={this.props.onCardTransform}
      onCardDoubleClick={this.props.onCardDoubleClick}
      hide={() => this.showHidden(false)}
      clear={this.props.onClearHidden}
    />;
  }

  renderPoolControls() {
    return <>
      <button disabled={this.props.disabled} onClick={this.props.onAddBasicLands}>Add Basic Lands</button>
      {this.renderSortControls()}
      <div className="sideboard-controls-hidden">
        {this.maybeRenderHideButton()}
        <button className="hidden-hide-all"
            disabled={this.props.disabled} 
            onClick={this.props.onHideAllCards}>
          Hide All
        </button>
      </div>
    </>;
  }

  maybeRenderHideButton() {
    if (!this.state.showHidden) {
      const hiddenCards = this.props.hiddenColumns.flatMap(column => column.cards);

      return <>
        <button className="hidden-show" 
            disabled={this.props.disabled} 
            onClick={() => this.showHidden(true)}>
          {hiddenCards.length}&nbsp;Hidden
        </button>
      </>;
    }
  }

  showHidden = (visible) => {
    this.setState({ showHidden: visible });
  }

}

/**
 * Specialized version of {@link Sideboard} view for visualizing a full card set.  
 * It does not have a hidden card pane.
 */
export class FullSetSideboard extends SortableColumnList {

  constructor(props) {
    super(props);
    this.name = 'Card Pool'
  }

  renderTitle() {
    return <>{this.props.setCode?.toUpperCase()} {this.name}: {this.renderCardCount()}</>;
  }

  /**
   * Render the 'Add Basic Lands' button in the pool controls
   */
  renderPoolControls() {
    // show 'Add Basic Lands' button if action is defined
    const addBasicLands = this.props.onAddBasicLands ?
      <button disabled={this.props.disabled} onClick={this.props.onAddBasicLands}>Add Basic Lands</button>
      : <></>;

    return <>
      {addBasicLands}
      {this.renderSortControls()}
    </>;
  }

}
