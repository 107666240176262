/**
 * The Diff state of a Card
 */
export default class DiffState {
  static Deck           = new DiffState('Deck');
  static Sideboard      = new DiffState('Sideboard');
  static Added          = new DiffState('Added');
  static ProxyDeck      = new DiffState('Proxy-Deck');
  static ProxySideboard = new DiffState('Proxy-Sideboard');

  static isProxy = (diffState) => 
    this.ProxyDeck.matches(diffState) || this.ProxySideboard.matches(diffState);

  constructor(status) {
    this.status = status;
  }
  
  /**
   * This checks status equality by internal status since restoring the object
   * from saved card state will not restore the same static objects.
   */
  matches(otherState) {
    return otherState && (this.status === otherState.status);
  }

  toString() {
    return `DiffState.${this.status}`;
  }
}

