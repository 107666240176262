// Runtime exception when using Parse: "Buffer is not defined"
//  https://stackoverflow.com/a/70948424/186818
// It is no longer automatically polyfilled with Webacpk 5:
//  https://viglucci.io/articles/how-to-polyfill-buffer-with-webpack-5
import { Buffer } from 'buffer';

/**
 * Custom Error type used when a pool is not found.
 */
export class PoolNotFoundError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PoolNotFoundError';
  }
} 

export default class RemoteStorage {

  constructor(parseModule) {
    this.parseModule = parseModule;

    // initialize the provided Parse module
    this.parseModule.initialize(
      process.env.REACT_APP_PARSE_APP_ID,
      process.env.REACT_APP_PARSE_JS_KEY
    );
    
    this.parseModule.serverURL = 'https://parseapi.back4app.com/';    
  }

  savePoolState(poolState, sourceId) {
    const PoolState = this.parseModule.Object.extend("PoolState");
    return this.saveCardPoolObject(PoolState, poolState, sourceId)
  }

  saveTierList(poolState, sourceId, set) {
    const TierList = this.parseModule.Object.extend("TierList");
    return this.saveCardPoolObject(TierList, poolState, sourceId, { set })
  }

  saveCardPoolObject(CardPoolObject, poolState, sourceId, extraParams = {}) {
    // save the content as a Parse.File
    const stateJson = JSON.stringify(poolState);
    // convert data to base64
    const base64 = Buffer.from(stateJson).toString('base64')
    const parseStateFile = new this.parseModule.File("state.json", { base64: base64 });
    return parseStateFile.save()
      .then(savedFile => {
        // link the file to a new TierList object (or it will be an orphan)
        const newPoolObject = new CardPoolObject();
        newPoolObject.set("stateFile", savedFile);

        // maybe create a pointer to this card pool's originating source object
        if (sourceId) {
          sourceId.update(newPoolObject, this.parseModule);
        }

        // add an extra params that were provided
        for (const [key, value] of Object.entries(extraParams)) {
          newPoolObject.set(key, value);
        }

        return newPoolObject.save()
          .then(parseObject => parseObject.id);
      });
  }

  readPoolState(poolStateId) {
    const PoolState = this.parseModule.Object.extend("PoolState");
    return this.readCardPoolObject(PoolState, poolStateId);
  }

  readTierList(tierListId) {
    const TierList = this.parseModule.Object.extend("TierList");
    return this.readCardPoolObject(TierList, tierListId);
  }

  readCardPoolObject(CardPoolObject, cardpoolId) {
    const query = new this.parseModule.Query(CardPoolObject);
    return query.get(cardpoolId)
      .catch((err) => { throw new PoolNotFoundError(err); })
      // .save() will immediately set the 'updatedAt' time after a successful load
      .then(object => object.save())
      .then(object => {
        // load pool state from Parse.File
        const stateFile = object.get("stateFile");
        if (!stateFile)
          return Promise.reject(new Error(`No stateFile for ${CardPoolObject.className} ${cardpoolId}`));
        else {
          // Promise to get Base64 encoded data
          return stateFile.getData();
        }
      })
      .then(stateBase64 => {
        // convert the Base64 pool state to JSON
        const buffer = Buffer.from(stateBase64, 'base64');
        const stateJson = JSON.parse(buffer.toString('utf-8'));
        return Promise.resolve(stateJson);
      })
      .catch((err) => {
        console.debug(`Error reading Parse ${CardPoolObject.className} '${cardpoolId}' -`, err.message);
        throw err;
      });
  }

  //TODO push into BrowserRemoteStorage?
  saveDeckFile(file) {
    // save the file as a Parse.File
    const parseFile = new this.parseModule.File(file.name, file);
    return this.saveParseDeckFile(parseFile);
  }

  saveDeckContent(content, filename = "content.txt") {
    // convert content to base64
    const base64 = Buffer.from(content).toString('base64')
    const parseFile = new this.parseModule.File(filename, { base64: base64 });
    return this.saveParseDeckFile(parseFile);
  }

  saveParseDeckFile(parseFile) {
    return parseFile.save()
      .then((respFile) => {
        // then link the file to a DeckFile object (or it will be an orphan)
        const DeckFile = this.parseModule.Object.extend("DeckFile");
        const deckFile = new DeckFile();
        deckFile.set("name", parseFile.name());
        deckFile.set("file", respFile);
        return deckFile.save();
      })
      .then(parseDeckFile => parseDeckFile.id);
  }

  readDeckFile(deckFileId) {
    const DeckFile = this.parseModule.Object.extend("DeckFile");
    const queryDeckFile = new this.parseModule.Query(DeckFile);
    return queryDeckFile.get(deckFileId)
      .then(deckFileObject => {
        // load deck file from Parse.File
        const deckFile = deckFileObject.get("file");
        if (!deckFile)
          // Why is there no DeckFile?
          return Promise.reject(new Error("No file for DeckFile" + deckFileId));
        else
          return deckFile.getData();
      })
      .then(deckFileBase64 => {
        // convert the Base64 pool state to a UTF-8 string
        const buffer = Buffer.from(deckFileBase64, 'base64');
        const deckFileContent = buffer.toString('utf-8');
        return Promise.resolve(deckFileContent);
      });
  }
}


/**
 * Create a version of RemoteStorage that uses the browser version of the Parse SDK.
 */
export class BrowserRemoteStorage extends RemoteStorage {
  constructor() {
      super(require('parse'));
  }
}


/**
 * The base class for a card pool source identifier.
 */
export class SourceID {
  /**
   * Deserialize the appropriate SourceID from the provided source ID JSON data.
   * 
   * @param {Object} sourceJson  
   * @returns the deserialized SourceID
   */
  static fromJSON(jsonSourceId) {
    if (jsonSourceId.startsWith(RemoteDeckFileID.PREFIX)) {
      return new RemoteDeckFileID(jsonSourceId.slice(RemoteDeckFileID.PREFIX.length))
    }
    else if (jsonSourceId.startsWith(RemotePoolStateID.PREFIX)) {
      return new RemotePoolStateID(jsonSourceId.slice(RemotePoolStateID.PREFIX.length))
    }
    else if (jsonSourceId.startsWith(RemoteTierListID.PREFIX)) {
      return new RemoteTierListID(jsonSourceId.slice(RemoteTierListID.PREFIX.length))
    }
    // check SeventeenLands TierList first because the prefix is more specific
    else if (jsonSourceId.startsWith(SeventeenLandsTierListID.PREFIX)) {
      return new SeventeenLandsTierListID(jsonSourceId.slice(SeventeenLandsTierListID.PREFIX.length))
    }
    else if (jsonSourceId.startsWith(SeventeenLandsPoolID.PREFIX)) {
      return new SeventeenLandsPoolID(jsonSourceId.slice(SeventeenLandsPoolID.PREFIX.length))
    }
  }

  update(poolObject, parseModule) {
    throw new Error(`${this.constructor.name}.update() is not implemented`)
  }

  prefix() {
    throw new Error(`${this.constructor.name}.prefix() is not implemented`)
  }

  /**
   * If an object being stringified (by `JSON.stringify`) has a property named `toJSON` whose 
   * value is a function, then the toJSON() method customizes JSON stringification behavior: 
   * instead of the object being serialized, the value returned by the toJSON() method when 
   * called will be serialized.
   */
  toJSON() {
    return `${this.prefix()}${this.id}`;
  }
}

/**
 * Source identifier for a deck file in remote storage
 */
export class RemoteDeckFileID extends SourceID {
  static PREFIX = 'deck:';

  constructor(id) {
    super();
    this.id = id;
  }

  prefix() {
    return RemoteDeckFileID.PREFIX;
  }

  update(poolObject, parseModule) {
    const DeckFile = parseModule.Object.extend('DeckFile');
    const pointerDeckFile = new DeckFile().set('objectId', this.id);
    poolObject.set('deckFile', pointerDeckFile);
  }
}

/**
 * Source identifier for a card pool state in remote storage
 */
export class RemotePoolStateID extends SourceID {
  static PREFIX = 'pool:';

  constructor(id) {
    super();
    this.id = id;
  }

  prefix() {
    return RemotePoolStateID.PREFIX;
  }

  update(poolObject, parseModule) {
    const PoolState = parseModule.Object.extend('PoolState');
    const pointerPoolState = new PoolState().set('objectId', this.id);
    poolObject.set('sourcePoolState', pointerPoolState);
  }
}

/**
 * Source identifier for a tier list in remote storage
 */
export class RemoteTierListID extends SourceID {
  static PREFIX = 'tierlist:';

  constructor(id) {
    super();
    this.id = id;
  }

  prefix() {
    return RemoteTierListID.PREFIX;
  }

  update(tierlistObject, parseModule) {
    const TierList = parseModule.Object.extend('TierList');
    const pointerTierList = new TierList().set('objectId', this.id);
    tierlistObject.set('sourceTierList', pointerTierList);
  }
}

/**
 * Source identifier for a 17Lands card pool or deck
 */
export class SeventeenLandsPoolID extends SourceID {
  static PREFIX = '17lands-';

  constructor(poolId, deckId) {
    super();
    this.id = deckId ? `${poolId}-${deckId}` : poolId;
  }

  prefix() {
    return SeventeenLandsPoolID.PREFIX;
  }

  update(poolObject) {
    poolObject.set('sourceExternal', `${this.prefix()}${this.id}`);
  }
}

/**
 * Source identifier for a 17Lands tier list
 */
export class SeventeenLandsTierListID extends SourceID {
  static PREFIX = '17lands-tierlist-';

  constructor(tierlistId) {
    super();
    this.id = tierlistId;
  }

  prefix() {
    return SeventeenLandsTierListID.PREFIX;
  }

  update(poolObject) {
    poolObject.set('sourceExternal', `${this.prefix()}${this.id}`);
  }
}
