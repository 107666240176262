import React from 'react';

import { TitledColumn } from './Column';
import SortableColumnList from './SortableColumnList';

import Switch from '../components/widgets/LabelSwitch';

/**
 * Specialized version of {@link SortableColumnList} view for visualizing a tier list.
 */
export default class TierList extends SortableColumnList {

  constructor(props) {
    super(props);
    this.name = "Tier List";
  }

  renderPoolDetails() {}

  renderPoolControls() {
    return <>
      <span className="pool-controls">
        <Switch 
            className="split-switch"
            disabled={this.props.disabled} 
            onChange={this.props.onSplitColumns} 
            checked={this.props.splitColumns ? true : false} >
          Split View
        </Switch>
      </span>
      <span className="pool-controls">
        <button 
          className="tierlist-clear" 
          disabled={this.props.disabled} 
          onClick={this.props.onClear}>Clear Tier List</button>
      </span>
    </>
  }

  renderColumn(column, columnId) {
    return <TitledColumn
        key={columnId} 
        id={columnId} 
        title={column.title}
        cards={column.cards}
        onCardTransform={this.props.onCardTransform}
        onCardDoubleClick={this.props.onCardDoubleClick} 
      />;
  }

}
