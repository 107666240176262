import React from 'react';
import { Draggable } from '@hello-pangea/dnd';

import Card from './Card';

const TIMEOUT_MOUSE_PRESS = 150;

export default class DraggableCard extends React.Component {

  constructor(props) {
    super(props);
    // initialize placeholder for recognizing if we want to translate the Card position
    this.dragTranslateY = 0;
  }

  shouldComponentUpdate (nextProps, nextState) {
    // compare all properties/state that, if different, should trigger a re-render
    return (this.props.provided !== nextProps.provided 
      || this.props.snapshot    !== nextProps.snapshot 
      || this.props.index       !== nextProps.index 
      || this.props.card        !== nextProps.card);
  }

  componentDidUpdate(prevProps) {
    // check if we are newly preparing to drag this Card
    if (this.dragTranslateY !== 0  
        && this.props.card.isPreparingToDrag 
        && !prevProps.card.isPreparingToDrag) {
      // clear the MousePress timer since we are about to do the same action
      clearTimeout(this.mousePressTimer);
      // update style to translate the Card y-position
      this.updateStyleBeforeDrag();
    }
    //Note: no `else if` because the Card gets recreated on drop
    //else if (prevProps.isPreparingToDrag && !this.props.isPreparingToDrag) ...
  }

  render() {
    return (
      <Draggable draggableId={this.props.card.cardId} index={this.props.index} key={this.props.card.cardId} >
        { (provided, snapshot) => (
          <div
            className={this.determineClassNames(snapshot.isDragging)}
            ref={(ref) => this.setRef(ref, provided)}
            {...provided.draggableProps} 
            {...provided.dragHandleProps} 
            style={this.getStyle(provided.draggableProps.style, snapshot)}
            // handle mouse down/up for repositioning the draggable under the cursor
            onMouseDown={this.handleMouseDownFn(provided)}
            onMouseUp={this.handleMouseUp}
          > 
            <Card 
              card={this.props.card} 
              onTransform={this.props.onTransform}
              onDoubleClick={this.props.onDoubleClick}
            />
          </div>
        )}
      </Draggable>
    );
  }

  determineClassNames = (isDragging) => {
    const classNames = ["card-draggable"];
    // designate `dragging` class style when isDragging
    if (isDragging) classNames.push("dragging");

    return classNames.join(" ");
  }

  setRef = (ref, provided) => {
    // keep a reference to the Card DOM ref as an instance property
    this.ref = ref;
    // give the DOM ref to @hello-pangea/dnd
    provided.innerRef(ref);
  };

  updateStyleBeforeDrag() {
    // manually add `dragging` class -- the dragging state hasn't actually moved 
    // to isDragging yet; might have just done a mouse press
    const classNames = this.ref.getAttribute('class');
    this.ref.setAttribute('class', ("dragging "+classNames).trim());

    this.ref.setAttribute('style',
      `z-index: 5000;
      transform: translateY(${this.dragTranslateY}px)`
    );
  }

  getStyle(style, snapshot) {
    // Customize the zIndex during drop animation
    if (snapshot.isDropAnimating) {
      const patchedStyle = {
        ...style,
        // always fast transition (default is )
        transitionDuration: "0.05s",
      }

      if (snapshot.draggingOver && snapshot.draggingOver.startsWith("deck")) {
        // Set the z-index below the deck pool header z-index (3500)
        patchedStyle.zIndex = 3000;

        // If dragging from sideboard to deck, we don't want the card to "fly"
        // over other content to land in a scrolled-out-of-view location, so
        // further speed up the transition
        if (this.props.columnId.startsWith("sideboard")) {
          // cannot be 0, but make it super tiny (so onTransitionEnd event will still fire)
          patchedStyle.transitionDuration = "0.0001s";
        }
        
        // still visible when quickly set at new position; so also set opacity to 0
        //FIXME - this should only be necessary when starting from the 'sideboard'
        // BUT Safari is a jerk about z-index: setting the 'sideboard' pool z-index 
        // to anything makes a sideboard card draggable stay 'stuck' in that div
        patchedStyle.opacity = 0;
      }
      return patchedStyle;
    }
    return style;
  }

  handleMouseDownFn = (provided) => {
    return (event) => {
      this.handleMouseDown(event);

      if (provided.dragHandleProps.onMouseDown) {
        console.log("then pass along onMouseDown", provided.dragHandleProps.onMouseDown)
        provided.dragHandleProps.onMouseDown(event);
      }
    }
  }

  handleMouseDown = (event) => {
    //Note: should check this and exit early for @hello-pangea/dnd, 
    // but want to prepare for pre-drag action
    // if (event.defaultPrevented) {
    //   return;
    // }

    const clickClientY = event.nativeEvent.clientY;

    // Calculate the number of pixels to translate in the y-direction
    // in order to position the cursor over the top part of the Card.
    const eventTarget = event.currentTarget; // the clicked-on Card
    const boundingRect = eventTarget.getBoundingClientRect();
    const distanceToTopOfCard = clickClientY - boundingRect.top;
    // calculate offset based on card width, which may be adjustable
    const targetCardOffsetY = boundingRect.width / 9;
    const translateY = distanceToTopOfCard - targetCardOffsetY;
    
    this.dragTranslateY = translateY;

    // custom handling to determine if the mouse is pressed (and held) 
    // in order to distinguish from mouse clicks
    this.mousePressTimer = setTimeout(
      () => this.handleMousePress(eventTarget),
      TIMEOUT_MOUSE_PRESS);
  }

  handleMousePress = (target) => {
    this.updateStyleBeforeDrag();
  }

  handleMouseUp = (event) => {
    // clear the MousePress timer (may be null) since we are no longer pressing
    clearTimeout(this.mousePressTimer);
    // undo any style already added by handleMousePress
    event.currentTarget.removeAttribute('style');

    // also undo the `dragging` class added by handleMousePress
    const classNames = event.currentTarget.getAttribute('class');
    const newClassNames = classNames.replace("dragging", "").trim();
    event.currentTarget.setAttribute('class', newClassNames);
  }

}
  
