import { standardizeCardName } from '../helpers/card.helpers';

export default class PoolReader {

  // Favor MTGO and default the first pool to the 'sideboard' unless declared within file
  static read = (content, assumedFirstPool = "sideboard") => {
    // look for carriage returns '\r' optionally followed by a line feed '\n'
    // allowing '\r\r\n' to be considered two newlines
    const lines = content.split(/(?=\r|\n)\r?\n?/);
    // allPools = [ { cards: [], poolName: "" }, ... ] 
    const allPools = this.readDelimitedPools(lines);

    // iterate over the pools and fill in any explicitly declared pool names
    const declaredPoolNames = new Set();
    let currentPoolName = undefined;
    allPools.forEach(pool => {
      if (pool.poolName) {
        currentPoolName = pool.poolName;
        // track explicitly-declared pool names
        declaredPoolNames.add(currentPoolName);
      }
      else if (currentPoolName) {
        // set an undefined poolName
        pool.poolName = currentPoolName;
      }
    });

    // handle "companion" pool(s) (MTGA only; MTGO automatically checks the sideboard)
    let companions = [];
    if (declaredPoolNames.has("companion")) {
      companions = allPools.filter(pool => pool.poolName === "companion").flatMap(pool => pool.cards);
      declaredPoolNames.delete("companion");
    }
    
    
    // build the deck/sideboard card pools from the delimited pools (`set` optional)
    // cardPool = { 
    //   deck: [ { name: "aaa", count: 2, set: "xxx" }, ... ],
    //   sideboard: [ { name: "bbb", count: 1, set: "xxx" }, ...]
    // }
    const cardPool = {
      deck: [],
      sideboard: [],
    };
    // and filter down to only the pools from which we want to add cards
    const pools = allPools.filter(pool => 
      // pools without an explicit name (to be inferred) and any sideboard and deck pool
      !pool.poolName || this.isSideboard(pool.poolName) || this.isDeck(pool.poolName)
    );

    // if no poolNames were found, use assumedFirstPool until the first non-empty delimiter
    if (!declaredPoolNames.size) {
      const otherPool = this.otherPool(assumedFirstPool);

      pools.forEach(pool => {
        const poolName = cardPool[assumedFirstPool].length ? otherPool : assumedFirstPool;
        cardPool[poolName] = this.addCardsToPool(cardPool[poolName], pool.cards);
      });
    }
    // if one poolName was found, assume the unnamed pools are the other pool
    else if (declaredPoolNames.size === 1) {
      // get the first (and only) declared pool name
      const namedPool = declaredPoolNames.values().next().value;
      const unnamedPool = this.otherPool(namedPool);

      pools.forEach(pool => {
        const poolName = pool.poolName ? pool.poolName : unnamedPool;
        cardPool[poolName] = this.addCardsToPool(cardPool[poolName], pool.cards);
      });
    }
    // if two+ poolNames were found, assume the unnamed pools are the sideboard
    else /* if (declaredPoolNames.size > 1) */ {
      const unnamedPool = "sideboard";

      pools.forEach(pool => {
        const poolName = pool.poolName ? pool.poolName : unnamedPool;
        cardPool[poolName] = this.addCardsToPool(cardPool[poolName], pool.cards);
      });
    }

    // before returning the cardpool, check that any companions are fully represented in the sideboard
    return companions.reduce((combinedPool, companion) => {
      const sideboard = combinedPool.sideboard;
      const idx = sideboard.findIndex(card => this.cardsEqual(card, companion));
      if (idx < 0) {
        // if missing, add the companion to the sideboard
        combinedPool.sideboard = sideboard.concat(companion);
      }
      else {
        // update the count of an existing sideboard companion card
        const existing = sideboard[idx];
        combinedPool.sideboard[idx].count = Math.max(existing.count, companion.count);
      }
      return combinedPool;
    }, cardPool);
  }

  /**
   * Read blocks of cards separated by known delimiters:
   * - 'Deck'
   * - 'Sideboard'
   * - 'Commander' -> sideboard
   * - 'Companion' -> sideboard
   * - blank lines
   */
  static readDelimitedPools = (lines) => {
    const pools = [{ cards: [] }];

    lines.forEach(l => {
      const line = this.clean(l);
      if (this.isComment(line)) {
        // skip comment lines (no-op)
      }
      else if (this.isCompanion(line)) {
        // push to start next pool and assign known name
        pools.push({ poolName: "companion", cards: [] });
      }
      else if (this.isSideboard(line) || this.isCommander(line)) {
        // push to start next pool and assign known name
        pools.push({ poolName: "sideboard", cards: [] });
      }
      else if (this.isDeck(line)) {
        // push to start next pool and assign known name
        pools.push({ poolName: "deck", cards: [] });
      }
      else if (this.isBlank(line)) {
        // push to start next pool with unknown poolName
        pools.push({ cards: [] });
      }
      else { // It's a card!
        const card = this.parseCard(line);
        if (!card.name) console.debug("undefined card:", card, "for line: '"+line+"'");
        if (card.name) {
          pools[pools.length - 1].cards.push(card);
        }
      }
    });

    return pools;
  }

  static clean = line => line.trim().toLowerCase()

  static cardsEqual = (cardA, cardB) => 
    ((cardA.name.toLowerCase() === cardB.name.toLowerCase())
    && (cardA.set?.toLowerCase() === cardB.set?.toLowerCase()));

  static otherPool = poolName => poolName === "sideboard" ? "deck" : "sideboard"

  /**
   * Identifies lines starting with comments ('# ...' and '// ...')
   */
  static isComment = line => (line.startsWith("#") || line.startsWith("//"))

  static isBlank = line => !line.trim().length

  static isDeck = line => (line === "deck")

  static isSideboard = line => (line === "sideboard")

  static isCommander = line => (line === "commander")

  static isCompanion = line => (line === "companion")

  static countBlankLines = (lines, from) => {
    const remaining = lines.slice(from);
    const nextNonblankIndex = remaining.findIndex(l => !this.isBlank(l));
    // no non-blank line found?  all remaining are blank
    if (nextNonblankIndex < 0) return remaining.length;
    return nextNonblankIndex;
  }
  
  static addCardsToPool(pool, cards) {
    return cards.reduce((combinedPool, newCard) => {
        const idx = combinedPool.findIndex(card => this.cardsEqual(card, newCard));
        if (idx < 0) {
          // add to combined pool
          return combinedPool.concat(newCard);
        }
        else {
          // merge with existing ard
          const existing = combinedPool[idx];
          combinedPool[idx].count = existing.count + newCard.count;
          return combinedPool;
        }
      },
      pool // start reducing from the provided pool
    );
  }

  /**
   * Parse a card object from the given line
   */
  static parseCard = line => {
    // regex to match the expected parts of a card line
    //  Example: `3 +2 Mace // Test (SET) 33`
    // https://regexr.com
    // ^ - Beginning
    // (?:(\d+)\s)? - Optionally capture digits (Card Count) until whitespace
    // (.+?) - Card Name (non-greedy)
    // (?:\s\((.+)\).+)? - Optionally capture the Card Set between parens, followed by anything
    // $ - End
    const parts = line.trim().match(/^(?:(\d+)\s)?(.+?)(?:\s\((.+)\).*)?$/);
    // Note: intentionally parsing empty lines so that outside logic and recognize them

    // index 1 is the Card Count match group (explicitly set to 1 if no count specified)
    const cardCount = parts[1] ? parts[1] : 1;

    // index 2 is the Card Name match group
    let cardName = undefined;
    if (parts[2]) {
      cardName = standardizeCardName(parts[2]);
    }

    const card = {
      name: cardName,
      count: Number(cardCount)
    };

    // index 3 is the Card Set (optional)
    if (parts[3]) {
      card.set = parts[3].toLowerCase();
    }

    return card;
  }


}