import React from 'react';

import SortableColumnList from './SortableColumnList';

export default class Hideboard extends SortableColumnList {

  constructor(props) {
    super(props);
    this.name = "Hidden";
  }

  renderPoolControls() {
    return <>
      <button className="hidden-hide" 
          disabled={this.props.disabled} 
          onClick={() => this.props.hide()}>
        Hide
      </button>
      <button className="hidden-clear" 
          disabled={this.props.disabled} 
          onClick={() => this.props.clear()}>
        Clear Hidden
      </button>
    </>;
  }
  
}
