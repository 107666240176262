import React from 'react';

import { 
  isAftermath, 
  isBattle, 
  isDoubleFaced, 
  isFlip, 
  isSplit 
} from '../helpers/card.helpers';

const ZOOM_IMG_SCALE     = 1.5; // zoom scale
const ZOOM_OFFSET_SIDE   = 5;  // left/right offset from the card
const ZOOM_MARGIN_SIDE   = 5;   // left/right buffer in client window
const ZOOM_MARGIN_TOP    = 10;  // top buffer in client window
const ZOOM_MARGIN_BOTTOM = 20;  // bottom buffer in client window

export default class CardZoom extends React.Component {

  render() {
    // calculate the zoomed width and height from the bounding rectangle
    const cardImageRect = this.props.cardBoundingRect;
    let zoomWidth = cardImageRect.width * ZOOM_IMG_SCALE;
    let zoomHeight = cardImageRect.height * ZOOM_IMG_SCALE;

    // calculate the left position of the zoom image (start with the Card's position)
    let zoomLeft = cardImageRect.left;
    // adjust left position by normal card width
    zoomLeft += cardImageRect.width + ZOOM_OFFSET_SIDE;

    // check for special cases; these styles (zoomFrontStyle/zoomBackStyle) are applied 
    // WITHOUT consideration/knowledge of any scaling or other translation happening
    // on the outer `div.zoom` that wraps the images.
    let zoomFrontStyle = undefined;
    let zoomBackStyle = undefined;
    // Split Cards
    if (isSplit(this.props.card)) {
      // rotation is set to happen around the top/left point, so adjust the left 
      // position by the image's pre-scaled height (i.e., it's now-rotated width)
      zoomFrontStyle = {
        transformOrigin: "left top",
        transform: `translateX(${cardImageRect.height}px) rotate(90deg)`
      }
      // flip the width and height for the rotated zoom image
      zoomWidth  = (cardImageRect.height * ZOOM_IMG_SCALE);
      zoomHeight = (cardImageRect.width * ZOOM_IMG_SCALE);
    }
    // Double-Faced Cards
    else if (isDoubleFaced(this.props.card)) {
      //rotate the *front* face if this is a Battle card
      if (isBattle(this.props.card)) {
        // rotation is set to happen around the top/left point, so adjust the left 
        // position by the image's pre-scaled height (i.e., it's now-rotated width)
        zoomFrontStyle = {
          transformOrigin: "left top",
          transform: `translateX(${cardImageRect.height}px) rotate(90deg)`
        }
        // offset the back card face by the difference between the width of a normal 
        // card face and the width of a rotate card face
        const rotatedOffset = cardImageRect.height - cardImageRect.width;
        zoomBackStyle = {
          marginLeft: `${rotatedOffset}px`
        }
        // increase the overall zoom width by the card height (i.e. it's now-rotated width)
        zoomWidth += zoomHeight;
      }
      // all other DFCs just show both faces
      else {
        // double the width to show the second card face
        zoomWidth *= 2;
      }
    }
    // Aftermath Cards
    else if (this.displayAsAftermath(this.props.card)) {
      // increase aftermath half by roughly 46% of the card height * 1.1 scaling factor
      const aftermathScale = 1.1;
      // increase the width by the scale aftermath fraction (0.46) of the card
      zoomWidth += (zoomHeight * 0.46 * aftermathScale);
      // rotate the aftermath card face
      zoomBackStyle = {
        position: "relative",
        // adjust position of the aftermath card face
        top: `${11.5 * aftermathScale}em`,
        left: `-${8.625 * aftermathScale}em`,
        transformOrigin: "left top",
        // rotate for the aftermath card face
        transform: `rotate(-90deg) scale(${aftermathScale})`
      }
    }
    // Flip Cards
    else if (isFlip(this.props.card)) {
      // double the width to show the flipped card face
      zoomWidth *= 2;
      // rotate the flip card face
      zoomBackStyle = {
        transform: `rotate(180deg)`
      }
    }

    // calculate X (left) position adjustment, considering window edges
    let translateX = 0;
    const zoomRight = zoomLeft + zoomWidth;
    if ((zoomRight + ZOOM_MARGIN_SIDE) > window.innerWidth) {
      // shift to be ZOOM_OFFSET_SIDE pixels to the LEFT of the card
      const xOffset = ZOOM_OFFSET_SIDE  // undo the offset added to zoomLeft
        + cardImageRect.width           // plus width of the card itself
        + zoomWidth + ZOOM_OFFSET_SIDE; // plus the width of the zoom and offset
      // don't shift PAST the left side of the client window
      const maxOffset = zoomLeft - ZOOM_OFFSET_SIDE;
      translateX += -1 * Math.min(xOffset, maxOffset);
    }

    // calculate Y (top) position adjustment, considering client window edges
    const zoomTop = cardImageRect.top;
    const zoomBottom = zoomTop + zoomHeight;
    const translateY = Math.min(
      Math.max(0, ZOOM_MARGIN_TOP - zoomTop), // adjustment for top buffer
      window.innerHeight - zoomBottom - ZOOM_MARGIN_BOTTOM); // adjustment for bottom buffer
    
    // this styles the outer `div.zoom` that wraps the individual card face images
    const zoomStyle = {
      position: "fixed", 
      top: cardImageRect.top, 
      left: zoomLeft,
      transformOrigin: "left top",
      transform:
        `translate(${translateX}px, ${translateY}px) 
        scale(${ZOOM_IMG_SCALE})`,
    };

    return <div className="zoom" style={zoomStyle}>
        {this.renderZoomFrontImage(zoomFrontStyle)}
        {this.maybeRenderZoomBackImage(zoomBackStyle)}
      </div>;
  }

  renderZoomFrontImage(zoomFrontStyle) {
    // default image is missing (card back)
    let imageUri = "/errors/missing.jpg";
    // be funny on April 1st
    const today = new Date();
    if (today.getMonth() === 3 && today.getDate() === 1) {
      imageUri = "/errors/missingPurple.jpg"
    }

    const cardFrontImageUri = this.cardFaceImageUri(0);
    if (this.props.card.image_uris) {
      // use the default card image
      imageUri = this.props.card.image_uris.normal;
    }
    else if (cardFrontImageUri) {
      // use the front face card image
      imageUri = cardFrontImageUri;
    }
    return <img className="zoom-front" 
      style={zoomFrontStyle}
      src={imageUri} alt={this.props.card.name} />
  }

  maybeRenderZoomBackImage(zoomBackStyle) {
    const cardFrontImageUri = this.cardFaceImageUri(0);
    const cardBackImageUri  = this.cardFaceImageUri(1);

    let imageUri = undefined;
    if (this.displayAsAftermath(this.props.card) && cardFrontImageUri) {
      // Aftermath cards use the card front
      imageUri = cardFrontImageUri;
    }
    if (isFlip(this.props.card) && cardFrontImageUri) {
      // Flip cards use the card front
      imageUri = cardFrontImageUri;
    }
    else if (cardBackImageUri) {
      // otherwise if there is a card back image, use it
      imageUri = cardBackImageUri;
    }

    // render the image of a URI was found
    if (imageUri) {
      return <img className="zoom-back" 
        style={zoomBackStyle} 
        src={imageUri} 
        alt={this.props.card.name} /> 
    }
    else return undefined;
  }

  cardFaceImageUri(cardFaceIdx = 0) {
    // check card.image_uris first if getting this first card face
    if (cardFaceIdx === 0 && this.props.card.image_uris)
      return this.props.card.image_uris.normal;
    // if no image_uris, check for image_uris in card.card_faces 
    else if (this.props.card.hasOwnProperty("card_faces") && 
        this.props.card.card_faces[cardFaceIdx] &&
        this.props.card.card_faces[cardFaceIdx].hasOwnProperty("image_uris")) {
      return this.props.card.card_faces[cardFaceIdx].image_uris.normal;
    }
    else return undefined;
  }

  /**
   * Check if this card `isAftermath` AND if it is a non-Arena-only card.
   * 
   * Arena-only cards are remastered sets that have a janky no-text, non-rotated 
   * split image from Scryfall.
   * 
   * @param {*} card 
   * @returns 
   */
  displayAsAftermath(card) {
    return isAftermath(card) && 
        // don't display as Aftermath if it is Arena-only
        // `games` is the list of games that this card print is available in: paper, arena, and/or mtgo. 
        (!card.games || !(card.games.length === 1 && card.games[0] === "arena"));
  }

}