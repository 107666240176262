/**
 * Retrieve the HTML element for the given color symbol.
 * 
 * @param {String} symbol the single-character symbol representing a color (one of WUBRGC)
 * @param {boolean} withColor `true` to return a color element (vs grayscale)
 * @returns the HTML element to render for the color
 */
export function getColorElement(symbol, withColor = true) {
  // lower case symbol indicates splash color
  if (symbol === symbol.toLowerCase()) {
    return <span key={`color_splash_${symbol}`} alt={symbol} className={`color-splash color-splash-${symbol}`}>•</span>;
  }
  // return Mana Font icon for color symbol
  return <i key={`color_${symbol}`} className={`ms ms-${symbol.toLowerCase()}${withColor ? ' ms-cost' : ''}`}></i> 
}
