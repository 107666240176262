import React from 'react';

import './LabelRadio.css';

/**
 * An extended ReactSwitch that automatically includes a label that is 
 * visualized as disabled when the component is disabled.
 */
 export default class LabelRadio extends React.Component {

  render() {
    return <>
      <label htmlFor={this.props.id} title={this.props.title} 
          className={`label-radio ${this.props.disabled ? 'disabled' : ''}`}>
        <input
          type="radio"
          name={this.props.name}
          className="label-radio"
          id={this.props.id}
          disabled={this.props.disabled}
          value={this.props.value}
          checked={this.props.checked}
          onChange={this.props.onChange}
        />
        <span className="label">{this.props.children}</span>
      </label>
    </>;
  }
}