import React, { useState } from 'react';
import './Expan.css'

/**
 * @returns an expandable span from a short message to a longer message
 */
export default function Expan({ initial, className, title, children }) {
  const [isExpanded, setExpanded] = useState(false);

  const expandByClick = (event) => {
    // don't let the click that expands this component bubble up to other elements
    event.preventDefault();
    setExpanded(true);
  }

  // only expand if the key is Space (" ") or Enter (and definitely not Tab)
  const expandByKey = (event) => setExpanded(event.key === "Enter" || event.key === " ");

  // the initial/collapsed state of this expandable span
  const collapsed = 
    // eslint-disable-next-line
    <a className={`expan ${className ?? ''}`} 
        // The button role tells the user that the particular element is being treated as a button.
        // There is no href to cause a change in navigation in the current document, and therefore
        // there is no tabbing order by default.
        // https://stackoverflow.com/a/10510353
        role="button" tabIndex="0" 
        title={title}
        onClick={expandByClick} 
        onKeyDown={expandByKey} >
      {initial}
    </a>;
  
  // the expanded state of this expanable span
  const expanded = <span className={`expan expanded ${className ?? ''}`}>{children}</span>;

  return isExpanded ? expanded : collapsed;
}