import React from 'react';
import Switch from 'react-switch';

import './LabelSwitch.css';

/**
 * An extended ReactSwitch that automatically includes a label that is 
 * visualized as disabled when the component is disabled.
 */
 export default class LabelSwitch extends React.Component {

  render() {
    // If the component is disabled, set the label to disabled;
    // The `input:disabled+label` CSS selector doesn't seem to work when
    // the input component is nested within <div>s in the Switch component
    const disabledLabelClass = this.props.disabled ? 
      `labelswitch-disabled ${this.props.className}` 
      : this.props.className;

    return (
      <label className={disabledLabelClass} htmlFor={this.props.id} title={this.props.title}>
        <Switch 
          id={this.props.id}
          className={`labelswitch ${this.props.className}`}
          disabled={this.props.disabled} 
          // default height if not specified
          height={this.props.height ? this.props.height : 18}
          // default width if not specified
          width={this.props.width ? this.props.width : 28}
          // default color if not specified
          onColor={this.props.onColor ? this.props.onColor : "#09f" }
          onChange={this.props.onChange}
          checked={this.props.checked} 
          checkedIcon={false}
          uncheckedIcon={false}
        />
        {this.props.children}
      </label>
    );
  }
}