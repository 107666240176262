import React from 'react';

import { DragDropContext } from '@hello-pangea/dnd';
import SplitPane           from 'react-split-pane'

import DeckAnalysis                    from './DeckAnalysis';
import Deck                            from './Deck';
import Sideboard, { FullSetSideboard } from './Sideboard';
import TierList                        from './TierList';

import DiffState from '../services/diff-state';

/**
 * The default CardPool view which manages the DragDropContext and the top and bottom column lists.
 * 
 * Also see specialized versions: {@link FullSetEditor}
 */
export default class CardPoolEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 
      // show the sideboard by default
      showSideboard: true
    };
  }

  visibleSideboard = () => this.isSideboardFiltered() ? this.props.sideboardFiltered : this.props.sideboard;

  /**
   * @returns true if the cards in the sideboard are currently filtered
   */
  isSideboardFiltered = () => !!this.props.sideboardFiltered;

  /**
   * @returns the current visible deck pool, considering any active filtering
   */
  visibleDeck = () => this.isDeckFiltered() ? this.props.deckFiltered : this.props.deck;

  /**
   * @returns true if the cards in the deck are currently filtered
   */
  isDeckFiltered = () => !!this.props.deckFiltered;

  render() {
    const autoScrollerOptions /* : PartialAutoScrollerOptions */ = {
      // percentage distance from edge of container at which to start auto scrolling
      //startFromPercentage: 0.25,
      startFromPercentage: 0.1,
      // percentage distance from edge of container at which max scroll speed is achieved
      //maxScrollAtPercentage: 0.05,
      maxScrollAtPercentage: 0.01,
      // pixels per frame
      //maxPixelScroll: 28,
      // A function used to ease a percentage value for scroll
      //ease: (percentage: number): number => percentage ** 2,
      durationDampening: {
        // ms: how long to dampen the speed of an auto scroll from the start of a drag
        //stopDampeningAt: 1200,
        // ms: when to start accelerating the reduction of duration dampening
        //accelerateAt: 360,
      },
      // whether or not autoscroll should be turned off entirely
      // disabled: true
    }
    
    return (
      <DragDropContext 
          autoScrollerOptions={autoScrollerOptions}
          onBeforeCapture={this.onBeforeCapture} 
          onDragStart={this.onDragStart} 
          onDragEnd={this.onDragEnd}>
        <SplitPane
            className="cardpool-panes"
            split="horizontal"
            minSize={100}
            defaultSize="55%">
          {this.renderTop()}
          {this.renderDeck()}
        </SplitPane>
      </DragDropContext>
    );
  }

  renderDeck = () =>
    <Deck id="deck" 
      className="deck" 
      disabled={this.props.disabled}
      error={this.props.error}
      isFiltering={this.isDeckFiltered()}
      totalCardCount={this.totalDeckCardCount()}

      columns={this.prepareColumnCards(this.visibleDeck().columns)}
      splitColumns={this.prepareColumnCards(this.visibleDeck().splitColumns)}
      createDroppableId={this.createColumnId}

      onSortBy={this.props.controller.sortDeckBy}
      onSplitColumns={this.props.controller.splitDeckView}
      onPartitionNoncreatures={this.props.controller.partitionDeck}
      onCardTransform={this.props.controller.transformCard}
      onCardDoubleClick={this.onDeckCardDoubleClick}
      onClearDeck={this.props.controller.clearDeck}
      // `onSwapPools` is optional; button will not render if undefined
      onSwapPools={this.props.controller.swapPools}
    />;

  /**
   * Always render both top panels (Sideboard and Mana Sources/Deck Statistics) but hide 
   * one (`display: 'none'`) depending on if the sideboard is visible (`this.state.showSideboard`)
   */
  renderTop = () => {
    return <>
      <div className="cardpool-panes-top" style={this.state.showSideboard ? {} : { display: 'none' }}>
        {this.renderSideboard()}
      </div>
      <div className="cardpool-panes-top" style={this.state.showSideboard ? { display: 'none' } : {}}>
        {this.renderDeckStatistics()}
      </div>
    </>;
  }

  renderSideboard = () => {
    return <Sideboard id="sideboard" 
      className="sideboard" 
      disabled={this.props.disabled}
      error={this.props.error}
      isFiltering={this.isSideboardFiltered()}
      totalCardCount={this.totalSideboardCardCount()}

      columns={this.prepareColumnCards(this.visibleSideboard().columns)}
      //Note: Sideboard does not have `splitColumns`
      hiddenColumns={this.prepareColumnCards(this.props.hidden.columns)}
      createDroppableId={this.createColumnId}

      onSortBy={this.props.controller.sortSideboardBy}
      onCardTransform={this.props.controller.transformCard}
      onCardDoubleClick={this.onSideboardCardDoubleClick}
      onAddBasicLands={() => this.showSideboard(false)}
      onHideAllCards={this.props.onHideAllCards}
      onClearHidden={this.props.onClearHidden}
    />;
  }

  renderDeckStatistics = () =>
    <DeckAnalysis
      id="deck-analysis"
      className="deck-analysis"
      disabled={this.props.disabled}
      error={this.props.error}

      deckCards={this.allPoolCards(this.props.deck)}

      onShowSideboard={() => this.showSideboard(true)}
      addCards={this.props.controller.addCardsToDeck}
      removeCards={this.props.controller.removeCardsFromDeck}
    />;
  
  showSideboard = (visible) => {
    // switch between top sideboard pool and the land editor view
    this.setState({ showSideboard: visible });
  }

  /**
   * Prepare the cards in the provided columns to be displayed in the 
   * corresponding `Sideboard` or `Deck` card pool.
   * 
   * We already expect each column to have a `cards` property so this only
   * checks if we are preparing to drag a particular card via drag-and-drop
   * and, if so, sets that card's `isPreparingToDrag` property to true.
   * 
   * @param {Object[]} poolColumns the pool columns to prepare
   * @returns the pool columns, with `cards` prepared for the `Sideboard` or `Deck` card pools.
   */
  prepareColumnCards(poolColumns) {
    // if preparing to drag a card, change that card dynamically and only internally
    if (poolColumns && this.state.preparingToDragCardId) {
      return poolColumns.map(column => {
        const cards = column.cards.map(card => {
          // find the card that is preparing to drag
          if (card.cardId === this.state.preparingToDragCardId) {
            // clone and freeze the card that is preparing to drag
            return Object.freeze({ ...card, isPreparingToDrag: true });
          }
          else return card;
        });
        return { ...column, cards: cards };
      });
    }

    return poolColumns;
  }

  /**
   * @param {Boolean} includeProxy optionally include Proxy cards
   * @returns the unfiltered deck card count
   */
  totalDeckCardCount = (includeProxy = false) => 
    this.allPoolCards(this.props.deck, includeProxy).length;

  /**
   * @param {Boolean} includeProxy optionally include Proxy cards
   * @returns the unfiltered sideboard card count
   */
  totalSideboardCardCount = (includeProxy = false) => 
    this.allPoolCards(this.props.sideboard, includeProxy).length;

  /**
   * @param {Object} pool the pool object
   * @param {Boolean} includeProxy optionally include Proxy cards
   * @returns all of the pool's cards
   */
  allPoolCards(pool, includeProxy = false) {
    const columnCards = pool.columns
      .flatMap(column => column.cards)
      // don't count proxy cards
      .filter(card => includeProxy || !DiffState.isProxy(card.diffOrigin));

    const splitColumnCards = pool.splitColumns ?
      pool.splitColumns.flatMap(column => column.cards)
        // don't count proxy cards
        .filter(card => includeProxy || !DiffState.isProxy(card.diffOrigin))
      : [];

    return columnCards.concat(splitColumnCards);
  }
    
  onBeforeCapture = before => {
    const cardId = before.draggableId;
    // kind of a HACK, set `isPreparingToDrag` to notify Card
    this.setState({ preparingToDragCardId: cardId });
  }

  onDragStart = start => {
    // undo onBeforeCapture HACK
    this.setState({ preparingToDragCardId: null });
  }

  /**
   * Handle the @hello-pangea/dnd onDragEnd event when a Draggable (card) is dropped.
   * 
   * @param {*} result 
   * @returns 
   */
  onDragEnd = result => {
    const { destination, source } = result;

    // no drop target
    if (!destination) return;

    if (destination.droppableId === source.droppableId && 
        destination.index === source.index) {
      // the Draggable hasn't moved
      console.debug("the Draggable card", result.draggableId, "has not moved");
      return;
    }

    // identify the destination column and notify the controller
    const destColumn = this.parseColumnId(destination.droppableId);
    let destIndex = destination.index;
    // if dropping to the same column, the destination index will not include the dragged card
    if (destination.droppableId === source.droppableId && source.index < destination.index) {
      // if dropping further in the list, increment the destination index to match the 
      // controller state that still has the dragged card at a lower index
      destIndex = destination.index + 1;
    }
    this.props.controller.moveCard(result.draggableId, destColumn, destIndex);
  }

  /**
   * Handle the double-click event on a sideboard card, moving it to the deck.
   * 
   * @param {string} cardId the card identifier
   * @param {string} columnId the source column identifier
   */
  onSideboardCardDoubleClick = (cardId, columnId) => {
    // determine the source column parameters
    const sourceColumn = this.parseColumnId(columnId);

    // use the same column index as the source column
    const targetVisibleColumnIdx = sourceColumn.columnIdx;

    // the target index is the visible column index (this view doesn't know about column filtering)
    this.props.controller.moveCardToDeck(cardId, targetVisibleColumnIdx);
  }

  /**
   * Handle the double-click event on a deck card, moving it to the sideboard.
   * 
   * @param {string} cardId the card identifier
   * @param {string} columnId the source column identifier
   */
  onDeckCardDoubleClick = (cardId, columnId) => {
    // determine the source column parameters
    const sourceColumn = this.parseColumnId(columnId);

    // use the same column index as the source column
    const targetVisibleColumnIdx = sourceColumn.columnIdx;

    // the target index is the visible column index (this view doesn't know about column filtering)
    this.props.controller.moveCardToSideboard(cardId, targetVisibleColumnIdx);
  }

  /**
   * Create a Column Id string for a column (used as a Droppable Id)
   * 
   * @param {string} poolName 
   * @param {string} columnGroup 
   * @param {number} columnIdx 
   * @returns {string} a DroppableId string that uniquely identifes the column
   */
  createColumnId = (poolName, columnGroup, columnIdx) => poolName+"-"+columnGroup+"-"+columnIdx;

  /**
   * Determine the pool and column identifiers from the given Column (Droppable) ID
   * 
   * @param {string} droppableId the identifier of the droppable to parse
   * @returns {Object} An object describing the found column.  For example:
   * ```
     {
       poolName: "deck",
       columnGroup: "splitColumns",
       columnIdx: 4
     }
     ```
   */
  parseColumnId(columnId) {
    const parts = columnId.split("-");
    return { poolName: parts[0], columnGroup: parts[1], columnIdx: parts[2] };
  }

}


/**
 * Specialized version of {@link CardPoolEditor} view for visualizing a full card set.  
 * It does not have a hidden card pane.
 */
export class FullSetEditor extends CardPoolEditor {

  /**
   * Render the Sideboard view for a full set card pool.
   */
  renderSideboard = () => {
    return <>
      <FullSetSideboard id="sideboard" 
        className="sideboard" 
        disabled={this.props.disabled}
        error={this.props.error}
        setCode={this.props.setCode}

        isFiltering={this.isSideboardFiltered()}
        totalCardCount={this.totalSideboardCardCount(/* includeProxy = */ true)}

        columns={this.prepareColumnCards(this.visibleSideboard().columns)}
        createDroppableId={this.createColumnId}

        onSortBy={this.props.controller.sortSideboardBy}
        onCardTransform={this.props.controller.transformCard}
        onCardDoubleClick={this.onSideboardCardDoubleClick}
        onAddBasicLands={() => this.showSideboard(false)}
      />
    </>;
  }

}


/**
 * Specialized version of {@link CardPoolEditor} view for visualizing a tier list for a 
 * full card set.  It does not have a hidden card pane or basic lands / deck statistics.
 */
export class TierListEditor extends CardPoolEditor {

  /**
   * Render the Sideboard view for a full set card pool.
   */
  renderSideboard = () => {
    return <>
      <FullSetSideboard id="sideboard" 
        className="sideboard" 
        disabled={this.props.disabled}
        error={this.props.error}
        setCode={this.props.setCode}

        isFiltering={this.isSideboardFiltered()}
        totalCardCount={this.totalSideboardCardCount(/* includeProxy = */ true)}

        columns={this.prepareColumnCards(this.visibleSideboard().columns)}
        createDroppableId={this.createColumnId}

        onSortBy={this.props.controller.sortSideboardBy}
        onCardTransform={this.props.controller.transformCard}
        onCardDoubleClick={this.onSideboardCardDoubleClick}
      />
    </>;
  }

  renderDeck = () =>
    <TierList 
      // the ID is used to distinguish between sideboard/deck pool
      id="deck" 
      className="tierlist" 
      disabled={this.props.disabled}
      error={this.props.error}
      isFiltering={this.isDeckFiltered()}
      totalCardCount={this.totalDeckCardCount()}

      columns={this.prepareColumnCards(this.visibleDeck().columns)}
      splitColumns={this.prepareColumnCards(this.visibleDeck().splitColumns)}
      createDroppableId={this.createColumnId}

      onSplitColumns={this.props.controller.splitDeckView}
      onCardTransform={this.props.controller.transformCard}
      onCardDoubleClick={this.onDeckCardDoubleClick}
      onClear={this.props.controller.clearDeck}
    />;

}