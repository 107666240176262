import React from 'react';

import CardPoolEditor, { FullSetEditor, TierListEditor } from './CardPoolEditor';

import { 
  ColorCardFilter, 
  FilterType }   from '../services/card-filters';

import { getCardFront, isCreature } from '../helpers/card.helpers';

const TIMEOUT_FETCH_CARDS = 600;

/**
 * The default CardPool controller which creates a CardPoolEditor and manages the CardPool.
 * 
 * Also see specialized versions: {@link FullSetController}
 */
export default class CardPoolController extends React.Component {

  render() {
    const isSideboardFiltered = this.isFiltering(this.props.sideboard);
    const isDeckFiltered = this.isFiltering(this.props.deck);
    return (
      <CardPoolEditor 
        controller={this}
        disabled={this.props.disabled}
        error={this.props.error}

        sideboard={this.convertPoolColumns(this.props.sideboard, false)}
        // only pass `sideboardFiltered` when the sideboard cards are filtered
        sideboardFiltered={isSideboardFiltered ? 
          this.convertPoolColumns(this.props.sideboard, true): undefined}

        // hidden cards cannot be filtered
        hidden={this.convertPoolColumns(this.props.hidden, false)}
        onHideAllCards={this.hideAllSideboardCards}
        onClearHidden={this.clearHiddenCards}

        deck={this.convertPoolColumns(this.props.deck, false)}
        // only pass `deckFiltered` when the deck cards are filtered
        deckFiltered={isDeckFiltered ? this.convertPoolColumns(this.props.deck, true) : undefined}
      />
    );
  }

  /**
   * @returns true if filtering is enabled.  Filters may be enabled even when all cards are visible.
   */
  isFiltering = (pool) => this.props.cardfilters.length 
    // sideboard is always filtered, deck is only filtered when `this.props.isFilterAllActive` is true
    && (pool === this.props.sideboard || this.props.isFilterAllActive);
  
  /**
   * Convert a pool's column contents from `cardIds` to `cards` and return a pool object with 
   * only visible cards and columns, optionally applying any active card filters.
   * 
   * @param {Object} pool the pool containing `columns` and (optionally) `splitColumns` arrays.
   * @param {Boolean} filterCards `true` to apply active card filters and remove empty columns.
   * @returns {Object} the pool with only its visible cards and unfiltered columns
   */
  convertPoolColumns(pool, filterCards = false) {
    // filter and convert column `cardIds` to `cards`
    let visibleColumns = this.convertColumnCardIds(pool.columns, filterCards);
    let visibleSplitColumns = this.convertColumnCardIds(pool.splitColumns, filterCards);

    if (filterCards) {
      // Identify which column indexes are non-empty (have cards), based on both the `columns` 
      // and (if defined) `splitColumns` for the pool.  The i-th index of `nonEmptyColumns` will be
      // `true` if the i-th column contains a card in either the `columns` or `splitColumns` array.

      // when filtering, show both columns/splitColumns if either is non-empty
      const nonEmptyColumns =  this.nonEmptyColumnIndexes(visibleColumns, visibleSplitColumns);
      visibleColumns = visibleColumns.filter((col, i) => nonEmptyColumns[i]);
      if (visibleSplitColumns) {
        visibleSplitColumns = visibleSplitColumns.filter((col, i) => nonEmptyColumns[i]);
      }
    }

    const visiblePool = { columns: visibleColumns };
    if (visibleSplitColumns) {
      visiblePool.splitColumns = visibleSplitColumns;
    }
    return visiblePool;
  }

  /**
   * Convert each column's contents from `cardIds` to `cards` and return a column array containing
   * actual cards, optionally without any filtered cards.
   * 
   * @param {Object[]} columns the columns array to filter
   * @param {Object[]} filterCards true to filter out the not {@link isVisible} cards
   * @returns {Object} the filtered pool columns
   */
  convertColumnCardIds(columns, filterCards = false) {
    if (columns) {
      return columns.map(column => {
        // map each `cardId` to the card and, if filtering, exclude those that are not visible
        const visibleCards = column.cardIds
          .flatMap(id => {
            const card = this.props.cardpool.card(id);
            const hidden = filterCards && !this.isVisible(card);
            return hidden ? [] : [card];
          });
        // destructure the column to drop `cardIds`
        const { cardIds: _, ...visibleColumn } = { ...column, cards: visibleCards };
        return visibleColumn;
      });
    }
    else return undefined;
  }

  /**
   * Return an array identifying which column indexes are non-empty (have cards), based on both
   * the provided `columns` and (if defined) `splitColumns` for a pool.  The i-th index will be
   * `true` if the i-th column contains a card in either the `columns` or `splitColumns` array.
   * 
   * @param {Object[]} columns the columns array for a pool 
   * @param {Object[]} splitColumns the splitColumns array for a pool
   * @returns {boolean[]} an array of boolean values representing whether the column at 
   * that index is non-empty.
   */
  nonEmptyColumnIndexes(columns, splitColumns) {
    // combine all column cards; the entire column is visible if either contain cards
    const nonEmptyColumns =  [];
    const maxColumns = Math.max(columns.length, splitColumns ? splitColumns.length : 0);
    for (let i = 0; i < maxColumns; i++) {
      const nonEmptyColumn = (columns?.length > i && !this.isColumnEmpty(columns[i]));
      const nonEmptySplitColumn = (splitColumns?.length > i && !this.isColumnEmpty(splitColumns[i]));
      // a column is entirely hidden if all of its cards are `hidden`
      nonEmptyColumns.push(nonEmptyColumn || nonEmptySplitColumn);
    }

    return nonEmptyColumns;
  }

  /**
   * CardPool columns are empty if they have no cards.
   * 
   * @param {Object} column 
   * @returns true if the given column is empty
   */
  isColumnEmpty = (column) => !(column.cards?.length || column.cardIds?.length);

  /**
   * Apply all `this.props.cardfilters` to determine if a card is visible.
   * 
   * @param {Object} card the card to check
   * @returns true if the card is visible
   */
  isVisible(card) {
    // check for visibility across all non-standard filters
    const standardFilterTypes = new Set(
      // Color and ColorStrict
      [FilterType.Color, FilterType.ColorStrict, 
        // Card Types including Instant Speed (Instant and Flash)
        FilterType.CardType, FilterType.InstantSpeed,
        FilterType.Rarity]);
    // check visibility of card by any other filter (visible if no other filters)
    const otherFilters = this.props.cardfilters.filter(f => !standardFilterTypes.has(f.type));
    const isOtherwiseVisible = !otherFilters.length || otherFilters.some(f => f.isVisible(card));
    // check card visibility for each filter group separately so that they combine as expected
    // e.g., black mythic enchantments
    return (this.isVisibleByColor(card) 
      && this.isVisibleByCardType(card) 
      && this.isVisibleByRarity(card) 
      && isOtherwiseVisible);
  }

  /**
   * @param {Object} card the card to check
   * @returns true if the card is visible after applying the color card filters
   */
  isVisibleByColor(card) {
    const colorFilters = this.props.cardfilters.filter(f => f.type === FilterType.Color);
    // determine if the strict color filter is active/selected
    const strict = this.props.cardfilters.find(f => f.matches(FilterType.ColorStrict));
    if (strict) {
      return ColorCardFilter.evaluateOnly(card, colorFilters);
    }
    else {
      return ColorCardFilter.evaluateAny(card, colorFilters);
    }
  }

  /**
   * @param {Object} card the card to check
   * @returns true if the card is visible after applying the FilterType.CardType card filters
   */
  isVisibleByCardType(card) {
    const typeFilters = this.props.cardfilters.filter(f => 
      // Card Types including Instant Speed (Instant and Flash)
      f.type === FilterType.CardType || f.type === FilterType.InstantSpeed);
    if (!typeFilters.length) return true;
    return typeFilters.some(f => f.isVisible(card));
  }

  /**
   * @param {Object} card the card to check
   * @returns true if the card is visible after applying the FilterType.Rarity card filters
   */
  isVisibleByRarity(card) {
    const typeFilters = this.props.cardfilters.filter(f => f.type === FilterType.Rarity);
    if (!typeFilters.length) return true;
    return typeFilters.some(f => f.isVisible(card));
  }

  /**
   * Clear the deck and refresh the view state.
   */
  clearDeck = () => {
    this.props.cardpool.clearDeck();
    this.props.refreshViewState();
  }

  /**
   * Sort the sideboard columns according to the provided sort function and then refresh 
   * the view state.
   * 
   * @param {SortFn} sortFn the pool columns sort function
   */
  sortSideboardBy = (sortFn) => {
    this.props.cardpool.sortSideboardBy(sortFn);
    this.props.refreshViewState();
  }

  /**
   * Sort the deck columns (and split columns) according to the provided sort function and 
   * then refresh the view state.
   * 
   * @param {SortFn} sortFn the pool columns sort function
   */
  sortDeckBy = (sortFn) => {
    this.props.cardpool.sortDeckBy(sortFn);
    this.props.refreshViewState();
  }

  /**
   * Sets whether the deck view is split or not.
   * 
   * @param {boolean} split `true` to split the deck view
   */
  splitDeckView = (split) => {
    this.props.cardpool.splitDeck(split);
    this.props.refreshViewState();
  }

  /**
   * Split the deck view and partition the creature cards from the non-creatures.
   */
  partitionDeck = () => {
    const isFrontFaceCreature = (card) => {
      // use the card front to partition creatures
      const cardFront = getCardFront(card);
      return isCreature(cardFront);
    }

    this.props.cardpool.splitDeck(true, isFrontFaceCreature);
    this.props.refreshViewState();
  }

  /**
   * @param {string} cardId the ID of the card to be moved
   * @param {Object} destColumn An object describing the destination column in the view, e.g.:
   * ```
     {
       poolName: "deck",
       columnGroup: "splitColumns",
       columnIdx: 4
     }
     ```
   * @param {number} destCardIdx the index within the view's `destColumn` to move the card
   */
  moveCard = (cardId, destColumn, destCardIdx) => {
    const translated = this.translateIndexes(
      destColumn.poolName, destColumn.columnGroup, destColumn.columnIdx, destCardIdx);

    this.props.cardpool.moveCard(cardId, destColumn.poolName, destColumn.columnGroup, 
      translated.columnIdx, translated.cardIdx);
    this.props.refreshViewState();
  }

  /**
   * Move the specified card to the sideboard.
   * 
   * @param {string} cardId the ID of the card to be moved
   * @param {number} destColumnIdx the index of the visible destination column
   */
  moveCardToSideboard = (cardId, destColumnIdx) => {
    // translate column index because sideboard columns might be filtered
    const translated = this.translateIndexes("sideboard", "columns", destColumnIdx);
    const targetColumnIdx = translated.columnIdx;
    
    this.props.cardpool.moveCard(cardId, "sideboard", "columns", targetColumnIdx);
    this.props.refreshViewState();
  }

  /**
   * Move the specified card to the deck.
   * 
   * @param {string} cardId the ID of the card to be moved
   * @param {number} destColumnIdx the index of the visible destination column
   */
  moveCardToDeck = (cardId, destColumnIdx) => {
    // translate column index because deck columns might be filtered
    const translated = this.translateIndexes("deck", "columns", destColumnIdx);
    const targetColumnIdx = translated.columnIdx;

    this.props.cardpool.moveCard(cardId, "deck", "columns", targetColumnIdx);
    this.props.refreshViewState();
  }

  /**
   * Add the specified number of cards with the given name to the card pool.
   * 
   * @param {string} name the name of the card to add
   * @param {number} count the number of cards to add
   */
  addCardsToDeck = (name, count) => {
    this.props.cardpool.addCardsToDeck(name, count);
    // this _should_ immediately show the card if it was "known"
    this.props.refreshViewState();

    // delay fetching the new/missing card data in case another change occurs soon
    clearTimeout(this.fetchCardTimer);
    this.fetchCardTimer = setTimeout(() => 
      // fetch missing cards and then refresh the view state
      this.props.cardpool.fetchMissingBestCards().then(this.props.refreshViewState),
      TIMEOUT_FETCH_CARDS);
  }

  /**
   * Delete the specified number of cards with the given name to the card pool.
   * 
   * @param {string} name the name of the card to delete
   * @param {number} count the number of cards to delete
   */
  removeCardsFromDeck = (name, count) => {
    this.props.cardpool.deleteCardsFromDeck(name, count);
    this.props.refreshViewState();
  }

  /**
   * Move all of the sideboard cards to the hidden card pool.
   */
  hideAllSideboardCards = () => {
    this.moveAllColumnCards(this.props["sideboard"].columns, "hidden");
    this.props.refreshViewState();
  }

  /**
   * Move all of the hidden cards to the sideboard card pool.
   */
  clearHiddenCards = () => {
    this.moveAllColumnCards(this.props["hidden"].columns, "sideboard");
    this.props.refreshViewState();
  }

  swapPools = () => {
    // store a local copy of the cardIds in the sideboard and deck split/columns;
    // otherwise after the first move, the cards will just be moved back!
    const sideboardColumns = this.props["sideboard"].columns.map(column => 
      ({ cardIds: [...column.cardIds] })
    );
    const deckColumns = this.props["deck"].columns.map(column => 
      ({ cardIds: [...column.cardIds] })
    );
    const deckSplitColumns = this.props["deck"].splitColumns?.map(column => 
      ({ cardIds: [...column.cardIds] })
    );

    // move sideboard columns
    this.moveAllColumnCards(sideboardColumns, "deck");
    // move deck columns
    this.moveAllColumnCards(deckColumns, "sideboard");
    // maybe move deck split columns?
    console.debug("deckSplitColumns", deckSplitColumns);
    if (deckSplitColumns) {
      this.moveAllColumnCards(deckSplitColumns, "sideboard");
      // always turn off a split deck
      this.props.cardpool.splitDeck(false);
    }

    this.props.refreshViewState();
  }

  /**
   * Move all of the column's cards to the destination pool's `columns`.
   * 
   * @param {Object[]} sourceColumns 
   * @param {String} destPoolName 
   */
  moveAllColumnCards = (sourceColumns, destPoolName) => {
    sourceColumns.forEach((column, colIdx) => {
      column.cardIds.forEach(cardId => {
        this.props.cardpool.moveCard(cardId,
          /* destPoolName = */ destPoolName, 
          /* destColumnGroup = */ "columns",
          /* destColumnIdx = */ colIdx
          );
      });
    });
  }

  /**
   * Transform the specified card.
   * 
   * @param {string} cardId the ID of the card to be transformed
   */
  transformCard = (cardId) => {
    const card = this.props.cardpool.card(cardId);
    this.props.cardpool.transform(cardId, !card.transformed);
    this.props.refreshViewState();
  }

  /**
   * Find the actual column and (optionally) card indexes, considering which columns are visible/hidden.
   */
  translateIndexes(poolName, columnGroup, columnIdx, cardIdx) {
    if (poolName !== "deck" && poolName !== "sideboard" && poolName !== "hideboard") {
      console.error("Cannot translate column ", columnIdx, "for an unknown pool '", poolName, "'");
      return { columnIdx: columnIdx, cardIdx: cardIdx };
    }

    const pool = this.props[poolName];
    const actualColumns = pool[columnGroup];

    let viewColumns = actualColumns;
    if (this.isFiltering(pool)) {
      // filter out hidden *COLUMNS* but DO NOT filter out all individual *CARDS*
      // (or the remaining columns will not be findable with `actualColumns.indexOf`)
      // to do this we need to first generate the columns *without* hidden cards
      //Note: using `convertColumnCardIds()` to filter to the visible cards
      const visibleColumns = this.convertColumnCardIds(pool.columns, /* filterCards = */ true);
      const visibleSplitColumns = this.convertColumnCardIds(pool.splitColumns, /* filterCards = */ true);
      // identify the column indexes that are visible vs hidden (all cards hidden)
      const visibleColumnIndexes = this.nonEmptyColumnIndexes(visibleColumns, visibleSplitColumns);
      // these are the visible columns (including ALL cards) for the specified `columnGroup`
      viewColumns = pool[columnGroup].filter((col, i) => visibleColumnIndexes[i]);
    }

    let actualColumnIdx;

    // the column index might be beyond the visible columns
    if (columnIdx >= viewColumns.length) {
      if (this.isFiltering(pool)) {
        //Note: when filtering we can't easily translate to the "actual" column
        // index beyond the last visible column (because columns may exist but
        // not be visible) and we may end up with many extra empty columns if we
        // try to go to the exact same column index.

        // So we translate to the column index immediately AFTER the last visible
        // column in the destination pool.  This may not exist, so we rely on 
        // `CardPool.moveCard()` to create the new column if necessary.
        
        const lastVisibleColumn = viewColumns[viewColumns.length - 1];
        const actualLastVisibleColumnIdx = actualColumns.indexOf(lastVisibleColumn);
        // if the column is not found (or lastVisibleColumn is undefined) the index 
        // will be -1, which increments to 0 and that's a valid result
        actualColumnIdx = actualLastVisibleColumnIdx + 1;
      }
      else {
        // Otherwise, just use the provided column index
        actualColumnIdx = columnIdx;
      }
    }
    else {
      // translate visible column index to actual column index
      const visibleColumn = viewColumns[columnIdx];
      actualColumnIdx = actualColumns.indexOf(visibleColumn);
    }

    console.debug("translated visible destination column at index", columnIdx,
      "to actual index of", actualColumnIdx, "in [", poolName, ", columns ]");

    // exit early if no actual column at that index OR if no cardIdx to translate
    if (actualColumns.length < actualColumnIdx || typeof cardIdx === 'undefined') {
      return { columnIdx: actualColumnIdx };
    }
    //else if cardIdx is defined, also translate the card index within the actual column
    const actualColumn = actualColumns[actualColumnIdx];

    // exit early if there is no existing column in the viewColumn
    if (columnIdx >= viewColumns.length) {
      // add to the end of `actualColumn` or index 0
      const actualCardIdx = actualColumn ? actualColumn.cardIds.length : 0;
      return { columnIdx: actualColumnIdx, cardIdx: actualCardIdx };
    }
    // filter out the individual cardIds from the visible `viewColumns`
    //Note: using `convertColumnCardIds()` to filter to the visible cards
    const visibleColumns = this.convertColumnCardIds(viewColumns, this.isFiltering(pool));
    const visibleCards = visibleColumns[columnIdx].cards;
    
    let actualCardIdx = 0;
    if (cardIdx > 0) {
      // find visible cards to determine card position
      const previousVisibleCardId = visibleCards[cardIdx - 1].cardId;

      // translate visible card index to actual card index within the destination column
      // calculate the actual destination index: after the previous visible card
      const actualCardIdxPrevious = actualColumn.cardIds.indexOf(previousVisibleCardId);
      actualCardIdx = actualCardIdxPrevious < 0 ? 
        actualColumn.cardIds.length : 
        actualCardIdxPrevious + 1;

      console.debug("translated visible destination card index", cardIdx, 
        "to follow the previous visible card [", 
        previousVisibleCardId ? this.props.cardpool.card(previousVisibleCardId).name : undefined,
        "] at", actualCardIdxPrevious, "for actual destination index of", actualCardIdx);
    }
    // otherwise, if the actual target column exists but the visible column is empty
    else if (actualColumn && !visibleCards.length) {
      // set to the end of the existing column (the move was not intentionally moving 
      // to the top, but rather the view did not know other cards existed in the column)
      actualCardIdx = actualColumn.cardIds.length;
    }

    return { columnIdx: actualColumnIdx, cardIdx: actualCardIdx };
  }

}


/**
 * Specialized version of {@link CardPoolController} that creates a {@link FullSetEditor} 
 * view when managing a CardPool that represents a full card set.
 */
export class FullSetController extends CardPoolController {

  render() {
    const isSideboardFiltered = this.isFiltering(this.props.sideboard);
    const isDeckFiltered = this.isFiltering(this.props.deck);
    return (
      <FullSetEditor 
        controller={this}
        disabled={this.props.disabled}
        error={this.props.error}
        setCode={this.props.setCode}

        sideboard={this.convertPoolColumns(this.props.sideboard, false)}
        // only pass `sideboardFiltered` when the sideboard cards are filtered
        sideboardFiltered={isSideboardFiltered ? 
          this.convertPoolColumns(this.props.sideboard, true): undefined}

        deck={this.convertPoolColumns(this.props.deck, false)}
        // only pass `deckFiltered` when the deck cards are filtered
        deckFiltered={isDeckFiltered ? 
          this.convertPoolColumns(this.props.deck, true) : undefined}
      />
    );
  }

  /**
   * FullSetController does not provide pool swap functionality.
   * (This hides the `Deck.onSwapPools` button)
   */
  swapPools = undefined;

}


/**
 * Specialized version of {@link CardPoolController} that creates a {@link TierListEditor} 
 * view when managing a CardPool that represents a full card set.
 */
export class TierListController extends CardPoolController {

  render() {
    const isSideboardFiltered = this.isFiltering(this.props.sideboard);
    const isDeckFiltered = this.isFiltering(this.props.deck);
    return (
      <TierListEditor 
        controller={this}
        disabled={this.props.disabled}
        error={this.props.error}
        setCode={this.props.setCode}

        sideboard={this.convertPoolColumns(this.props.sideboard, false)}
        // only pass `sideboardFiltered` when the sideboard cards are filtered
        sideboardFiltered={isSideboardFiltered ? 
          this.convertPoolColumns(this.props.sideboard, true): undefined}

        deck={this.convertPoolColumns(this.props.deck, false)}
        // only pass `deckFiltered` when the deck cards are filtered;
        // empty columns should still appear in a filtered Tier List
        deckFiltered={isDeckFiltered ? this.convertPoolColumns(this.props.deck, true) : undefined}
      />
    );
  }

  /**
   * TierList columns are empty if they have no cards and no title.
   * 
   * @param {Object} column 
   * @returns true if the given column is empty
   */
  isColumnEmpty = (column) => !(column.cards?.length || column.cardIds?.length || column.title);
}
