import ButtonLink from './ButtonLink';

import './KoFi.css';

/**
 * @returns a KoFi donation link styled as a button
 */
export default function PatreonButton() {
  return <ButtonLink 
      className="kofi-button"
      target="_blank" 
      href="https://ko-fi.com/sealeddecktech"
      rel="noopener" 
      title="Buy me a coffee"> 
    <span className="kofitext">
      <img src="https://storage.ko-fi.com/cdn/cup-border.png" 
          className="kofiimg"
          alt="Ko-fi.com" 
      /> Buy me a coffee
    </span>
  </ButtonLink>
}
