import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

// disable logging in `production` environment
if (process.env.NODE_ENV === 'production') {
  if(!window.console) window.console = {};
  // do NOT disable console.error and console.warn
  const methods = ["log", "debug", "info"];
  for(let i = 0; i < methods.length; i++) {
      console[methods[i]] = function() {};
  }
}

const app = () => {
  return (
    // All Routes lead to <App />
    <Router>
      <Switch>

        {/* Routes for retrieving 17lands data */}
        <Route path="/17lands/pool/:seventeenlands_id">
          <App />
        </Route>
        <Route path="/17lands/deck/:seventeenlands_id/:deck_id">
          <App />
        </Route>
        <Route path="/17lands/tier_list/:seventeenlands_tier_list_id">
          <App />
        </Route>

        {/* Route for loading a tier list */}
        <Route path="/sets/:tier_list_set_code/tierlists/:tier_list_pool_id?">
          <App />
        </Route>
        <Route path="/tierlists/:tier_list_pool_id">
          <App />
        </Route>

        {/* Route for loading a set */}
        <Route path="/sets/:set_code/:pool_id?">
          <App />
        </Route>

        {/* Normal route for loading a pool (with optional poolId) */}
        <Route path="/:pool_id?">
          <App />
        </Route>

      </Switch>
    </Router>
  );
}

ReactDOM.render(
  app(),
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
