import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchangeAlt }   from '@fortawesome/free-solid-svg-icons/faExchangeAlt';

import SortableColumnList from './SortableColumnList';

import Switch from '../components/widgets/LabelSwitch';
import { getCardFront, isCreature, isLand, isSaga } from '../helpers/card.helpers';

export default class Deck extends SortableColumnList {

  constructor(props) {
    super(props);
    this.name = "Deck";
  }

  renderPoolDetails() {
    //NOTE: these counts do not know the original unfiltered card count!
    const deckCards = Object.values(this.allPoolCards());
    const count = deckCards.length

    const lands = this.lands(deckCards).length;
    // include Modal DFC land count if any exist
    const modalLands = this.modalLands(deckCards).length;
    const landCount = modalLands > 0 ? lands + ` (+${modalLands})` : lands;

    const creatures = this.creatures(deckCards).length;
    // include Saga Creature count if any exist
    const sagaCreatures = this.sagaCreatures(deckCards).length;
    const creatureCount = sagaCreatures > 0 ? creatures + ` (+${sagaCreatures})` : creatures;

    return (
      <React.Fragment>
        <span className="pool-details">Lands: {landCount}</span>
        <span className="pool-details">Creatures: {creatureCount}</span>
        <span className="pool-details">Other: {count-lands-creatures}</span>
      </React.Fragment>
    );
  }

  renderPoolControls() {
    return (
      <React.Fragment>
        <span className="pool-controls">
          Sort by: 
          <button disabled={this.props.disabled} onClick={this.sortByCMC}>Mana Value</button>
          {this.renderSortByColorControl()}
          <button disabled={this.props.disabled} onClick={this.sortByRarity}>Rarity</button>
          {this.maybeRenderSortByGihwrControl()}
          {this.maybeRenderSortByRatingsControl()}
        </span>
        <span className="pool-controls">
          <button disabled={this.props.disabled} onClick={this.props.onPartitionNoncreatures}>
            Separate Noncreatures
          </button>
          <Switch 
              className="split-switch"
              disabled={this.props.disabled} 
              onChange={this.props.onSplitColumns} 
              checked={this.props.splitColumns ? true : false} >
            Split View
          </Switch>
        </span>
        <span className="pool-controls">
          {this.maybeRenderSwapButton()}
          <button 
              className="deck-clear" 
              disabled={this.props.disabled} 
              onClick={this.props.onClearDeck}>
            Clear Deck
          </button>
        </span>
      </React.Fragment>
    );
  }

  maybeRenderSwapButton() {
    if (this.props.onSwapPools) {
      return <button 
          className="deck-swap" 
          disabled={this.props.disabled}
          onClick={this.props.onSwapPools}>
        <FontAwesomeIcon icon={faExchangeAlt} rotation={90} size="sm" />&nbsp;Swap
      </button>;
    }
  }
  
  lands(cards) {
    return cards.filter(card => {
      // use the card front to calculate land count
      const cardFront = getCardFront(card);
      return isLand(cardFront);
    });
  }
  
  modalLands(cards) {
    return cards.filter(card => {
      const cardFront = getCardFront(card);
      // check if this is a Modal DFC
      return (card.layout === "modal_dfc") 
        // don't count twice if the front face is a land, too
        && !isLand(cardFront)
        // check if the back side is a land?
        && card.hasOwnProperty("card_faces") 
        && card.card_faces[1].type_line.includes("Land");
    });
  }

  sagaCreatures(cards) {
    return cards.filter(card => {
      const cardFront = getCardFront(card);
      // check if this is a Tranforming DFC Saga
      return (card.layout === "transform" && isSaga(cardFront)
          // with a Creature on the back
          && card.hasOwnProperty("card_faces") 
          && isCreature(card.card_faces[1]));
    });
  }

  creatures(cards) {
    return cards.filter(card => {
      // use the card front to calculate creature count
      const cardFront = getCardFront(card);
      return cardFront.type_line && cardFront.type_line.includes("Creature");
    });
  }
}
