import React from 'react';
import {
  Bar, 
  BarChart, 
  CartesianGrid, 
  LabelList, 
  Tooltip,
  XAxis, 
  YAxis, 
} from 'recharts';

import LabelRadio from '../components/widgets/LabelRadio';
import Switch from '../components/widgets/LabelSwitch';

import { 
  groupCardsByColor
} from '../helpers/card-pool.helpers';
import {
  cmcFromManaCost, 
  colorCombinationFromManaCost,
  getCardFacePips,
  getCastableCardFaces,
  isCreature,
  isLand,
  toColorCombination,
} from '../helpers/card.helpers';
import {
  ColorCombinationMap,
  Colorless, 
  Multicolor, 
  OrderedColorsAndGroups,
  WUBRG
} from '../constants'

const CHART_HEIGHT = 180;
const CHART_Y_AXIS_WIDTH = 30;
const CHART_BAR_WIDTH = 16;
const CHART_LABEL_ROTATE_DEGREES = -40;
const MANA_SYMBOL_WIDTH = 20;
const MANA_SYMBOL_HEIGHT = 22;

const ChartColors = {
  "W" : { name: "White", htmlColor: "var(--mtg-white-dark)" },
  "U" : { name: "Blue",  htmlColor: "var(--mtg-blue-dark)" },
  "B" : { name: "Black", htmlColor: "var(--mtg-black-dark)" },
  "R" : { name: "Red",   htmlColor: "var(--mtg-red-dark)" },
  "G" : { name: "Green", htmlColor: "var(--mtg-green-dark)" },
  [Colorless] : { name: "Colorless", htmlColor: "var(--mtg-colorless)" },
  [Multicolor] : { name: "Multicolor", htmlColor: "var(--mtg-gold)" }
};

const ChartOptions = [
  { id: "chart-pips", description: "Pips by Color" },
  { id: "chart-color", description: "Spells by Color" },
  { id: "chart-cmc", description: "Spells by Mana Value" },
  { id: "chart-cmc-pips", description: "Pips by Mana Value" }
  //TODO { id: "chart-mana", description: "Mana Cost" }
];

export default class DeckCharts extends React.Component {
  constructor(props) {
    super(props);

    // initialize the default/initial state
    this.state = { 
      chartType: ChartOptions[0].id,
      creaturesOnly: false
    };
  }

  setChartOption = (event) => {
    const newType = event.target.value;
    if (this.state.chartType !== newType) {
      this.setState( { chartType: event.target.value });
    }
  }

  showCreatures = (showCreatures) => {
    this.setState({ creaturesOnly: showCreatures });
  }

  render() {
    return <div className="deck-stats">
      <div className="deck-stats-title">Deck Statistics</div>
      <div className="deck-stats-content">  
        {this.renderChartOptions()}
        {this.renderChart(this.props.deckCards)}
      </div>
    </div>;
  }

  /**
   * Render the deck chart options (radio buttons)
   */
  renderChartOptions() {
    return <div className="deck-stats-chart-options">
      {ChartOptions.map((chart, idx) => 
        <div className="deck-stats-chart-option" key={`deck-chart-${idx}`}>
          <LabelRadio
              id={chart.id}
              name={chart.id}
              value={chart.id}
              disabled={this.props.disabled}
              checked={this.state.chartType === chart.id}
              onChange={this.setChartOption} >
            {chart.description}
          </LabelRadio>
        </div>
      )}
      <div className="deck-stats-chart-filter-creatures">
        <Switch
            disabled={this.props.disabled} 
            onChange={this.showCreatures} 
            checked={this.state.creaturesOnly}
            >
          Creatures Only
        </Switch>
      </div>
    </div>;
  }

  renderChart(deckCards) {
    //Note: each chart should render when empty
    switch(this.state.chartType) {
      // Colored Pips
      case ChartOptions[0].id:
        return this.renderPipsChart(deckCards);
      // Card Color
      case ChartOptions[1].id:
        return this.renderColorChart(deckCards);
      // Mana Value
      case ChartOptions[2].id:
        return this.renderManaValueChart(deckCards);
      // Mana Value Pips
      case ChartOptions[3].id:
        return this.renderManaValuePipsChart(deckCards);
      default:
        return <></>;
    }
  }

  renderPipsChart(deckCards) {
    // initialize the map with zero of each colored pip
    const zeroPips = { "W": 0, "U": 0, "B": 0, "R": 0, "G": 0 };

    // for each castable card face, calculate the chart data by colored pips
    const castableCardFaces = deckCards.flatMap(getCastableCardFaces)
      // maybe filter card faces by creature-only (i.e., for Adventures)
      .filter(face => !this.state.creaturesOnly || isCreature(face));
  
    // count colored card pips in mana cost
    const pipCounts = castableCardFaces.reduce(
      // update the colored pip counts for this card face
      (coloredPipCount, cardFace) => this.addPipCountToMap(cardFace, coloredPipCount),
      zeroPips);

    //TODO Refactor? Below this point is duplicated in `renderManaValuePipsChart`
    // maybe include colorless pip in chart?
    const chartColors = WUBRG.slice();
    if (pipCounts["C"]) {
      chartColors.push(Colorless);
    }
    
    // compile the chart data for each bar chart pip color
    const allPipColors = Object.keys(pipCounts);
    const colorChartData = chartColors.map(color => 
      // count each multicolor combination that includes the current color (string) in its attribute
      allPipColors.reduce((data, c) => {
          if (c.includes(color)) data[c] = pipCounts[c];
          return data;
        }, 
        // initialize the chart data with an entry for each color
        /* initialValue = */ { color: color }
      )
    );

    // order the stacked bars to match the card column sorting
    const stackedBarOrder = OrderedColorsAndGroups.slice().reverse();
    return <BarChart
        width={this.chartWidth(chartColors.length)}
        height={CHART_HEIGHT}
        data={colorChartData}
        barSize={CHART_BAR_WIDTH}
      >
        <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
        <XAxis 
          dataKey="color" 
          interval={0} // if set to 0, all the ticks will be shown
          tick={this.renderAxisTickAsManaCost} />
        <YAxis 
          allowDecimals={false}  // only integer values
          domain={['dataMin', dataMax => Math.max(dataMax, 1)]}
          interval={0}
          // set the y-axis width to eliminate excess left padding
          // https://github.com/recharts/recharts/issues/843
          width={CHART_Y_AXIS_WIDTH} />
        <Tooltip 
          // don't highlight/fill the bar background on hover
          cursor={{fill: 'transparent'}} 
          // render custom ColorsTooltip content using `this.toManaSymbols`
          content={<ColorsTooltip renderColorsFn={this.toManaSymbols} />}
          labelFormatter={() => ''} />
        {stackedBarOrder.map((color, idx) => 
          <Bar key={`pips-bar-${idx}`}
              animationDuration={500} // default is 1500
              dataKey={color} stackId="a" fill={this.barColorOrMulti(color)}>
            <LabelList position="top"
              // calculate the label only for the top-most (last index) label
              valueAccessor={(entry) => 
                (OrderedColorsAndGroups.length-1 === idx) ? this.barTotalCount(entry.payload) : null
              }
            />
          </Bar>
        )}
      </BarChart>;
  }

  renderColorChart(deckCards) {
    // for each castable card face, calculate the chart data by color (based on `mana_cost`)
    const castableCardFaces = deckCards.flatMap(getCastableCardFaces)
      // maybe filter card faces by creature-only (i.e., for Adventures)
      .filter(face => !this.state.creaturesOnly || isCreature(face));
    const cardsByColor = groupCardsByColor(castableCardFaces, 
      cardFace => colorCombinationFromManaCost(cardFace.mana_cost));

    // filter out lands that should not appear in the chart
    if (cardsByColor.hasOwnProperty(Colorless)) {
      cardsByColor[Colorless] = cardsByColor[Colorless].filter(card => !isLand(card));
    }

    // the bar chart colors
    const wubrgc = WUBRG.concat(Colorless);
    const allCardColors = Object.keys(cardsByColor);

    // compile the chart data for each bar chart color
    const colorChartData = wubrgc.map(color => 
      // count each multicolor combination that includes the current color (string) in its attribute
      allCardColors.reduce((data, c) => {
          if (c.includes(color)) data[c] = cardsByColor[c].length;
          return data;
        }, 
        // initialize the chart data with an entry for each color
        /* initialValue = */ { color: color }
      )
    );

    // order the stacked bars to match the card column sorting
    const stackedBarOrder = OrderedColorsAndGroups.slice().reverse();
    return <BarChart
        width={this.chartWidth(wubrgc.length)}
        height={CHART_HEIGHT}
        data={colorChartData}
        barSize={CHART_BAR_WIDTH}
      >
        <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
        <XAxis 
          dataKey="color" 
          interval={0} // if set to 0, all the ticks will be shown
          tick={this.renderAxisTickAsManaCost} />
        <YAxis 
          allowDecimals={false}  // only integer values
          domain={['dataMin', dataMax => Math.max(dataMax, 1)]}
          interval={0}
          // set the y-axis width to eliminate excess left padding
          // https://github.com/recharts/recharts/issues/843
          width={CHART_Y_AXIS_WIDTH} />
        <Tooltip 
          // don't highlight/fill the bar background on hover
          cursor={{fill: 'transparent'}} 
          // render custom ColorsTooltip content using color indicators
          content={<ColorsTooltip renderColorsFn={this.toColorIndicator} />}
          labelFormatter={t => ""} />
        {stackedBarOrder.map((color, idx) => 
          <Bar key={`pips-bar-${idx}`}
              animationDuration={500} // default is 1500
              dataKey={color} stackId="a" fill={this.barColorOrMulti(color)}>
            <LabelList position="top"
              // calculate the label only for the top-most (last index) label
              valueAccessor={(entry) => 
                (OrderedColorsAndGroups.length-1 === idx) ? this.barTotalCount(entry.payload) : null
              }
            />
          </Bar>
        )}
      </BarChart>;
  }

  renderManaValueChart(deckCards) {
    // for each castable card face, calculate the chart data by mana value
    const castableCardFaces = deckCards.flatMap(getCastableCardFaces)
      // maybe filter card faces by creature-only (i.e., for Adventures)
      .filter(face => !this.state.creaturesOnly || isCreature(face));
    const chartDataByManaValue = castableCardFaces.reduce((map, cardFace) => {
        const cmc = cmcFromManaCost(cardFace.mana_cost);
        // determine the card color combination
        const colors = colorCombinationFromManaCost(cardFace.mana_cost)
        // 
        const chartData = map.hasOwnProperty(cmc) ? map[cmc] : { cmc: `{${cmc}}` };
        chartData[colors] = chartData.hasOwnProperty(colors) ? chartData[colors] + 1 : 1;
        map[cmc] = chartData;
        return map;
      }, 
      /* initialValue = */ {}
    );

    // determine the min and max mana values
    const manaValues = Object.keys(chartDataByManaValue).map(Number);
    const uniqueCmcs = new Set(manaValues);
    // round up partial mana values (i.e., 0.5)
    const wholeManaValues = manaValues.map(cmc => Math.ceil(cmc));
    // always show at least a lower bound of 2 CMC
    var minCmc = Math.min(2, ...wholeManaValues);
    // what upper bound for continuous x-axis?
    // At least up to 6 CMC but no larger than 20 (i.e., Gleemax @ 1,000,000)
    var maxCmc = Math.max(6, ...wholeManaValues.filter(mv => mv <= 20));
    // add values for continuous range between the calculated min and max value
    for(let cmc = minCmc; cmc <= maxCmc; cmc += 1) {
      uniqueCmcs.add(cmc);
    }
    // explicitly sort CMCs numerically (default sort is alphabetical)
    const sortedCmcs = [...uniqueCmcs].sort((a, b) => (a - b))
    const chartData = sortedCmcs.map(cmc => {
      if (chartDataByManaValue.hasOwnProperty(cmc)) {
        return chartDataByManaValue[cmc];
      }
      // create a placeholder with no color data
      else return { cmc : `{${cmc}}` }
    });

    // order the stacked bars to match the card column sorting
    const stackedBarOrder = OrderedColorsAndGroups.slice().reverse();
    return <BarChart
        data={chartData}
        width={this.chartWidth(chartData.length)}
        height={CHART_HEIGHT}
        barSize={CHART_BAR_WIDTH}
    >
      <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
      <XAxis 
        dataKey="cmc" 
        interval={0} // if set to 0, all the ticks will be shown
        tick={this.renderAxisTickAsManaCost} />
      <YAxis yAxisId="left" 
        allowDecimals={false}  // only integer values
        domain={['dataMin', dataMax => Math.max(dataMax, 1)]}
        interval={0}
        // set the y-axis width to eliminate excess left padding
        // https://github.com/recharts/recharts/issues/843
        width={CHART_Y_AXIS_WIDTH} />
      <Tooltip 
        // don't highlight/fill the bar background on hover
        cursor={{fill: 'transparent'}} 
        // render custom ColorsTooltip content using color indicators
        content={<ColorsTooltip renderColorsFn={this.toColorIndicator} />}
        labelFormatter={_ => ""} />
      {stackedBarOrder.map((color, idx) => 
          <Bar key={`cmc-bar-${idx}`}
              animationDuration={500} // default is 1500
              dataKey={color} stackId="a" yAxisId="left" fill={this.barColorOrMulti(color)}>
            <LabelList position="top"
              // calculate the label only for the top-most (last index) label
              valueAccessor={(entry, a, b) => {
                return (OrderedColorsAndGroups.length-1 === idx) ? this.barTotalCount(entry.payload) : null
                }
              }
            />
          </Bar>
        )}
    </BarChart>;
  }

  renderManaValuePipsChart(deckCards) {
    // for each castable card face, calculate the chart data by mana value
    const castableCardFaces = deckCards.flatMap(getCastableCardFaces)
      // maybe filter card faces by creature-only (i.e., for Adventures)
      .filter(face => !this.state.creaturesOnly || isCreature(face));

    const chartDataByManaValue = castableCardFaces.reduce((map, cardFace) => {
        const cmc = cmcFromManaCost(cardFace.mana_cost);
        const chartData = map.hasOwnProperty(cmc) ? map[cmc] : { cmc: `{${cmc}}` };
        // update the chartData with colored pip counts for this card face
        map[cmc] = this.addPipCountToMap(cardFace, chartData);
        return map;
      }, 
      /* initialValue = */ {}
    );

    //TODO Refactor? Below this point is duplicated in `renderPipsChart`
    // determine the min and max mana values
    const manaValues = Object.keys(chartDataByManaValue).map(Number);
    const uniqueCmcs = new Set(manaValues);
    // round up partial mana values (i.e., 0.5)
    const wholeManaValues = manaValues.map(cmc => Math.ceil(cmc));
    // always show at least a lower bound of 2 CMC
    var minCmc = Math.min(2, ...wholeManaValues);
    // what upper bound for continuous x-axis?
    // At least up to 6 CMC but no larger than 20 (i.e., Gleemax @ 1,000,000)
    var maxCmc = Math.max(6, ...wholeManaValues.filter(mv => mv <= 20));
    // add values for continuous range between the calculated min and max value
    for(let cmc = minCmc; cmc <= maxCmc; cmc += 1) {
      uniqueCmcs.add(cmc);
    }
    // explicitly sort CMCs numerically (default sort is alphabetical)
    const sortedCmcs = [...uniqueCmcs].sort((a, b) => (a - b))
    const chartData = sortedCmcs.map(cmc => {
      if (chartDataByManaValue.hasOwnProperty(cmc)) {
        return chartDataByManaValue[cmc];
      }
      // create a placeholder with no color data
      else return { cmc : `{${cmc}}` }
    });

    // order the stacked bars to match the card column sorting
    const stackedBarOrder = OrderedColorsAndGroups.slice().reverse();
    return <BarChart
        data={chartData}
        width={this.chartWidth(chartData.length)}
        height={CHART_HEIGHT}
        barSize={CHART_BAR_WIDTH}
    >
      <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
      <XAxis 
        dataKey="cmc" 
        interval={0} // if set to 0, all the ticks will be shown
        tick={this.renderAxisTickAsManaCost} />
      <YAxis yAxisId="left" 
        allowDecimals={false}  // only integer values
        domain={['dataMin', dataMax => Math.max(dataMax, 1)]}
        interval={0}
        // set the y-axis width to eliminate excess left padding
        // https://github.com/recharts/recharts/issues/843
        width={CHART_Y_AXIS_WIDTH} />
      <Tooltip 
        // don't highlight/fill the bar background on hover
        cursor={{fill: 'transparent'}} 
        // render custom ColorsTooltip content using color indicators
        content={<ColorsTooltip renderColorsFn={this.toManaSymbols} />}
        labelFormatter={_ => ""} />
      {stackedBarOrder.map((color, idx) => 
          <Bar key={`cmc-bar-${idx}`}
              animationDuration={500} // default is 1500
              dataKey={color} stackId="a" yAxisId="left" fill={this.barColorOrMulti(color)}>
            <LabelList position="top"
              // calculate the label only for the top-most (last index) label
              valueAccessor={(entry, a, b) => {
                return (OrderedColorsAndGroups.length-1 === idx) ? this.barTotalCount(entry.payload) : null
                }
              }
            />
          </Bar>
        )}
    </BarChart>;
  }

  /**
   * Add the colored pip count from the `mana_cost` of the given `cardFace` to the 
   * provided `pipCountMap` with keys corresponding to the pip color from 
   * {@link OrderedColorsAndGroups}.
   * 
   * This method changes the provided `pipCountMap`. 
   * 
   * @param {Object} cardFace 
   * @param {Object} pipCountMap 
   * @returns the updated `pipCountMap`
   */
  addPipCountToMap(cardFace, pipCountMap) {
    // get colored pips for this card face
    const pipsByCardFace = getCardFacePips(cardFace);
    // pips for each card will be a three-level nested array: 1. card face, 2. pips, 3 hybrid;
    // flatMap the face, further mapping the pip on each face to its joined form (for hybrid)
    const allPips = pipsByCardFace.flatMap(face => face.map(pips =>  pips.join('')));
    // increase the pip color count
    allPips.forEach(pip => {
      // get pip color, dropping any half-pip 'H' prefix (i.e., {HW} for 'Little Girl')
      const color = pip.startsWith('H') ? pip[1] : toColorCombination(pip);
      if (!pipCountMap.hasOwnProperty(color)) pipCountMap[color] = 0;
      pipCountMap[color] = pipCountMap[color] + (pip.startsWith('H') ? 0.5 : 1);
    });
    return pipCountMap;
  }

  /**
   * Determine the bar chart color from the defined `ChartColors`
   * 
   * @param {*} color 
   * @returns 
   */
  barColorOrMulti = (color) => {
    if (ChartColors.hasOwnProperty(color)) {
      return ChartColors[color].htmlColor
    }
    // if no match, return the multicolor color
    return ChartColors[Multicolor].htmlColor
  }
  
  /**
   * Calculate the total count for a stacked bar chart
   * 
   * @param {*} payload 
   * @returns 
   */
  barTotalCount = (payload) => {
    return Object.keys(payload).reduce(
      // sum the payload values only if they are numbers
      // (skip the dataKey values, e.g., 'cmc', 'color')
      (total, key) => isNaN(payload[key]) ? total : total + payload[key],
      /* initialValue = */ 0);
  }

  /**
   * Calculate total chart width.
   * 
   * @param {Number} columns the number of columns
   * @returns The total width of the chart, considering y-axis, bar columns, and column spacing.
   */
  chartWidth = (columns) => 40 + (columns * 36);

  /**
   * Split on {} mana cost separators
   */
  splitManaCost = (manaCost) => manaCost.split(/[{}]+/);

  /**
   * Calculate the label height for the given number of mana symbols, 
   * when rotated by CHART_LABEL_ROTATE_DEGREES.
   */
  labelHeight = (manaSymbolCount) => {
    const hypotenuse = MANA_SYMBOL_WIDTH * (manaSymbolCount - 0.5);
    const radians = Math.PI / 180 * Math.abs(CHART_LABEL_ROTATE_DEGREES);
    // this calculates the height relative to the mid-height of the mana symbol (rotate origin)
    const rotatedHeight = (Math.sin(radians) * hypotenuse);
    // must also includ a portion of the height that represents the rotated bottom-half of the symbols
    const remainder = Math.cos(radians) * (MANA_SYMBOL_HEIGHT / 2);
    return (MANA_SYMBOL_HEIGHT/2) + rotatedHeight + remainder;
  }

  /**
   * Recharts Tick rendering function, converting the `payload.value` (which is expected to 
   * contain mana cost symbol(s)) into the corresponding mana symbol icon(s).
   * 
   * @param {*} param0 
   * @returns 
   */
  renderAxisTickAsManaCost = ({ x, y, payload }) => {
    const manaSymbols = this.toManaSymbols(payload.value);

    // special handling for large CMC values
    let manaSymbolWidth = manaSymbols.length;
    if (payload.value === "{100}") {
      // 100 CMC ratio: 36.4833 / 19.7667
      manaSymbolWidth = (36.4833 / 19.7667);
    }
    else if (payload.value === "{1000000}") {
    // 1,000,000 CMC ratio: 82.0833 / 19.7667
    manaSymbolWidth = (82.0833 / 19.7667);

    }

    // Calculate variables for label height and positioning that may
    // change when rotating the label (for long labels)
    var height = MANA_SYMBOL_HEIGHT;
    // adjustment for the x-position relative to the tick mark
    // this will center the mana cost
    var xAdjust = MANA_SYMBOL_WIDTH * (manaSymbolWidth / 2);
    // calculate the transform-origin x-position to the center of the right-most mana symbol
    const xOrigin = MANA_SYMBOL_WIDTH * (manaSymbolWidth - 0.5);

    // if the mana cost is more than one symbol, rotate the label
    var angle = 0;
    if (manaSymbolWidth > 1) {
      // this will center the tick mark at the rotation origin (right-most mana symbol)
      xAdjust = xOrigin;
      angle = CHART_LABEL_ROTATE_DEGREES;
      height = this.labelHeight(manaSymbolWidth);
    }

    return <g>
      <foreignObject
          x={x - xAdjust} 
          y={y} 
          width={MANA_SYMBOL_WIDTH * manaSymbolWidth}
          height={height} >
        <div style={{ 
            transformOrigin: `${xOrigin}px ${MANA_SYMBOL_HEIGHT / 2}px`,
            transform: `rotate(${angle}deg)`
            }}>
          {manaSymbols}
        </div>
      </foreignObject></g>;
  };
  
  toManaSymbols = (manaCost) => {
    // map mana costs to Mana symbols (https://mana.andrewgioia.com)
    return this.splitManaCost(manaCost).filter(c => c.length).map((c, idx) => {
      // remove slashes from hybrid mana costs and convert to lower case
      let symbol = c.replace('/', '').toUpperCase();
      // special handling for 1/2 mana value
      if (c === "0.5") symbol = "1-2";
      // maybe translate the colors to the color combination for the Mana Font style name
      const style = ColorCombinationMap.hasOwnProperty(symbol) ? 
        ColorCombinationMap[symbol] : symbol;
      return <i key={idx} className={`ms ms-${style.toLowerCase()} ms-cost`}></i> ;
    });
  }

  toColorIndicator = (colors) => 
    <i className={`ms ms-ci ms-ci-${colors.length} ms-ci-${colors}`}></i>;

}

/**
 * Custom colors tooltip with `renderColorsFn` parameter to render the colored pip/indicator.
 */
export const ColorsTooltip = ({ active, payload, label, renderColorsFn }) => {
  // filter out zero values (no tooltip)
  const bars = payload?.filter(entry => entry?.value > 0);

  if (active && bars && bars.length) {
    return <>
      <div className="custom-tooltip">
        {bars.map((payload, idx) => {
          const colors = payload.dataKey.toLowerCase();
          return <span className="tooltip-item" key={idx}>
            {renderColorsFn(colors)}
            <div className="tooltip-count">{payload.value}</div>
          </span>
        })}
      </div>
    </>;
  }

  // otherwise return `null` to render nothing
  return null;
};
