import './ButtonLink.css'

/**
 * @returns an anchor link styled as a button
 */
export default function ButtonLink({ className, href, target, rel, title, disabled, children }) {
  if (disabled) {
    return (
      <span 
          className={`button-link disabled ${className ?? ''}`} 
          title={title}>
        {children}
      </span>);
  }
  return (
    <a className={`button-link ${className ?? ''}`}
        title={title} 
        href={href}
        rel={rel} 
        target={target}>
      {children}
    </a>);
}