import React from 'react';
import NumericInput from 'react-numeric-input';

import DeckCharts from './DeckCharts';

import { getColorElement } from '../helpers/ui.helpers';
import { countStandardBasicLands, countLandSources } from '../helpers/card-pool.helpers';
import { BasicLandColors, BasicLandTypes } from '../constants'

export default class DeckAnalysis extends React.Component {
  
  render() {
    return (
      <React.Fragment>
        <div className="deck-analysis-header">
          <span className="deck-analysis-title">Mana Sources</span>
          {this.renderDeckAnalysisControls()}
        </div>
        <div className="deck-analysis-scrollable">
          <div className="deck-analysis-body">
            {this.renderSources(this.props.deckCards)}
            <DeckCharts deckCards={this.props.deckCards}></DeckCharts>
          </div>
        </div>
      </React.Fragment>
    );
  }
  
  renderDeckAnalysisControls() {
    return (
      <span className="deck-analysis-controls">
        <button disabled={this.props.disabled} onClick={this.props.onShowSideboard}>Show Sideboard</button>
      </span>
    );
  }

  renderSources(deckCards) {
    const basicLandCounts = countStandardBasicLands(deckCards);
    const landSourceCounts = countLandSources(deckCards);

    const sources = BasicLandTypes.map(basicLand => {
      const color = BasicLandColors[basicLand];
      return <div key={color} className={`mana-source mana-source-${color.toLowerCase()}`}>
        {this.renderBasicLandControl(deckCards, color, basicLand, basicLandCounts[basicLand])}
        <hr/>
        {this.renderLandCount(color, landSourceCounts[color])}
      </div>;
    });

    // only show colorless land sources if non-zero count
    const colorlessLandSources = landSourceCounts['C'] > 0 ?
      <div key={'C'} className={`mana-source mana-source-c`}>
        <div key={`basic-land-widget-c`} className="add-basic-land-widget">
          {getColorElement('C', 'colorless')}
        </div>
        {this.renderLandCount('C', landSourceCounts['C'])}
      </div> : <></>;

    return <div className="mana-sources">
      <div className="mana-sources-title">
        Basic Lands
      </div>
      <div className="mana-sources-content">
        {sources}
      </div>
      {colorlessLandSources}
    </div>;
  }

  renderBasicLandControl(deckCards, symbol, name, count = 0) {
    return <div key={`basic-land-widget-${symbol}`} className="add-basic-land-widget">
      { getColorElement(symbol, name) }
      <NumericInput mobile value={count}
        //NOTE: no max specified because onChange does not trigger when auto-correcting to max value
        min={0} //max={99}
        // auto-select text on focus
        onFocus={ (e) => { if (e.target.select) e.target.select(); } }
        onChange={ (valueAsNumber) => this.handleLandChange(deckCards, name, valueAsNumber - count) }
      />
    </div>;
  }

  renderLandCount(symbol, count) {
    return <div className={`mana-source-land mana-source-land-${symbol.toLowerCase()}`}>
      {count} Land {count === 1 ? 'Source' : 'Sources'}
    </div>;
  }

  handleLandChange = (deckCards, name, diff) => {
    if (diff > 0) {
      this.props.addCards(name, diff);
    }
    else if (diff < 0) {
      this.props.removeCards(name, -1 * diff);
    }
  }

}
