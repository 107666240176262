import CardPoolScryfallData from './card-pool-scryfall-data';
import DiffState from './diff-state';

import { 
  arenaCardName, 
  isStandardBasicLand, 
  standardizeCardName }   from '../helpers/card.helpers';
import { 
  cardsToColumnsByCmc, 
  cardsToColumnsByColorIdentity } from '../helpers/card-pool.helpers';

import { OrderedTiers } from '../constants';

/**
 * Import uuid library instead of using `crypto.randomUUID()` because
 * `crypto.randomUUID()` is not available outside of the browser 
 * (i.e. for API functions).
 */
import { v4 as uuidv4 } from 'uuid';

/**
 * Card metadata property whitelist.
 * 
 * Exclude `diffOrigin` and `scryfall_id` from exported card metadata.
 * 
 * Also exclude the `cardId` itself which is already stored as the key 
 * in the exported `cards` map and can be loaded dynamically when restoring 
 * a CardPool from json (this is handled in the constructor).
 */
const METADATA_EXPORT_WHITELIST_SET = new Set([
  // 'cardId',
  'name',
  'set',
  'transformed',
  // 'scryfall_id',
  // 'diffOrigin'
]);

export default class CardPool {

  /**
   * @returns a JSON state representation of an empty card pool. Similar to `CardPool.toJsonState()`
   */
  static emptyState() {
    return {
      // no `cards` or `scryfallCards` which should not be used externally
      sideboard: { columns: [] },
      deck: { columns: [] }
    };
  }

  /**
   * Create a new CardPool object, optionally initialized with the provided JSON state
   * (as if the state were generated from `CardPool.export`):
   * ```
     {
       sideboard: { columns: [ { cardIds: [] }, ... ] }, 
       hidden: { columns: [ { cardIds: [] }, ... ] }, 
       deck: { columns: [ { cardIds: [] }, ... ] },
       cards: { "xxx-xx-xx": { cardId: "xxx-xx-xx", name: "", scryfall_id: "yyyy" }, ... },
       scryfallCards: { "yyyy": { ... } }
     }
     ```
   * 
   * @param {Object} json the JSON card pool state (optional)
   */
  constructor(json = {}) {
    // sanitize provided card pool column data
    this.sideboard = this.sanitizeColumns(json.sideboard);
    this.hidden    = this.sanitizeColumns(json.hidden);
    this.deck      = this.sanitizeColumns(json.deck);

    // scryfallCards to contain/manage all Scryfall card data
    this.scryfallCards = new CardPoolScryfallData(json.scryfallCards);

    // initialize cardsById, dropping any IDs not in the deck or sideboard
    const cardIdsInUse = new Set([
      ...this.sideboardCardIds(),
      ...this.hiddenCardIds(),
      ...this.deckCardIds()
    ]);
    const cards = json.cards ? json.cards : {}
    const poolCardsById = Object.keys(cards)
      .filter(key => cardIdsInUse.has(key))
      .reduce((obj, key) => {
        return {
          ...obj,
          // inject the `cardId` which may not be included in the json;
          // see `METADATA_EXPORT_WHITELIST_SET`
          [key]: { ...cards[key], cardId: key }
        };
      }, {});
    // intitialize cardMetadataById to only contain filtered card metadata
    this.cardMetadataById = this.filterCardMetadata(poolCardsById);

    this.trimEmptyColumns();
  }

  /**
   * Filter the given cards to only those card metadata properties that should be restored 
   * from persisted state.
   * 
   * @param {*} cardsById 
   * @returns 
   */
  filterCardMetadata(cardsById) {
    const cardMetadataById = Object.values(cardsById).reduce((map, card) => {
      // capture only the known/wanted metadata fields
      const metadata = {
        // cardId and name are required to re-fetch data
        cardId: card.cardId,
        name: card.name
      }
      // if provided, maintain the card set
      if (card.hasOwnProperty("set")) metadata.set = card.set;
      // if provided, maintain the ID to external Scryfall data
      if (card.hasOwnProperty("scryfall_id")) metadata.scryfall_id = card.scryfall_id;
      // if provided, maintain the transformed state which is relevant for proper display
      if (card.hasOwnProperty("transformed")) metadata.transformed = card.transformed;
      // if provided, maintain the card's diffOrigin DiffState
      if (card.hasOwnProperty("diffOrigin")) metadata.diffOrigin = card.diffOrigin;
      // freeze the object and store it in the map
      map[card.cardId] = Object.freeze(metadata);
      return map;
    }, /* initialValue = */ {});
    return cardMetadataById;
  }

  /**
   * Filter the given card metadata properties to only include those in 
   * the `METADATA_EXPORT_WHITELIST_SET`.
   * 
   * @param {Object} metadata The metadata object for a single card.
   * @returns the sanitized card metadata
   */
  sanitizeMetadata = (metadata) => {
    return Object.keys(metadata)
      // only include metadata properties from the export whitelist
      .filter(key => METADATA_EXPORT_WHITELIST_SET.has(key))
      .reduce(
        (obj, key) => ({ ...obj, [key]: metadata[key] }),
        /* initialValue = */ {});
  }

  /**
   * @returns a JSON state representation of this card pool: 
   * ```
     {
       sideboard: { columns: [ { cardIds: [] } ] }, 
       hidden: { columns: [ { cardIds: [] } ] }, 
       deck: { columns: [ { cardIds: [] } ] }
     }
     ```
   */
  toJsonState() {
    return {
      // no `cards` or `scryfallCards` which should not be used externally
      sideboard: this.sideboard,
      hidden: this.hidden,
      deck: this.deck
    };
  }

  /**
   * @returns a new {@link CardPool} that is a clone of the current instance.
   */
  clone() {
    return new CardPool(this.export(/* includeAllData = */ true));
  }
  
  /**
   * @param {Boolean} includeAllData `true` to include all card data in the export.  
   *    This includes `cards` and Scryfall card data in the the `scryfallCards` property.
   * @returns a JSON representation of this card pool for exporting/serializing:
   * ```
     {
       sideboard: { columns: [ { cardIds: [] }, ... ] }, 
       hidden: { columns: [ { cardIds: [] }, ... ] }, 
       deck: { columns: [ { cardIds: [] }, ... ] },
       cards: { "xxx-xx-xx": { cardId: "xxx-xx-xx", name: "", scryfall_id: "yyyy" }, ... },
       scryfallCards: { "yyyy": { ... }, ... }
     }
     ```
   */
  export(includeAllData = false) {
    const json = this.toJsonState();
    if (includeAllData) {
      // explicitly add card metadata
      json.cards = this.cardMetadataById;
      // exporting scryfallCards data is optional
      json.scryfallCards = this.scryfallCards.allCards();
    }
    else {
      // sanitize exported card metadata when `!includeAllData`
      json.cards = Object.keys(this.cardMetadataById).reduce(
        (map, cardId) => ({ ...map, [cardId]: this.sanitizeMetadata(this.cardMetadataById[cardId])}),
        /* initialValue = */ {});
    }
    return json;
  }

  /**
   * @returns a JSON representation of this card pool's card counts:
   * ```
     {
       sideboard: [ { name: "...", count: 1, set: "xxx" }, ... ]
       hidden: [ { name: "...", count: 1, set: "xxx" }, ... ]
       deck: [ { name: "...", count: 1, set: "xxx" }, ... ]
     }
   * ```
   */
   exportCardCounts(includeSet = false, includeCollectorNo = false, combineHiddenSideboard = false) {
    const deckMetadata = this.deckCardIds().map(id => this.cardMetadataById[id]);
    // combine hidden cards into sideboard
    const sideboardMetadata = this.sideboardCardIds().map(id => this.cardMetadataById[id]);
    const hiddenMetadata = this.hiddenCardIds().map(id => this.cardMetadataById[id]);

    // exit early if pool is empty
    if (deckMetadata.length + sideboardMetadata.length + hiddenMetadata.length === 0) {
      return {};
    }

    // define a helper function to condense/calculate individual card counts
    const cardCounts = (cardMetadata) => {
      // reduce the metadata to a mapping by distinct card name (and, maybe, set)
      const metadataCounts = cardMetadata.reduce((map, metadata) => {
        // get the full card to extract the Arena card name
        const card = this.card(metadata.cardId)
        // index the card JSON by name or name+set
        const key = (includeSet && card.set) ? card.name + "+" + card.set : card.name;
        // if not yet defined, initialize the JSON for this key
        if (!map.hasOwnProperty(key)) {
          const json = { name: arenaCardName(card), count: 0 };
          // always export set data as lower case (match Scryfall behavior)
          if (includeSet && card.set) json.set = card.set.toLowerCase();
          if (includeCollectorNo && card.collector_number) json.collector_number = card.collector_number;
          map[key] = json;
        }
        // increment the count for this card and update the map
        const json = map[key];
        json.count = json.count + 1;
        map[key] = json;
        return map;
      }, /* initialValue = */ {});

      // discard the keys and return the JSON including card counts
      return Object.values(metadataCounts);
    }

    const deckJson = cardCounts(deckMetadata);
    let sideboardJson = cardCounts(sideboardMetadata);
    let hiddenJson = undefined;
    // either re-count the sideboard with the hidden cards or export hidden cards separately
    if (combineHiddenSideboard) {
      sideboardJson = cardCounts(sideboardMetadata.concat(hiddenMetadata));
    }
    else {
      hiddenJson = cardCounts(hiddenMetadata);
    }

    return { sideboard: sideboardJson, hidden: hiddenJson, deck: deckJson };
  }

  /**
   * Sort the sideboard cards by color and the deck by CMC.
   * 
   * @returns this card pool
   */
  defaultSort() {
    // sort sideboard by color identity
    this.sortSideboardBy(cardsToColumnsByColorIdentity);
    // sort deck by CMC
    this.sortDeckBy(cardsToColumnsByCmc);
    return this;
  }

  /**
   * Recreate the sideboard with columns (and split columns) sorted according 
   * to the provided sort function.
   * 
   * @param {SortFn} sortFn the pool columns sort function
   */
  sortSideboardBy(sortFn) {
    const columnCards = this.columnGroupCardIds(this.sideboard.columns).map(this.card);
    // sideboard doesn't have splitColumns
    this.sideboard = sortFn(columnCards, /* splitCards = */ undefined);
  }
  
  /**
   * Recreate the deck with columns (and split columns) sorted according 
   * to the provided sort function.
   * 
   * @param {SortFn} sortFn the pool columns sort function
   */
  sortDeckBy(sortFn) {
    const columnCards = this.columnGroupCardIds(this.deck.columns).map(this.card);

    let splitColumnCards = undefined;
    if (this.deck.splitColumns) {
      splitColumnCards = this.columnGroupCardIds(this.deck.splitColumns).map(this.card);
    }

    this.deck = sortFn(columnCards, splitColumnCards);
  }

  /**
   * Move the card with the specified `cardId` to the destination card/column/group/pool location.
   * 
   * @param {String} cardId 
   * @param {String} destPoolName 
   * @param {String} destColumnGroup 
   * @param {Number} destColumnIdx 
   * @param {Number} destCardIdx 
   */
  moveCard(cardId, destPoolName, destColumnGroup, destColumnIdx, destCardIdx) {
    // find the card's source pool/column
    const source = this.findCardLocation(cardId);

    // if there is a source, identify the source column
    let sourceColumn;
    if (source) {
      const sourceColumns = this[source.poolName][source.columnGroup];
      sourceColumn = sourceColumns[source.columnIdx];
    }
    // track the source card index before making changes
    let sourceCardIdx = sourceColumn?.cardIds.indexOf(cardId);

    // check if the destination column index exists in the destination pool
    const destColumns = this[destPoolName][destColumnGroup];
    const destColumnsCount = destColumns.length;
    if (destColumnIdx >= destColumnsCount) {
      // add missing columns if destColumnIdx does not exist
      const missing = destColumnIdx - destColumnsCount + 1;
      console.debug("Adding", missing, "column(s) to", destPoolName, "-", destColumnGroup);
      const newColumns = new Array(missing).fill().map(a => ({ cardIds: [] }));
      destColumns.push(...newColumns);
    }

    // insert card to destination column at the correct position
    // (this happens before removing the source card from the source column)
    const destColumn = destColumns[destColumnIdx];
    let destColumnTargetIdx = destCardIdx;
    if (typeof(destCardIdx) !== "number") {
      // if destCardIdx was not specified, set to end of destColumn
      destColumnTargetIdx = destColumn.cardIds.length;
    }
    // safety-adjust target card index between 0 and end-of-column
    destColumnTargetIdx = Math.max(0, Math.min(destColumnTargetIdx, destColumn.cardIds.length));
    const newDestCardIds = [...destColumn.cardIds];
    newDestCardIds.splice(destColumnTargetIdx, 0, cardId);
    destColumns[destColumnIdx].cardIds = newDestCardIds;

    // also remove `cardId` from source column (being moved from)
    if (sourceColumn === destColumn) {
      console.debug(cardId, "[", this.card(cardId).name, "] was moved within the same column");
      // adjust the index if inserting the destination card has changed the position
      if (sourceCardIdx && sourceCardIdx >= destCardIdx) {
          sourceCardIdx += 1;
      }
    }
    else {
      console.debug(cardId, "[", this.card(cardId).name, "] was moved to a new column");
    }
    // if there is a source, update the source column to remove the source card
    if (sourceColumn && sourceCardIdx >= 0) {
      const newSourceCardIds = [...sourceColumn.cardIds];
      newSourceCardIds.splice(sourceCardIdx, 1);
      sourceColumn.cardIds = newSourceCardIds;
    }

    // finally, equalize/trim any extra blank columns 
    this.equalizeDeckColumns();
    this.trimEmptyColumns();
  }

  allCards() { return Object.keys(this.cardMetadataById).map(this.card); }

  allCardMetadata() { return Object.values(this.cardMetadataById); }

  /**
   * @param {Object} column the column containing the card IDs
   * @returns {Object[]} an array of the cards in the given column.
   */
  columnCards = (column) => column.cardIds.map(this.card);

  /**
   * @param {string} cardId the identifier of the card to retrieve
   * @returns {Object} a copy of the card object
   */
  card = (cardId) => {
    const metadata = this.cardMetadataById[cardId];
    const scryfall = this.scryfallCards.card(metadata.scryfall_id);
    // combine metadata with scryfall data
    return Object.freeze({ ...metadata, ...scryfall });
  }
 
  sideboardCardIds() {
    return this.poolCardIds(this.sideboard);
  }

  hiddenCardIds() {
    return this.poolCardIds(this.hidden);
  }

  deckCardIds() {
    return this.poolCardIds(this.deck);
  }

  poolCardIds(pool) {
    const columnCardIds = this.columnGroupCardIds(pool.columns);
    const splitColumnCardIds = this.columnGroupCardIds(pool.splitColumns);
    return columnCardIds.concat(splitColumnCardIds);
  }

  /**
   * @param {Object[]} columns the pool columns group from which to retrieve card IDs
   * @returns {String[]} an array of card IDs if the column group is defined, 
   *  otherwise an empty array
   */
  columnGroupCardIds(columns) {
    return columns ? columns.flatMap(col => col.cardIds) : [];
  }

  missingCardNames(cards = undefined) {
    const partition = this.partitionMissingCards(cards);
    return partition[0].map(metadata => metadata.name);
  }

  partitionMissingCards = (cards = undefined) => {
    const cardsToCheck = cards ? cards : this.allCardMetadata();
    const missingScryfallCards = (card) => !this.hasScryfallData(card);
    return this.partition(cardsToCheck, missingScryfallCards);
  }

  /**
   * @param {Object} metadata the card metadata object to check for Scryfall card data
   * @returns `true` if the card metadata has a Scryfall ID and the corresponding Scryfall data exists
   */
  hasScryfallData = (metadata) => {
    // check for the metadata scryfall_id and the actual Scryfall card data
    return metadata.scryfall_id && this.scryfallCards.card(metadata.scryfall_id);
  }

  /**
   * Fetch all cards in the pool one time (no additional queries for the 'best' version).
   * 
   * @returns {Promise} a Promise with the updated CardPool
   */
  async fetchAllCardsOnce() {
    const allCards = this.allCardMetadata();
    return this.fetchCardsOnce(allCards);
  }

  /**
   * Fetch the specified cards one time (no additional queries for the 'best' version).
   * 
   * @param {Object[]} cardsToFetch the cards with a `name` (and optional `set`) property 
   * to use to fetch the corresponding card data.
   * @returns {Promise} a Promise with the updated CardPool
   */
  async fetchCardsOnce(cardsToFetch) {
    // fetch Scryfall card data
    return this.scryfallCards.fetchCardsOnce(cardsToFetch)
      .then(scryfallCards => {
        // map and merge the results into the card pool metadata
        const enrichedMetadata = this.mapScryfallCardsToCardMetadata(scryfallCards, cardsToFetch);
        this.updateCardMetadata(enrichedMetadata);
        return this; // return the updated CardPool
      });
  }

  /**
   * Fetch any card that is missing card data and update all cards to the 'best' version 
   * for this pool.  'Best' is determined by maximizing the number of cards that are in 
   * the set(s) with the most already-known cards (across the entire pool).
   * 
   * @returns {Promise} a Promise with the updated CardPool
   */
  async fetchMissingBestCards() {
    const [missingCards /*, cardsWithData */] = this.partitionMissingCards();
    //TODO if `missingCards` include a `scryfall_id` the card can be looked up directly

    if (missingCards.length > 0) {
      return this.fetchCardsOnce(missingCards)
        // fetch missing cards, ignoring any sets they are "anchored" to
        .then(cardpool => cardpool.fetchMissingCardsUnanchored())
         // only fetch the missing/unanchored cards (provide only cardId)
        .then(cardpool => cardpool.fetchBestCards(missingCards.map(card => card.cardId)))
    }
    else {
      // no missing cards to fetch
      return Promise.resolve(this);
    }
  }

  /**
   * Fetch any card that is missing card data, ignoring any anchored `set` attribute.
   * 
   * @returns {Promise} a Promise with the updated CardPool
   */
  async fetchMissingCardsUnanchored() {
    const [missingCards /*, cardsWithData */] = this.partitionMissingCards();
    //TODO if `missingCards` include a `scryfall_id` the card can be looked up directly

    if (missingCards.length > 0) {
      return this.fetchCardsUnanchored(missingCards);
    }
    else {
      // no missing cards to fetch
      return Promise.resolve(this);
    }
  }

  /**
   * Fetch the specified cards and explicitly ignore any card `set` attribute.
   * 
   * @param {Object[]} cardsToFetch the cards with a `name` property to use to 
   * fetch the corresponding card data.
   * @returns {Promise} a Promise with the updated CardPool
   */
  async fetchCardsUnanchored(cardsToFetch) {
    // explicitly fix each card `set` to `undefined` to query for previously-not-found cards
    const cardsUnanchored = cardsToFetch.map(card => ({ ...card, set: undefined }));
    return this.scryfallCards.fetchCardsOnce(cardsUnanchored)
      .then(unanchoredScryfallCards => {
        // map and merge the results into the card pool metadata
        // `cardsUnanchored` cards have `set` of `undefined`, which will be transfered to `unanchored`
        const unanchored = this.mapScryfallCardsToCardMetadata(unanchoredScryfallCards, cardsUnanchored);
        this.updateCardMetadata(unanchored);

        return this; // return the updated CardPool
      });
  }

  /**
   * Fetch all cards in the pool and try to find the 'best' version of each card for this pool.
   * 'Best' is determined by maximizing the number of cards that are in the set(s) with the most 
   * already-known cards (across the entire pool).
   * 
   * @returns {Promise} a Promise with the updated CardPool
   */
  async fetchAllBestCards() {
    const allCardMetadata = this.allCardMetadata();
    
    return this.fetchCardsOnce(allCardMetadata)
      .then(cardpool => cardpool.fetchMissingCardsUnanchored())
      .then(cardpool => cardpool.fetchBestCards());
  }

  /**
   * Fetch the 'best' version of each card for this pool. 'Best' is determined by 
   * maximizing the number of cards that are in the set(s) with the most already-known 
   * cards (across the entire pool).
   * 
   * @param {String[]} optionalCardIdsToFetch Optional parameter identifying the card IDs of
   * known cards (in the card pool's `cardMetadataById`) for which to fetch the 'best' version.
   * If not specified, all cards will be fetched.
   * @returns {Promise} a Promise with the updated CardPool
   */
  async fetchBestCards(optionalCardIdsToFetch) {
    // convert provided card IDs to card metadata;
    // `refreshCardsForMajoritySets` needs the existing `scryfall_id`
    const cardMetadataToRefresh = optionalCardIdsToFetch?.map(cardId => this.cardMetadataById[cardId]);

    const allKnownCardMetadata = this.allCardMetadata();
    return this.scryfallCards.refreshCardsForMajoritySets(allKnownCardMetadata, cardMetadataToRefresh)
      .then(bestScryfallCards => {
        // map and merge the results into the card pool metadata;
        // if specified, limit the mapping to those cards being refreshed
        const enrichedMetadata = this.mapScryfallCardsToCardMetadata(bestScryfallCards, 
          /* cardMetadata = */ cardMetadataToRefresh ?? allKnownCardMetadata);
        this.updateCardMetadata(enrichedMetadata);

        return this; // return the updated CardPool
      });
  }

  /**
   * Update the metadata (e.g., transformed, tier) for the specified cards.
   * 
   * @param  {...any} cardMetadata An array of card objects containing card metadata
   *  properties identified by cardId:
   * ```
     {
       cardId: "id", 
       transformed: true, 
       tier: "A+",
       diffOrigin: DiffState.Sideboard
     }
     ```
   */
  updateCardMetadata(...cardMetadata) {
    cardMetadata.flat().forEach(card => {
      const original = this.cardMetadataById[card.cardId];
      // merge the new card metadata over the original card metadata;
      // freeze and store the merged object
      this.cardMetadataById[card.cardId] = Object.freeze({...original, ...card});
    });
  }

  /**
   * Set the transformed state for the given cardId.
   * 
   * @param {String} cardId 
   * @param {Boolean} transformed 
   */
  transform = (cardId, transformed) => 
    this.updateCardMetadata({ cardId: cardId, transformed: transformed });

  mapScryfallCardsToCardMetadata(scryfallCards, cardMetadata) {
    // search Scryfall cards, generating a mapping from card metadata `cardId`s 
    // to Scryfall card matches (by `name` and `set`)
    const scryfallMatches = this.searchCards(scryfallCards, cardMetadata);
    
    const mergedCardMetadata = cardMetadata.flatMap(metadata => {
      // are there Scryfall card match(es) for this card metadata?
      const matches = scryfallMatches[metadata.cardId];

      // card matches may not be found!
      if (!matches?.length) {
        // return the original un-mapped metadata (no `scryfall_id`)
        return metadata;
      }
      else {
        // sort the matches by `released_at` date in 'yyyy-mm-dd' format...
        const scryfallCard = matches.sort((b, a) => {
            if (b.released_at > a.released_at) return -1;
            else if (b.released_at < a.released_at) return 1;
            else return 0;
           })[0]; // ...and grab the first match

        // create new metadata linking the metadata `cardId` to the new Scryfall card ID; 
        // replace the metadata card name with the matched Scryfall card name
        const newMetadata = { cardId: metadata.cardId, name: scryfallCard.name, scryfall_id: scryfallCard.id };
        // specify the `newMetadata.set` if included in the original card `metadata`
        if (metadata.hasOwnProperty("set")) newMetadata.set = metadata.set;
        // set transformed if in `cardMetadata` or searchCards results
        if (metadata.transformed || scryfallCard.transformed) newMetadata.transformed = true;
        return newMetadata;
      }
    });

    return mergedCardMetadata;
  }

  /**
   * Find the location of the specified card ID in the pool
   * 
   * @param {string} cardId the identifier of the card to find
   * @returns {Object} an object that identifies the location of the card, or `undefined` if not found.
   * ```
     {
       poolName: "deck",
       columnGroup: "splitColumns",
       columnIdx: 4,
       cardIdx: 0
     }
     ```
   */
  findCardLocation(cardId) {
    // check sideboard columns
    const sideboardResult = this.findCardInColumnGroup(cardId, "sideboard");
    if (sideboardResult) return sideboardResult;

    // check hidden columns
    const hiddenResult = this.findCardInColumnGroup(cardId, "hidden");
    if (hiddenResult) return hiddenResult;

    // check deck columns
    const deckResult = this.findCardInColumnGroup(cardId, "deck");
    if (deckResult) return deckResult;

    // check deck splitColumns
    const deckSplitResult = this.findCardInColumnGroup(cardId, "deck", "splitColumns");
    if (deckSplitResult) return deckSplitResult;

    // not found
    console.debug("Could not find the location of", cardId);
    //return undefined;
  }

  /**
   * Find the location of the specified card ID within the identified column group.
   * 
   * @param {string} cardId the identifier of the card to find
   * @param {string} poolName the name of the pool (i.e., "sideboard" or "deck")
   * @param {string} columnGroup the name of the column group to search (i.e., "columns" or "splitColumns")
   * @returns {Object} an object that identifies the location of the card, or `undefined` if not found.
   * ```
     {
       poolName: "deck",
       columnGroup: "splitColumns",
       columnIdx: 4,
       cardIdx: 0
     }
     ```
   */
  findCardInColumnGroup(cardId, poolName, columnGroup = "columns") {
    for (let colIdx = 0; this[poolName][columnGroup] && colIdx < this[poolName][columnGroup].length; colIdx++) {
      const col = this[poolName][columnGroup][colIdx];
      const cardIdx = col.cardIds.findIndex(id => id === cardId);
      if (cardIdx >= 0) {
        return { poolName: poolName, columnGroup: columnGroup, columnIdx: colIdx, cardIdx: cardIdx };
      }
    }
    //return undefined; // if not found
  }

  /**
   * Find all cards in the given column that match the specified card name. 
   * 
   * @param {string} name the card name
   * @param {Object} column the column to search
   * @returns {string[]} an array of card IDs from the column with a matching name
   */
  findCardsInColumn(name, column) {
    //TODO check each card face (if present) for a matching name?
    return column.cardIds.filter(cardId => 
      this.card(cardId).name.toLowerCase() === name.toLowerCase());
  }

  /**
   * Search the columns of the given pool from left to right, top to bottom to
   * find up to `limit` number of cards where the provided `matchFn` returns `true`.
   * 
   * @param {Object} pool 
   * @param {*} matchFn 
   * @param {number} limit
   * @returns {string[]}
   */
  findCardIdsByColumn = (pool, matchFn, limit = Number.POSITIVE_INFINITY) => {
    const foundCardIds = [];

    const numColumns = pool.columns.length;
    // search from left to right
    for (let i = 0; (i < numColumns) && (foundCardIds.length < limit); i++) {
      // for each column index, first check pool.columns then pool.splitColumns
      const numCards = pool.columns[i].cardIds.length;
      // search from top to bottom
      for (let j = 0; (j < numCards) && (foundCardIds.length < limit); j++) {
        const cardId = pool.columns[i].cardIds[j];
        if (matchFn(cardId)) {
          console.debug("found", cardId, "from column", i, "position", j);
          foundCardIds.push(cardId)
        }
      }

      // then check pool.splitColumns for index i
      if (pool.splitColumns) {
        const numSplitCards = pool.splitColumns[i].cardIds.length;
        // search from top to bottom
        for (let j = 0; (j < numSplitCards) && (foundCardIds.length < limit); j++) {
          const cardId = pool.splitColumns[i].cardIds[j];
          if (matchFn(cardId)) {
            console.debug("found", cardId, "from (split) column", i, "position", j);
            foundCardIds.push(cardId)
          }
        }  
      }
    }

    return foundCardIds;
  }

  /**
   * Find all cards in this CardPool that match the provided card name.
   * 
   * @param {string} name
   * @param {string} set
   * @returns an array of card metadata objects that match on the given `name` and 
   *  (optional) `set` (if any are found).
   */
  findCardMetadata = (name, set) => {
    // special handling for card set DAR -> convert to DOM (Dominaria)
    let querySet = set?.toLowerCase();
    if (querySet === "dar") {
      querySet = "dom";
    }
    
    const temporaryId = uuidv4();
    const query = { cardId: temporaryId, name: name, set: querySet };
    const results = this.searchCards(this.allCardMetadata(), [ query ]);
    return results[temporaryId];
  }

  /**
   * Find all cards in `cardsToSearch` that match cards represented in `metadataQuery` by `name` 
   * and `set` (if specified), checking each card face (if present) for a matching name.
   * 
   * If a `name` matches the back `card_face` of a card in `cardsToSearch`, the result will 
   * additionally have `transformed: true`.
   * 
   * @param {Object[]} cardsToSearch 
   * @param {Object[]} metadataQuery an array of metadata query objects consisting of a 
   *  `cardId`, `name`, and optionally a `set`:
   * ```
     [{
       cardId: "id",
       name: "query name",
       set: "xxx"
     }, {...}, ... ]
     ```
   * @returns {Object} a map from each `metadataQuery.cardId` to an array of the matching card 
   *  objects from `cardsToSearch` (if any are found); each `cardId` is only included in the 
   *  resulting map if one or more matches are found.
   */
  searchCards(cardsToSearch, metadataQuery) {
    // create map of { "scryfall card name" : [card, card, ...] } for mapping
    // `cardsToSearch` names (i.e., fetched Scryfall data) to card metadata
    const searchCardsByName = cardsToSearch.reduce((map, card) => { 
      const cardName = standardizeCardName(card.name);
      // add the standard card name
      if (!map.hasOwnProperty(cardName)) map[cardName] = [];
      map[cardName].push(card);

      // also add the `name` for any `card.card_faces`
      if (card.hasOwnProperty("card_faces")) {
        card.card_faces.forEach((face, index) => {
          const faceName = standardizeCardName(face.name);
          const result = { ...card }
          // if this is the back card face, mark as `transformed`
          if (index > 0) result.transformed = true;
          if (!map.hasOwnProperty(faceName)) map[faceName] = [];
          map[faceName].push(result);
        });
      }
      return map;
    }, /* initialValue = */ {});

    // reduce all `metadataQuery` to a mapping from the `cardId` to any matches
    return metadataQuery.reduce((map, metadata) => {
      // check for matches with the name of the provided card
      const cardName = standardizeCardName(metadata.name);
      const matches = searchCardsByName.hasOwnProperty(cardName) ? searchCardsByName[cardName] : [];
      // each metadata card should have a `cardId` property; use that as the return value key
      map[metadata.cardId] = matches.filter(searchCard => {
        // only find those matches that also match on set, when specified in either card
        return !metadata.set || !searchCard.set || metadata.set.toLowerCase() === searchCard.set.toLowerCase();
      });
      return map;
    }, /* initialValue = */ {});
  }

  splitDeck(split, partitionFn) {
    if (split) {
      if (!this.deck.splitColumns) {
        this.deck.splitColumns = [];
        this.equalizeDeckColumns();
      }

      if (partitionFn) {
        const split = this.partitionColumns(this.deck, partitionFn);
        this.deck.columns = split.columns;
        this.deck.splitColumns = split.splitColumns;
      }
    }
    else {
      this.mergeSplitColumns(this.deck);
    }
  }

  mergeSplitColumns(pool) {
    const numColumns = Math.max(pool.columns.length, pool.splitColumns.length);
    const mergedColumns = [];
    for (let i = 0; i < numColumns; i++) {
      // destructure the column to drop `cardIds`
      const { cardIds: _, ...column } = pool.columns[i];

      let mergedCardIds = [];
      // merge the top column cardIds
      if (pool.columns.length > i)
        mergedCardIds = mergedCardIds.concat(pool.columns[i].cardIds);
      // and the bottom column cardIds
      if (pool.splitColumns.length > i)
        mergedCardIds = mergedCardIds.concat(pool.splitColumns[i].cardIds);
      
      // add the merged cardIds to the new column and merge
      column.cardIds = mergedCardIds;
      mergedColumns.push(column);
    }

    pool.columns = mergedColumns;
    pool.splitColumns = undefined;
  }

  /**
   * Clear all cards from the deck into the sideboard, and reset the deck to empty.  
   * Delete all lands in the deck.
   */
  clearDeck() {
    const deck = this.deck;
    // exit with no change if deck columns is empty and not split 
    if (!deck.splitColumns && !this.deckCardIds().length) {
      return;
    }
    
    // helper method to check if a card ID is a basic land
    const isCardIdBasicLand = (cardId) => isStandardBasicLand(this.card(cardId));
    // we don't want to merge basic lands back to the sideboard, so track them for removal
    const idsToRemove = [];

    // iterate over all columns
    const numDeckColumns = Math.max(
      deck.columns.length, 
      deck.splitColumns ? deck.splitColumns.length : 0);
    for (let i = 0; i < numDeckColumns; i++) {
      // merge the top column
      if (deck.columns.length > i) {
        // partition basic lands from all other cards (not just lands)
        const [basics, nonBasics] = this.partition(deck.columns[i].cardIds, isCardIdBasicLand);
        // mark all basic land card IDs for removal
        basics.forEach(cardId => idsToRemove.push(cardId));
        // move all of the non-basic lands to the sideboard columns
        nonBasics.forEach(cardId => this.moveCard(cardId, "sideboard", "columns", i));
      }
      // and repeat for the bottom/split column (if defined)
      if (deck.splitColumns && deck.splitColumns.length > i) {
        // partition basic lands from all other cards (not just lands)
        const [basics, nonBasics] = this.partition(deck.splitColumns[i].cardIds, isCardIdBasicLand);
        basics.forEach(cardId => idsToRemove.push(cardId));
        nonBasics.forEach(cardId => this.moveCard(cardId, "sideboard", "columns", i));
      }
    }

    // delete the actual cards being removed (basic lands)
    this.deleteCardIds(idsToRemove);
    // set the empty deck
    this.deck = { columns: [], /* splitColumns: undefined */ }
  }
  
  /**
   * Delete the specified card IDs from the deck columns and card pool.
   * 
   * @param {name} 
   */
  deleteCardsFromDeck(name, count) {
    const idsToRemove = [];
    // remove from deck columns
    const numColumns = this.deck.columns.length;
    for (let i = 0; (i < numColumns) && (idsToRemove.length < count); i++) {
      console.log("for i =",i,"idsToRemove:", idsToRemove);
      // for each column index, first check 'columns' then 'splitColumns'
      ["columns", "splitColumns"].forEach(columnGroup => {
        const deckColumns = this.deck[columnGroup];
        if (deckColumns) {
          const column = deckColumns[i];
          const forDeletion = this.findCardsInColumn(name, column);
    
          console.debug("remove up to", count - idsToRemove.length, "cards named", name, "from", column, ":", forDeletion);
          // reverse the card IDs so that we remove from visible bottom to top
          const filteredColumn = column.cardIds.reverse().filter(cardId => {
            // delete the card if count has not been reached
            const remove = (idsToRemove.length < count) && forDeletion.includes(cardId);
            if (remove) idsToRemove.push(cardId);
            return !remove;
          });
          // re-reverse the column's card IDs before replacing
          deckColumns[i].cardIds = filteredColumn.reverse();
        }
      });
    }

    // finally, trim any empty columns from the column group
    this.trimEmptyColumns();
    // delete card IDs from cardpool map
    this.deleteCardIds(idsToRemove);
  }

  deleteFromColumns(pool, ...cardIdsToDelete) {
    const removeFromDeck = new Set(cardIdsToDelete.flat());
    const numColumns = pool.columns.length;
    for (let i = 0; i < numColumns; i++) {
      // for each column index, first check 'columns' then 'splitColumns'
      ["columns", "splitColumns"].forEach(columnGroup => {
        const poolColumns = pool[columnGroup];
        if (poolColumns) {
          pool[columnGroup][i].cardIds = poolColumns[i].cardIds.filter(id => !removeFromDeck.has(id));
        }
      });
    }
  }

  deleteCardIds(...cardIds) {
    //TODO Delete orphan cards within `this.scryfallCards`? They will be removed when exported
    cardIds.flat().forEach(id => delete this.cardMetadataById[id]);
  }

  /**
   * Add to the card pool (sideboard and deck) with the provided card pool data.
   * 
   * @param {*} cardPoolData expected to match the following format (`set` optional):
   * ```
     cardPoolData = {
       sideboard: [ { name: "card name", count: 1, set: "xxx" }, ... ],
       deck:      [ { name: "card name", count: 1, set: "xxx" }, ... ],
      }
     ```
   * @returns {Object} an array of added card objects containing `cardId` and `name`:
     ```
   * [ { cardId: "card-id", name: "card name" }, ... ]
     ```
   */
  addCards(cardPoolData) {
    const addedSideboard = this.addPoolCards(this.sideboard, cardPoolData.sideboard);
    //TODO directly add hidden cards?
    const addedDeck = this.addPoolCards(this.deck, cardPoolData.deck);
    return addedSideboard.concat(addedDeck);
  }

  addCardsToSideboard(name, count) {
    this.addToPool(this.sideboard, name, count);
  }

  addCardsToDeck(name, count) {
    this.addToPool(this.deck, name, count);
  }

  /**
   * Add the specified cards to a new first column of the target `pool`.
   * 
   * @param {Object} pool the pool (sideboard, deck) to add cards to
   * @param {Object[]} poolData an array of objects containing the card `name`, `count`, 
   *  and optional metadata (e.g., `set`, `tier`)
   * @returns {Object} an array of objects containing `cardId` and `name`: 
   * ```
   * [ { cardId: "card-id", name: "card name" }, ... ]
   * ```
   */
  addPoolCards(pool, poolData) {
    // skip if no cards are provided
    if (poolData?.length) {
      // insert a new first column to the pool, where the new cards will be added
      pool.columns.unshift({ cardIds: [] });
      return poolData.flatMap(card => {
        // destructure the card to known params and other metadata (e.g., set, tier)
        let { name, count, ...metadata } = card;
        // default the `count` to 1 if not specified
        if (!count) count = 1;
        
        return this.addToPool(pool, card.name, count, metadata);
      });
    }
    else return [];
  }

  addToPool(pool, name, count, metadata) {
    // check for any existing card matches before creating the new cards
    const matches = this.findCardMetadata(name, metadata?.set);
    // filter to only those matches with scryfall data (i.e., a valid scryfall_id)
    const matchesWithData = matches.length ? matches.filter(this.hasScryfallData) : [];
    const knownCard = matchesWithData[0];

    // create the new card(s) and update the CardPool
    const newCardsById = this.createCards(name, count, metadata);
    return Object.keys(newCardsById).map(id => {
      // if existing card data was found, point to the same Scryfall data
      if (knownCard) {
        newCardsById[id] = { ...newCardsById[id], scryfall_id: knownCard.scryfall_id };
      }
      // set and freeze the new card metadata object
      this.cardMetadataById[id] = Object.freeze(newCardsById[id]);

      // ensure that pool column 0 exists
      if (!pool.columns) {
        pool.columns = [];
      }
      if (!pool.columns.length) {
        pool.columns = [ { cardIds: [] } ];
      }

      // add the card to pool column 0
      pool.columns[0].cardIds.push(id);
      
      // return a copy (so that changes don't change the internal data)
      return { ...newCardsById[id] };
    });
  }

  /**
   * Sanitize the column arrays of the provided pool to only include `cardIds`.
   * 
   * @param {Object} pool the pool to sanitize
   * @returns the pool with its columns filtered to only contain `cardIds`
   */
  sanitizeColumns(pool) {
    if (!pool) {
      // empty columns (no splitColumns)
      return { columns: [], /* splitColumns: undefined */ };
    }
    else {
      // sanitize columns array objects to only include the `title` and `cardIds` properties
      const sanitize = (columns) => columns ? 
        columns.map(col =>({ title: col.title, cardIds: col.cardIds })) 
        : [];

      const sanitized = { columns: sanitize(pool.columns) };
      // only sanitize `splitColumns` if it is defined
      if (pool.splitColumns) {
        sanitized.splitColumns = sanitize(pool.splitColumns);
      }
      return sanitized;
    }
  }

  /**
   * Trim the empty columns from the end of each sideboard/deck cardpool.
   */
  trimEmptyColumns() {
    this.sideboard = this.trimPoolColumns(this.sideboard);
    this.hidden    = this.trimPoolColumns(this.hidden);
    this.deck      = this.trimPoolColumns(this.deck);
  }

  /**
   * Trim columns for a pool, keeping splitColumns in sync.
   * 
   * @param {Object} pool 
   */
  trimPoolColumns = (pool) => {
    // helper method to find the last non-empty column index
    const lastNonEmptyIndex = (columns) => {
      if (!columns) return -1;
      // iterate in reverse to find the index of the last non-empty column
      for (let idx = columns.length - 1; idx >= 0; idx--) {
        const column = columns[idx];
        if (column.cardIds.length) {
          // found last non-empty column
          return idx;
        }
      }
      return -1;
    }
    
    // calculate the last non-empty column index across columns/splitColumns
    const lastColumnIdx = lastNonEmptyIndex(pool.columns);
    const lastSplitColumnIdx = lastNonEmptyIndex(pool.splitColumns);
    const lastIdx = Math.max(lastColumnIdx, lastSplitColumnIdx);

    // construct the trimmed pool
    const trimmedPool = { columns: pool.columns.slice(0, lastIdx + 1) };
    if (pool.splitColumns) {
      trimmedPool.splitColumns = pool.splitColumns.slice(0, lastIdx + 1);
    }
    return trimmedPool;
  }

  /**
   * Add new columns to equalize the size of `deck.columns` and `deck.splitColumns`
   */
  equalizeDeckColumns() {
    if (this.deck.splitColumns) {
      // create empty columns
      const missing = this.deck.columns.length - this.deck.splitColumns.length;
      const newColumns = new Array(Math.abs(missing)).fill().map(a => ({ cardIds: [] }));

      if (missing > 0) {
        // add to splitColumns
        this.deck.splitColumns.push(...newColumns);
      }
      else if (missing < 0) {
        // add to columns
        this.deck.columns.push(...newColumns);
      }
    }
  }

  /**
   * @param {string} name 
   * @param {number} count 
   * @param {Object} metadata (optional)
   * @returns {Object} a map consiting of card ids to cards: 
   *  { "card-id": { cardId: "card-id", name: "card name", set: "xxx" } }
   */
  createCards(name, count, metadata) {
    // create a distinct card object for duplicates of the same card
    const cards = {};
    for (let i = 0; i < count; i++) {
      const cardId = this.newCardId();
      cards[cardId] = { cardId: cardId, name: name, ...metadata };
      // explicitly lower-case the set code
      cards[cardId].set = metadata?.set?.toLowerCase()
      // special handling for card set DAR -> convert to DOM (Dominaria)
      if (cards[cardId].set === "dar") {
        cards[cardId].set = "dom";
      }
    }
    return cards;
  }

  newCardId() {
    return uuidv4();
  }

  /**
   * Partition all cards in the given pool using the provided partition function.
   * 
   * @param {*} pool the pool of cards to partition
   * @param {*} partitionFn the partition function
   * @returns {Object} An object containing the partitioned `columns` and `splitColumns`
   */
  partitionColumns(pool, partitionFn) {
    const topCardsByColumn = [];
    const topCardColumnsToSplit = [];
    const bottomCardsByColumn = [];

    // for each column in the top, partition the cards
    pool.columns.forEach((col, idx) => {
      const cards = this.columnCards(col);
      const [top, bottom] = this.partition(cards, partitionFn);
      topCardsByColumn.push(top);
      // store to-be-split cards separately so that we can append the end of the 
      // bottom columns (so that it looks less surprising)
      topCardColumnsToSplit.push(bottom);
    });

    // now do same for the split columns
    if (pool.splitColumns) {
      pool.splitColumns.forEach((col, idx) => {
        const cards = this.columnCards(col);
        const [top, bottom] = this.partition(cards, partitionFn);
        // push partitioned cards onto existing column or create new column
        if (topCardsByColumn.length > idx) {
          topCardsByColumn[idx].push(...top);
        } else {
          topCardsByColumn.push(top);
        }
        // push to-be-split cards onto existing column or create a new column
        if (bottomCardsByColumn.length > idx) {
          bottomCardsByColumn[idx].push(...bottom);
        } else {
          bottomCardsByColumn.push(bottom);
        }
      });
    }

    // append any to-be-split cards on top to the correct bottom column
    topCardColumnsToSplit.forEach((cards, idx) => {
      if (bottomCardsByColumn.length > idx) {
        bottomCardsByColumn[idx].push(...cards);
      } else {
        bottomCardsByColumn.push(cards);
      }
    });

    // convert card objects to columns with cardIds
    const topColumns = topCardsByColumn.map((col) =>
      ({ cardIds: col.map(card => card.cardId) }) 
    );
    const bottomColumns = bottomCardsByColumn.map((col) =>
      ({ cardIds: col.map(card => card.cardId) })
    );

    return {
      columns: topColumns,
      splitColumns: bottomColumns,
    };
  }
  
  /**
   * Basic utility helper method for partitioning an array by some condition.
   */
  partition = (array, partitionFn) => array.reduce((acc, e) => {
    acc[partitionFn(e) ? 0 : 1].push(e);
    return acc;
  }, [[], []]);

}

/**
 * A card pool with the sideboard representing an entire set
 */
export class SetCardPool extends CardPool {

  /**
   * @param {String} setCode the card set for which to initialize the full set card pool
   * @returns a {@link Promise} to fetch the set cards and return the card pool
   */
  static async initialize(setCode, json) {
    const poolStateDeck = {};
    if (json && json.deck) {
      // only initialize the deck and the deck cards
      poolStateDeck.deck = json.deck;
      const deckCardIds = json.deck.columns.flatMap(col => col.cardIds);
      // check the deck for splitColumns
      if (json.deck.splitColumns) {
        const splitCardIds = json.deck.splitColumns.flatMap(col => col.cardIds);
        deckCardIds.push(...splitCardIds);
      }
      poolStateDeck.cards = deckCardIds.reduce((deckCards, cardId) => 
        ({ ...deckCards, [cardId]: json.cards[cardId] }),
        {});
    }
    
    return new SetCardPool(poolStateDeck).fetchSetCards(setCode);
  }

  /**
   * Create a new CardPool object, optionally initialized with the provided JSON state
   * (as if the state were generated from `CardPool.toJson`).
   * 
   * @param {Object} json the JSON card pool state (optional)
   */
  constructor(json = {}) {
    // Do *not* filter the sideboard data before passing to the parent constructor because 
    // it might contain restored session state with set cards. If it contains a non-set pool
    // then the caller should initialize the set card pool correctly
    super(json);

    // initialize the local counts of deck card names
    this.deckCardNameCounts = {};
    if (json.deck) {
      this.deckCardIds().forEach(cardId => this.incrementDeckCount(this.card(cardId).name));
    }
  }
  
  /**
   * @param {Boolean} includeAllData `true` to include all card data in the export.
   * This includes the sideboard (usually excluded from SetCardPool exports) and 
   * Scryfall card data in the the `scryfallCards` property.
   * @returns a JSON representation of this SetCardPool for exporting/serializing, 
   * only including the deck content (no sideboard (set) content).
   */
  export(includeAllData = false) {
    if (includeAllData) {
      // export as normal (both deck and sideboard) and includeAllData
      return super.export(true);
    }
    else {
      const deckCardIds = this.deckCardIds();
      // sanitize exported card metadata when `!includeAllData`
      const deckCardsById = deckCardIds.reduce(
        (map, cardId) => ({ ...map, [cardId]: this.sanitizeMetadata(this.cardMetadataById[cardId])}),
        /* initialValue = */ {});
  
      return {
        cards: deckCardsById,
        deck: this.deck
        // exclude sideboard
      };
    }
  }

  /**
   * @returns a JSON representation of the deck card counts in this SetCardPool:
   * ```
     {
       deck: [ { name: "...", count: 1, set: "xxx" }, ... ]
     }
   * ```
   */
  exportCardCounts(includeSet = false, includeCollectorNo = false) {
    const cardCounts = super.exportCardCounts(includeSet, includeCollectorNo);
    // for this SetCardPool only return the deck cards, not the the full sideboard
    return { deck: cardCounts.deck };
  }

  async fetchSetCards(setCode) {
    return this.scryfallCards.fetchSetCards(setCode)
    .then(scryfallCards => {    
      // map any existing deck cards to scryfall set cards; update the scryfall_id for existing cards
      const deckCards = this.deckCardIds().map(this.card);
      const enrichedDeckMetadata = this.mapScryfallCardsToCardMetadata(scryfallCards, deckCards);

      // create card metadata for each new sideboard card in the set
      const sideboardCardMetadata = scryfallCards.map(scryfallCard => {
        // initialize new card metadata
        const metadata = { 
          cardId: this.newCardId(), 
          scryfall_id: scryfallCard.id,
          name: scryfallCard.name
        };
        
        // if this card already exists in the deck, set the new sideboard card to ProxySideboard
        const existsInDeck = this.deckCardNameCounts[scryfallCard.name];
        metadata.diffOrigin = existsInDeck ? DiffState.ProxySideboard : DiffState.Sideboard;
        return metadata;
      });

      // initially add all new card IDs to the first sideboard column (sort below)
      const cardIds = sideboardCardMetadata.map(metadata => metadata.cardId);
      this.sideboard = { columns: [ { cardIds: cardIds } ]};
      // update the cardpool metadata
      this.updateCardMetadata(enrichedDeckMetadata, sideboardCardMetadata);
      // always default-sort the sideboard by color identity
      this.sortSideboardBy(cardsToColumnsByColorIdentity);
      return this; // return the updated CardPool
    })
  }

  /**
   * Move a card within the SetCardPool.  When switching pools, copy or delete the card instead.
   * 
   * @param {string} cardId the card ID to move
   * @param {string} destPoolName the destination pool name
   * @param {string} destColumnGroup the destination column group name
   * @param {number} destColumnIdx the column index within the destination column group
   * @param {number} destCardIdx the card index within the destination column
   */
  moveCard(cardId, destPoolName, destColumnGroup, destColumnIdx, destCardIdx) {
    const card = this.card(cardId);  
    // find the card's source pool/column
    const source = this.findCardLocation(cardId);

    if (source.poolName === destPoolName) {
      // move the card as normal
      super.moveCard(cardId, destPoolName, destColumnGroup, destColumnIdx, destCardIdx);
    }
    else if (destPoolName === "deck") {
      // create new copy in the deck
      this.copyCardToDeck(cardId, destColumnGroup, destColumnIdx, destCardIdx);
      // update the original card in the sideboard to Proxy (it's been used)
      this.updateCardMetadata({ cardId: cardId, diffOrigin: DiffState.ProxySideboard });
    }
    else if  (destPoolName === "sideboard") {
      // helper function to find the first card ID with matching by name in the given pool
      const findSideboardCardIdByName = (cardName) => {
        const sameName = (id) => this.card(id).name === cardName;
        // limit to 1 result
        const found = this.findCardIdsByColumn(this.sideboard, sameName, 1);
        if (found.length) {
          return found[0];
        }
        else {
          console.warn(`Could not find a card in the sideboard with name '${cardName}'`);
          return undefined;
        }
      }

      // delete the moved card from the deck (no move, sideboard card still exists)
      this.deleteFromColumns(this[source.poolName], cardId);
      this.decrementDeckCount(card.name);
      this.trimEmptyColumns();
      this.deleteCardIds(cardId);

      // determine if there are any more of this card in the deck
      const existsInDeck = this.deckCardNameCounts[card.name];
      if (!existsInDeck) {
        // if the card is not in the deck, update the card in the sideboard to non-Proxy
        const sideboardId = findSideboardCardIdByName(card.name);
        if (sideboardId) {
          this.updateCardMetadata({ cardId: sideboardId, diffOrigin: DiffState.Sideboard });
        }
      }
    }
    else {
      console.error("Tried to move", cardId, "to unknown pool:", destPoolName);
    }
  }

  /**
   * Copy the specified card ID to the destination.
   * 
   * @param {string} cardId the card ID to copy
   * @param {string} destPoolName the destination pool name
   * @param {string} destColumnGroup the destination column group name
   * @param {number} destColumnIdx the column index within the destination column group
   * @param {number} destCardIdx the card index within the destination column
   */
  copyCardToDeck(cardId, destColumnGroup, destColumnIdx, destCardIdx) {
    const metadata = this.cardMetadataById[cardId];
    // copy the card metadata and explicitly set the diffOrigin to Sideboard (original could be a Proxy)
    const copy = { ...metadata, diffOrigin: DiffState.Sideboard };
    // generate a new ID for the copy
    copy.cardId = this.newCardId();
    // increment the count of deck cards with this name
    this.incrementDeckCount(copy.name);
    this.updateCardMetadata(copy);

    // then add to destination using the base CardPool.moveCard function
    super.moveCard(copy.cardId, "deck", destColumnGroup, destColumnIdx, destCardIdx);
  }

  incrementDeckCount(name) {
    let count = this.deckCardNameCounts[name];
    if (!count) {
      count = 0;
    }
    this.deckCardNameCounts[name] = count + 1;
  }

  decrementDeckCount(name) {
    const count = this.deckCardNameCounts[name];
    if (count) {
      this.deckCardNameCounts[name] = count - 1;
    }
    else {
      console.warn("Decrementing count for card '" + name + "' which is already at", count)
    }
  }

  /**
   * Clear all cards from the deck into the sideboard, and reset the deck to empty.  
   * Delete all lands in the deck.
   */
  clearDeck() {
    // update the diffOrigin of *all* sideboard cards back to DiffState.Sideboard
    this.sideboardCardIds().forEach(cardId => {
      this.updateCardMetadata({ cardId: cardId, diffOrigin: DiffState.Sideboard });
    });
    // explicitly clear the local counts of deck card names
    //this.deckCardNameCounts = {};
    // now clear the deck
    super.clearDeck();
  }
}


/**
 * A tier list card pool with the sideboard representing an entire set
 */
export class TierListCardPool extends CardPool {

  /**
   * @param {String} setCode the card set for which to initialize the tier list
   * @returns a {@link Promise} to fetch the tier list cards and return the card pool
   */
  static async initialize(setCode) {
    // initialize column titles for a new TierListCardPool to the `OrderedTiers`
    const columns = TierListCardPool.emptyColumns();
    const emptyTierlist = { set: setCode, deck: { columns: columns }};
    return new TierListCardPool(emptyTierlist).fetchSetCards();
  }

  /**
   * Create empty columns with titles to match {@link OrderedTiers}.
   * 
   * @returns the empty columns array
   */
  static emptyColumns() {
    return OrderedTiers.map(tier => ({ title: tier, cardIds: [] }));
  }

  /**
   * Create a new CardPool object, optionally initialized with the provided JSON state
   * (as if the state were generated from `CardPool.toJson`).
   * 
   * @param {Object} json the JSON card pool state (optional)
   */
  constructor(json = {}) {
    // Do *not* filter the sideboard data before passing to the parent constructor because 
    // it might contain restored session state with set cards. If it contains a non-set pool
    // then the caller should initialize the set card pool correctly
    super(json);

    // track the set code (if provided)
    this.set = json.set;
  }

  async fetchSetCards() {
    return this.scryfallCards.fetchSetCards(this.set)
    .then(scryfallCards => {    
      // map any existing deck cards to scryfall set cards; update the scryfall_id for existing cards
      const deckCards = this.deckCardIds().map(this.card);
      const enrichedDeckMetadata = this.mapScryfallCardsToCardMetadata(scryfallCards, deckCards);
      
      // track existing deck card names (in lower case)
      const existingDeckCardNames = deckCards.reduce(
        (acc, card) => acc.add(card.name.toLowerCase()), new Set());

      // create card metadata for each *new* sideboard card in the set
      const sideboardCardMetadata = scryfallCards.flatMap(scryfallCard => {
        // if this card already exists in the deck, skip the new sideboard card
        if (existingDeckCardNames.has(scryfallCard.name.toLowerCase())) return [];

        // initialize new card metadata
        const metadata = { 
          cardId: this.newCardId(), 
          scryfall_id: scryfallCard.id,
          name: scryfallCard.name
        };
        return [metadata];
      });

      // initially add all new card IDs to the first sideboard column (sort below)
      const cardIds = sideboardCardMetadata.map(metadata => metadata.cardId);
      this.sideboard = { columns: [ { cardIds: cardIds } ]};
      // update the cardpool metadata
      this.updateCardMetadata(enrichedDeckMetadata, sideboardCardMetadata);
      // always default-sort the sideboard by color identity
      this.sortSideboardBy(cardsToColumnsByColorIdentity);
      return this; // return the updated CardPool
    })
  }

  /**
   * @returns a JSON state representation of this tier list card pool: 
   * ```
     {
       set: "xxx",
       sideboard: { columns: [ { cardIds: [] } ] }, 
       hidden: { columns: [ { cardIds: [] } ] }, 
       deck: { columns: [ { cardIds: [] } ] }
     }
     ```
   */
     toJsonState() {
      return {
        set: this.set,
        ...super.toJsonState()
      };
    }
  
  /**
   * @returns a JSON representation of this card pool's cards and their tiers:
   * ```
     {
       set: "xxx",
       cards: [ { name: "", tier: "" }, ... }]
     }
   * ```
   */
  exportTierList() {
    const jsonCards = [];

    const numColumns = Math.max(this.deck.columns.length, 
      // consider splitColumns for max number of columns, if it exists
      this.deck.splitColumns ? this.deck.splitColumns.length : 0);

    // for each card in each column (and splitColumns), create its json export object
    for (let i = 0; i < numColumns; i++) {
      const column = this.deck.columns[i];
      // consider the column title as the tier
      const tier = column.title ? column.title : null;

      // create JSON { name, tier } for each card in this deck column
      const cards = this.columnCards(column);
      jsonCards.push(...cards.map(card => 
        ({ name: card.name, tier: tier })
      ));

      // create JSON { name, tier }  for each card in this deck splitColumn
      if (this.deck.splitColumns?.length > i) {
        const splitCards = this.columnCards(this.deck.splitColumns[i]);
        jsonCards.push(...splitCards.map(card => 
          ({ name: card.name, tier: tier })
        ));
      }
    }

    // all sideboard cards have an explicit `null` tier
    jsonCards.push(...this.sideboardCardIds().map(cardId => {
      const card = this.card(cardId);
      return { name: card.name, tier: null };
    }));

    return { set: this.set, cards: jsonCards };
  }

  /**
   * Clear the Tier List (stored in the `deck`) and reset column titles.
   */
  clearDeck() {
    super.clearDeck();
    // reset deck columns with titles for each of the `OrderedTiers`
    this.deck.columns = TierListCardPool.emptyColumns();
  }

  /**
   * Trim the empty columns from the end of each sideboard/deck cardpool.
   */
  trimEmptyColumns() { 
    let lastColumnIdx = -1;
    // iterate in reverse to find the index of the last non-empty or titled column
    for (let idx = this.deck.columns.length - 1; idx >= 0; idx--) {
      const column = this.deck.columns[idx];
      // found last column with a card OR a title
      if (column.cardIds.length || column.title?.length) {
        lastColumnIdx = idx;
        break;
      }
    }
    
    // if found, trim the deck pool down to the last column
    if (lastColumnIdx >= 0) {
      this.deck.columns = this.deck.columns.slice(0, lastColumnIdx + 1);
    }
    
    // trim empty columns from the sideboard pool
    this.sideboard = this.trimPoolColumns(this.sideboard);
  }

}
