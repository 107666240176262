import React from 'react';
import { Droppable } from '@hello-pangea/dnd';

import DraggableCard from '../components/DraggableCard';
import DiffState from '../services/diff-state';

export default class Column extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    // skip rendering the entire Column (and Droppable) if no change in cards
    return !this.allCardsEqual(this.props.cards, nextProps.cards);
  }

  allCardsEqual = (cards1 = [], cards2 = []) => {
    if (cards1 === cards2) {
      //NOTE: this case is never entered; the cards array is always regenerated in Sideboard/Deck
      return true;
    }

    // check one level deep (compare individual card object references)
    //FIXME comparing array contents would not be needed if the same card array is passed in;
    // need to memoize higher up for this AND ensure that the array isn't changed/recreated 
    // along the way (i.e., CardPoolController, CardPoolEditor, Sideboard/Deck)
    return cards1.length === cards2.length &&
      cards1.every((item, index) => (item === cards2[index]));
  };

  render() {
    return (
      <Droppable droppableId={this.props.id}>
        { provided => 
          <div className={this.wrapperClasses()} >
            <div className="column-header">
              {this.renderColumnHeader()}
            </div>
            <div className="column" ref={provided.innerRef} {...provided.droppableProps}>
              {this.renderCards(this.props.cards)}
              {provided.placeholder}
            </div>
          </div>
        }
      </Droppable>
    );
  }

  renderColumnHeader = () => {
    const cardCount = this.cardCount();
    return <>{ cardCount ? cardCount : "\u00a0" }</>;
  }

  renderCards(cards) {
    return cards.map((card, index) =>
      <DraggableCard 
        index={index}
        key={card.cardId} 
        card={card} 
        columnId={this.props.id}
        onTransform={this.props.onCardTransform}
        onDoubleClick={this.handleCardDoubleClick}
      />
    );
  }

  /**
   * @returns the number of cards in this column, excluding any Proxy cards
   */
  cardCount = () => this.props.cards.reduce(
    (count, card) => DiffState.isProxy(card.diffOrigin) ? count : count + 1,
    /* initialValue = */ 0);

  wrapperClasses = () => "column-wrapper";

  handleCardDoubleClick = (cardId) => {
    // include the column ID (droppableId)
    this.props.onCardDoubleClick(cardId, this.props.id);
  }
}


export class TitledColumn extends Column {

  shouldComponentUpdate(nextProps, nextState) {
    // a TitledColumn should update if the title has changed (and if the base Column should update)
    return this.props.title !== nextProps.title ||
      super.shouldComponentUpdate(nextProps, nextState);
  }

  /**
   * @returns the column's title, or non-breaking space if none was specified
   */
  renderColumnHeader = () => 
    <div className="column-title">{this.props.title ? this.props.title : "\u00a0"}</div>;
    
}


export class LastColumn extends Column {
  // add the 'last' class to the column wrapper
  wrapperClasses = () => "column-wrapper last";
}
