import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync }          from '@fortawesome/free-solid-svg-icons/faSync';

import CardZoom from './CardZoom';

import DiffState from '../services/diff-state';

import { isDoubleFaced } from '../helpers/card.helpers';
import { 
  GIH_COUNT_PROPERTY,
  GIH_SET_PROPERTY,
  GIH_WR_DIGITS,
  GIH_WR_PROPERTY,
  OrderedTierRatings,
  RATING_SCORES_PROPERTY,
  RATING_SET_PROPERTY,
  RATING_TIER_PROPERTY } from '../constants';


/**
 * The card width; must match var(--card-width) in App.css
 */
export const CARD_WIDTH_PX = 184;

const TIMEOUT_DOUBLE_CLICK = 400;

export default class Card extends React.Component {

  constructor(props) {
    super(props);
    // initialize local clickCount variable for custom doubleClick detection
    this.clickCount = 0;
    // create a ref for the card <img/> itself
    this.cardFrontImageRef = React.createRef();
    
    // initialize isZoomVisible state to false
    this.state = { isZoomVisible: false };
  }

  componentWillUnmount() {
    // remove key up/down listener when unmounting the card
    this.removeKeyListeners();
  }

  render() {
    return <div className={this.determineClassNames()}
      // handle mouse over for toggling card zoom
      onMouseOver={this.handleMouseEnter}
      onMouseLeave={this.handleMouseLeave}
      // handle onClick for custom double click evaluation;
      // the onDoubleClick event doesn't re-trigger quickly for repeated double clicks
      onClick={this.handleClick}
      // handle mouse down for toggling card zoom
      onMouseDown={this.handleMouseDown}
      // prevent the right-click context menu
      onContextMenu={(e)=> e.preventDefault()}
    >
      {this.renderCardFaces()}
      <div className="additional">
        {this.maybeRenderScryfallLink()}
        {this.maybeRenderGIHWR()}
        {this.maybeRenderRating()}
        {this.maybeRenderTransformButton()}
      </div>
      {this.maybeRenderCardZoom()}
    </div>;
  }

  determineClassNames = () => {
    const classNames = ["card"];
    // add `alpha` class name for LEA (to stylize rounded corners)
    if (this.props.card.set === "lea") {
      classNames.push("alpha");
    }
    const isDFC = isDoubleFaced(this.props.card);
    // designate as having "extras" to show on the card?
    if (this.hasCardRating() || 
        this.hasGIHWR() || 
        isDFC || 
        this.hasStupidCardFrame() ||
        this.isImageMissing())
      classNames.push("extras");
    // is this a transformed DFC?
    if (isDFC && this.props.card.transformed) classNames.push("transformed");

    // check for diffOrigin
    const originProxy = DiffState.isProxy(this.props.card.diffOrigin);
    if (originProxy) classNames.push("origin-proxy");
    
    return classNames.join(" ");
  }

  renderCardFaces() {
    // set the card name as tooltip when no image, otherwise no tooltip (undefined)
    const tooltip = this.isImageMissing() ? this.props.card.name : undefined;

    // create the card front
    const cardFrontDiv = 
      <div className={"card-front"}>
        <img 
          ref={this.cardFrontImageRef} 
          src={this.cardImageUri()} 
          alt={this.props.card.name} 
          title={tooltip} />
      </div>;

    // maybe create the card back (if it exists)
    let maybeCardBackDiv = <></>;
    if (isDoubleFaced(this.props.card)) {
      maybeCardBackDiv = 
        <div className={"card-back"}>
          <img 
            src={this.cardImageUri(1)} 
            alt={this.props.card.name} 
            title={tooltip} />
        </div>;
    }

    return <div className={"card-faces"}>
        {cardFrontDiv}
        {maybeCardBackDiv}
      </div>;
  }

  maybeRenderScryfallLink = () => {
    if (this.isImageMissing()) {
      // for debugging in case of error when fetching card image
      return <div className={"missing-image"} >
        {/* eslint-disable-line */} <a 
            target="_blank" rel="noopener"
            href={'https://scryfall.com/search?q='+this.props.card.name}>
          {this.props.card.name}
        </a>
        </div>;
    }
    return <></>;
  }

  maybeRenderTransformButton = () => {
    if (isDoubleFaced(this.props.card)) {
      return (
        <div className={"transform"}>
          <button className={"transform"}
              onClick={this.transformCard}>
            <FontAwesomeIcon icon={faSync} transform="rotate-160" />
          </button>
        </div>);
    }
    return <></>;
  }

  maybeRenderRating = () => {
    if (this.hasCardRating()) {
      return (
        <span className={"rating"}>
          {this.renderCardRating(this.props.card)}
        </span>);
    }
    return <></>;
  }

  renderCardRating(card) {
    const rating_set = this.props.card[RATING_SET_PROPERTY];
    const renderSet = rating_set 
        ? <>&nbsp;<i className={`ss ss-${rating_set.toLowerCase()} ss-fw`}></i></> 
        : <></>;

    // an explicitly 'null' (not undefined) tier/score implies loading
    if (card[RATING_TIER_PROPERTY] === null || card[RATING_SCORES_PROPERTY] === null) {
        // Loading ellipsis ... (with pulse)
        return <span className="gihwr"><span className="pulse">&hellip;</span></span>;
    }
    // check if the card has a tier/grade rating
    else if (card[RATING_TIER_PROPERTY]) {
      return <span style={ this.cardTierStyle(card) }>{card[RATING_TIER_PROPERTY]}{renderSet}</span>
    }
    // otherwise check for score ratings and convert to tiers
    else if (card[RATING_SCORES_PROPERTY]?.length) {
      // create a stylized span for each rating, separated by ' // '
      const ratingSpans = card[RATING_SCORES_PROPERTY].map((rating, idx) =>
          <span key={card.cardId+"-rating-"+idx} style={ this.cardRatingStyle(rating) }>
            {rating}
          </span>
        ).reduce((acc, x) => acc === null ? [x] : [acc, ' // ', x], null)

      return <React.Fragment>{ratingSpans}{renderSet}</React.Fragment>;
    }
  }

  maybeRenderGIHWR = () => {
    if (this.hasGIHWR()) {
      const gih_wr = this.props.card[GIH_WR_PROPERTY];
      const gih_game_count = this.props.card[GIH_COUNT_PROPERTY];
      const gih_set = this.props.card[GIH_SET_PROPERTY];

      // an explicitly 'null' (not undefined) GIH WR implies loading
      if (gih_wr === null) {
        // Loading ellipsis ... (with pulse)
        return <span className="gihwr"><span className="pulse">&hellip;</span></span>;
      }
      else {
        const gih_wr_pct = Number.isNaN(gih_wr) ? "---"
            : Number(gih_wr).toLocaleString(undefined, 
              { style: 'percent', minimumFractionDigits: GIH_WR_DIGITS-2 });

        // calculate the (optional) GIH WR set name (plus whitespace) to embed in the title
        let gih_set_name = gih_set ? gih_set.toUpperCase() : this.props.card.set?.toUpperCase();
        let gih_wr_title = Number.isNaN(gih_wr) ? 
            `Not enough ${gih_set_name} data; `
            : `${gih_wr_pct} ${gih_set_name} win rate; `;
        gih_wr_title += `${parseInt(gih_game_count).toLocaleString()} games in hand`;

        const renderSet = gih_set ? <>&nbsp;<i className={`ss ss-${gih_set.toLowerCase()} ss-fw`}></i></> : <></>;

        return (
          <span className={"gihwr"}>
            <span title={gih_wr_title}>{gih_wr_pct}</span>{renderSet}
          </span>);
      }
    }
    else return <></>;
  }

  maybeRenderCardZoom = () => {
    if(this.state.isZoomVisible && this.cardFrontImageRef) {
      // get the bounding rectangle for the Card DIV itself
      const boundingRect = this.cardFrontImageRef.current.getBoundingClientRect();
      return <CardZoom card={this.props.card} cardBoundingRect={boundingRect} />
    }
    else return <></>;
  }

  isImageMissing() {
    return !(this.props.card.image_uris || this.props.card.hasOwnProperty("card_faces"));
  }

  cardImageUri(cardFaceIdx) {
    // display the first card face by default
    const idx = cardFaceIdx ? cardFaceIdx : 0;
    // if cardFaceIdx is undefined, check image_uris first
    if ((typeof cardFaceIdx === 'undefined') && this.props.card.image_uris)
      return this.props.card.image_uris.normal;
    // if no image_uris, check for image_uris in card.card_faces 
    else if (this.props.card.hasOwnProperty("card_faces") && 
        this.props.card.card_faces[idx] &&
        this.props.card.card_faces[idx].hasOwnProperty("image_uris")) {
      return this.props.card.card_faces[idx].image_uris.normal;
    }
    else {
      // be funny on April 1st
      const today = new Date();
      if (today.getMonth() === 3 && today.getDate() === 1) {
        return "/errors/missingPurple.jpg"
      }
      return "/errors/missing.jpg";
    }
  }

  hasCardRating() {
    // specifically want to avoid 'undefined' -- NaN is OK (to be shown as '---'); 'null' is Loading
    const hasTierRating = (typeof this.props.card?.[RATING_TIER_PROPERTY]) !== "undefined";
    const hasScoreRating = (typeof this.props.card?.[RATING_SCORES_PROPERTY]) !== "undefined"
        // non-empty ratings
        && (this.props.card?.[RATING_SCORES_PROPERTY]?.some(c => c.length));
    return hasTierRating || hasScoreRating;
  }

  cardRatingStyle(rating) {
    // determine text color based on card rating
    const tierRating = OrderedTierRatings.find(t => t.rating === rating);
    if (tierRating) return { "color" : tierRating.color };
  }

  cardTierStyle(card) {
    // determine text color based on card tier
    const tierRating = OrderedTierRatings.find(t => card[RATING_TIER_PROPERTY] === t.tier);
    if (tierRating) return { "color" : tierRating.color };
  }

  hasStupidCardFrame() {
    // 'otp' is the 'Breaking News' bonus sheet for 'Outlaws of Thunder Junction'
    // and it has a ridiculous newspaper style card frame
    return (this.props.card.set === 'otp')
  }

  hasGIHWR() {
    // specifically want to avoid 'undefined' -- NaN is OK (to be shown as '---'); 'null' is Loading
    return (typeof this.props.card?.[GIH_WR_PROPERTY]) !== "undefined";
  }

  transformCard = (e) => {
    // stop the event from propagating 
    // (prevent double-click when mashing the button like a monkey)
    e.stopPropagation();

    this.props.onTransform(this.props.card.cardId);
  }

  handleClick = (e) => {
    // custom handling for detecting double clicks because the built-in 
    // onDoubleClick event doesn't re-trigger quickly for repeated double clicks
    this.clickCount += 1;
    if (this.clickCount === 1) {
      this.singleClickTimer = setTimeout(
        () => { this.clickCount = 0; }, 
        TIMEOUT_DOUBLE_CLICK);
    }
    else if (this.clickCount === 2) {
      this.handleDoubleClick(e);
      clearTimeout(this.singleClickTimer);
      this.clickCount = 0;
    }
  }

  handleDoubleClick = (e) => {
    this.props.onDoubleClick(this.props.card.cardId);
  }

  /*****************************************************************************
   * Event handling to alter zoom state (state.isZoomVisible)
   */

  handleMouseEnter = e => {
    // don't zoom on mouseover, but do listen for keydown events to trigger zooming 
    // when the mouse is over the card
    document.addEventListener("keydown", this.handleKeyPressed);
  }

  handleMouseLeave = e => {
    // remove key up/down listener when mouse leaves the card
    this.removeKeyListeners();
    this.hideCardZoom();
  }

  handleMouseDown = e => {
    // check if non-primary button
    if (e.button !== 0) {
      this.toggleCardZoom();
      // prevent default browser behavior of scroll compass
      e.preventDefault();
      // we handled the event, stop it from propagating/bubbling up
      e.stopPropagation();
      return;
    }
  }

  removeKeyListeners() {
    // remove the listener added on handleMouseEnter
    document.removeEventListener("keydown", this.handleKeyPressed);
    // remove the listener added on handleKeyPressed
    document.removeEventListener("keyup", this.handleZoomKeyUp);
  }

  handleKeyPressed = e => {
    // trigger zoom when key 'q' or 'z' is pressed (while the mouse is over the card)
    if (!this.state.isZoomVisible && (e.code === "KeyQ" || e.code === "KeyZ")) {
      // listen for zoom key depress
      document.addEventListener("keyup", this.handleZoomKeyUp);
      this.showCardZoom();
    }
  }

  handleZoomKeyUp = e => {
    this.hideCardZoom();
  }

  /**
   * End event handling to alter zoom state (state.isZoomVisible)
   *****************************************************************************/

  toggleCardZoom() {
    this.setState(prevState => { return { isZoomVisible: !prevState.isZoomVisible } });
  }

  showCardZoom() {
    this.setState({ isZoomVisible: true });
  }

  hideCardZoom() {
    this.setState({ isZoomVisible: false });
  }

}