import ButtonLink from './ButtonLink';

import './Patreon.css';

/**
 * @returns a Patreon link styled as a button
 */
export default function PatreonButton() {
  return <ButtonLink 
      className="patreon-button"
      target="_blank" 
      href="https://patreon.com/sealeddecktech"
      rel="noopener" 
      title="Become a patron" > 
    <span className="patreontext">
      <img src="/img/PATREON_SYMBOL_1_BLACK_RGB.svg" 
          className="patreonimg"
          alt="Patreon.com" 
      /> Become a patron
    </span>
  </ButtonLink>;
}
