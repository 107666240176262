import Scryfall  from './scryfall';
import { isStandardBasicLand } from '../helpers/card.helpers';

/**
 * Class to encapsulate the Scryfall card data storage and fetch operations for a CardPool.
 */
export default class CardPoolScryfallData {

  /**
   * Create a new CardPoolData object, optionally initialized with the provided JSON state
   * (as if the state were generated from `CardPool.toJson`).
   * 
   * @param {Object} scryfallCards the scryfall card pool data (optional)
   */
  constructor(scryfallCards = {}) {
    /**
     * `scryfallCards` contains a map of all Scryfall card IDs to Scryfall card data.
     */
    // Unless provided, the default is an empty object until explicitly fetched
    this.scryfallCards = scryfallCards ? scryfallCards : {};
  }

  allCards() {
    return { ...this.scryfallCards };
  }

  card(scryfallId) {
    return this.scryfallCards[scryfallId];
  }

  async fetchSetCards(setCode) {
    return Scryfall.fetchSet(setCode).then(this.updateScryfallCards);
  }

  async fetchCardsOnce(cardsToFetch) {
    return Scryfall.fetchCards(cardsToFetch).then(this.updateScryfallCards);
  }

  /**
   * Refresh all cards (or only those specified by the `cardsToRefresh` parameter) to maximize the 
   * number of cards that are in the set(s) with the most cards in `allCardMetadata`.
   * 
   * @param {Object[]} allCardMetadata an array of all existing card metadata, used to calculate 
   * which sets are the majority sets of already-known cards.
   * @param {Object[]} cardsToRefresh Optional array of card metadata representing the cards to 
   * refresh.  When specified, only those cards wil be refreshed for majority sets.
   * @returns a {@link Promise} with a result containing an array of any fetched cards.
   */
  async refreshCardsForMajoritySets(allCardMetadata, cardsToRefresh) {
    // re-fetch cards for "majority" sets that represent at least 10% of the full card pool;
    // use a floor of 7 cards (e.g., roughly half a pack)
    const minimum = Math.max(7, allCardMetadata.length * 0.1);

    // were the cards to fetch specified?  If not, determine which cards to refresh
    const cardsToFetch = cardsToRefresh ??
        // ONLY re-fetch card data when the card `set` was NOT anchored (in the query metadata)
        allCardMetadata.filter(metadata => !metadata.set)

    // pass the already retrieved non-land cards (which should include the set-anchored cards),
    // to determine the majority sets and which cards to re-fetch per set
    const allScryfallCards = allCardMetadata.flatMap(metadata => {
      if (metadata.scryfall_id) {
        return this.scryfallCards[metadata.scryfall_id];
      }
      else return []; // empty for flatMap
    });
    
    const cardsNonBasics = allScryfallCards.filter(c => !isStandardBasicLand(c));
    return Scryfall.fetchCardsForCommonSets(
        cardsToFetch, /* allScryfallCards = */ cardsNonBasics, minimum)
      .then(this.updateScryfallCards);;
  };

  /**
   * Update the locally stored Scryfall card data, indexed by Scryfall ID
   * 
   * @param {...Object[]} scryfallCards an array of one or more arrays containing Scryfall card 
   * data to add to the local `scryfallCards` map, indexed by Scryfall ID.
   */
  updateScryfallCards = (...scryfallCards) => {
    const allCards = scryfallCards.flat();
    allCards.forEach(scryfallCard => {
      const original = this.scryfallCards[scryfallCard.id];
      // merge the new Scryfall card data with/over any existing data;
      // freeze and store the merged object
      this.scryfallCards[scryfallCard.id] = Object.freeze({...original, ...scryfallCard});
    });
    return allCards;
  }

}