import { 
  cmcFromManaCost,
  getCardFront, 
  getPips, 
  getSortColor,
  getSortColorGroup,
  getSortColorIdentityGroup,
  getVisibleCardCmc,
  getVisibleCardFront,
  isAftermath,
  isLand,
  isMdfc,  
  isSplit,
  isStandardBasicLand
} from './card.helpers'
import {
  BasicLand,
  BasicLandColors,
  BasicLandTypes,
  BasicFetchLands,
  BasicTypeFetchLands,
  Colorless, 
  GIH_WR_DIGITS,
  GIH_WR_PROPERTY,
  Land,
  ManaSourceColors,
  OrderedColorsAndGroups, 
  OrderedColorGroups,
  OrderedRarities, 
  OrderedRatings,
  OrderedTiers,
  RATING_SCORES_PROPERTY,
  RATING_TIER_PROPERTY,
  Unknown,
  WUBRG
} from '../constants'

//TODO can this be replaced with cardsToColumnsByGroup
export function cardsToColumnsByCmc(cards, splitCards) {
  const cardGroups = groupCardsByCMC(cards);
  // extract unknown group
  let unknowns = [];
  if (cardGroups.hasOwnProperty(Unknown)) {
    unknowns = unknowns.concat(cardGroups[Unknown]);
    delete cardGroups[Unknown];
  }
  // extract land group
  let lands = [];
  if (cardGroups.hasOwnProperty(Land)) {
    lands = lands.concat(cardGroups[Land]);
    delete cardGroups[Land];
  }
  // track cmcs
  let cmcs = Object.keys(cardGroups);

  // handle special case for split pool columns
  let splitCardGroups = undefined;
  let splitUnknowns = [];
  let splitLands = [];
  if (splitCards) {
    splitCardGroups = groupCardsByCMC(splitCards);
    // extract unknown group
    if (splitCardGroups.hasOwnProperty(Unknown)) {
      splitUnknowns = splitCardGroups[Unknown];
      delete splitCardGroups[Unknown];
    }
    // extract land group
    if (splitCardGroups.hasOwnProperty(Land)) {
      splitLands = splitCardGroups[Land];
      delete splitCardGroups[Land];
    }
    // update cmc tracking with distinct cmcs
    cmcs = [...new Set(cmcs.concat(Object.keys(splitCardGroups)))];
  }

  // sort CMCs numerically
  cmcs = cmcs.map(k => parseFloat(k)).sort((a,b) => a - b);

  // create columns for each CMC group
  let colIdx = 0;
  const cmcColumns = [];
  const cmcSplitColumns = [];

  // prepend an unknown column if unknowns in pool
  if (unknowns.length + splitUnknowns.length > 0) {
    cmcColumns[colIdx] = unknowns;
    cmcSplitColumns[colIdx] = splitUnknowns;
    colIdx += 1;
  }
  
  // prepend a land column if lands in pool
  if (lands.length + splitLands.length > 0) {
    cmcColumns[colIdx] = lands;
    cmcSplitColumns[colIdx] = splitLands;
    colIdx += 1;
  }

  // iterate over all CMCs, skipping empty columns
  cmcs.forEach(cmc => {
    // update main columns
    if (cardGroups.hasOwnProperty(cmc))
      cmcColumns[colIdx] = cardGroups[cmc];
    else 
      cmcColumns[colIdx] = [];

    // update split columns
    if (splitCardGroups && splitCardGroups.hasOwnProperty(cmc))
      cmcSplitColumns[colIdx] = splitCardGroups[cmc];
    else
      cmcSplitColumns[colIdx] = [];
    
    // increment column index
    colIdx += 1;
  });

  // return in pool format
  return { 
    columns: cardGroupsToColumns(cmcColumns),
    splitColumns: splitCards ? cardGroupsToColumns(cmcSplitColumns) : undefined
  };
}

/**
 * Separate the given cards into groups by card CMC.
 * Lands are considred a distinct group from 0 CMC cards.
 */
function groupCardsByCMC(cards) {
  if (!cards) return undefined;

  const unknowns = [];
  const lands = [];
  const cmcGroups = cards.reduce((map, card) => {
      // use the *visible* card front for CMC sorting
      const cardFront = getVisibleCardFront(card);

      // Special handling to sort Aftermath cards by CMC of front face
      if (isAftermath(card)) {
        // Aftermath cards are strange and do not have individual card_face cmcs
        // Calculate CMC from the `cardFront` mana_cost to distinguish between faces of:
        //  'Destined // Lead' cmc:6 - 'Destined' cmc:2; 'Lead' cmc:4
        const cmcBeforemath = cmcFromManaCost(cardFront.mana_cost);
        if (!map.hasOwnProperty(cmcBeforemath)) map[cmcBeforemath] = [];
        map[cmcBeforemath] = map[cmcBeforemath].concat(card);
      }
      // the card itself *should* always have the top-level CMC property
      else if (!card.hasOwnProperty("cmc")) {
        unknowns.push(card); // push onto unknown array
      }
      // handle lands separately from 0 cmc
      else if (isLand(cardFront))
        lands.push(card); // push onto lands array
      else {
        // check the card front, then the card for CMC
        const cmc = getVisibleCardCmc(card);
        if (!map.hasOwnProperty(cmc)) map[cmc] = [];
        map[cmc] = map[cmc].concat(card);
      }
      return map;
    }, 
    {}
  );

  cmcGroups[Unknown] = unknowns;
  cmcGroups[Land] = lands;
  return cmcGroups;
}

/**
 * Sort the given `cards` and `splitCards` arrays into columns by {@link OrderedColorGroups}.
 * 
 * The returned results is a pool data object containing 
 * { columns: [...], splitColumns: [...] } where `splitColumns` is `undefined`
 * if the provided `splitCards` is undefined.
 */
export function cardsToColumnsByColor(cards, splitCards) {
  const pool = cardsToColumnsByGroup(
    (cards) => groupCardsByColor(cards, getSortColorGroup),
    OrderedColorGroups,
    cards,
    splitCards);
  return filterEmptyColumns(pool);
}

/**
 * Sort the given `cards` and `splitCards` arrays into columns by {@link OrderedColorsAndGroups} .
 * 
 * The returned results is a pool data object containing 
 * { columns: [...], splitColumns: [...] } where `splitColumns` is `undefined`
 * if the provided `splitCards` is undefined.
 */
export function cardsToColumnsByColorCombination(cards, splitCards) {
  const pool = cardsToColumnsByGroup(
    (cards) => groupCardsByColor(cards, getSortColor),
    OrderedColorsAndGroups,
    cards,
    splitCards);
  return filterEmptyColumns(pool);
}

/**
 * Sort the given `cards` and `splitCards` arrays into columns by {@link OrderedColorGroups}.
 * 
 * The returned results is a pool data object containing 
 * { columns: [...], splitColumns: [...] } where `splitColumns` is `undefined`
 * if the provided `splitCards` is undefined.
 */
export function cardsToColumnsByColorIdentity(cards, splitCards) {
  const pool = cardsToColumnsByGroup(
    (cards) => groupCardsByColor(cards, getSortColorIdentityGroup),
    OrderedColorGroups,
    cards,
    splitCards,
    /* sortColorlessByIdentity = */ true);
  return filterEmptyColumns(pool);
}

/**
 * Separate the given cards into groups by card color, using the result 
 * of the provided `getCardColorFn(card)` function.
 */
export function groupCardsByColor(cards, getCardColorFn) {
  // group cards by color order id
  const colorGroups = cards.reduce((map, card) => {
      // get card color
      let colortype = getCardColorFn(card);
      // Unknown card color
      if (!colortype) colortype = Unknown;
      if (!map.hasOwnProperty(colortype)) map[colortype] = [];
      map[colortype] = map[colortype].concat(card);
      return map;
    }, 
    {}
  );

  return colorGroups;
}

/**
 * Sort the given `cards` and `splitCards` arrays into columns by rarity.
 * 
 * The returned results is a pool data object containing 
 * { columns: [...], splitColumns: [...] } where `splitColumns` is `undefined`
 * if the provided `splitCards` is undefined.
 */
export function cardsToColumnsByRarity(cards, splitCards) {
  const pool = cardsToColumnsByGroup(groupCardsByRarity, OrderedRarities, cards, splitCards);
  return filterEmptyColumns(pool);
}

/**
 * Separate the given cards into groups by card rarity
 */
function groupCardsByRarity(cards) {
  const rarityGroups = cards.reduce((map, card) => {
      let rarity = card.rarity ?? Unknown;
      if (isStandardBasicLand(card)) {
        // special rarity slot for basic lands
        rarity = BasicLand;
      }
      // specifically set unknown rarities
      if (!OrderedRarities.includes(rarity)) {
        console.debug("Unknown rarity '" + rarity + "' on card '" + card.name + "'");
        rarity = Unknown;
      }
      if (!map.hasOwnProperty(rarity)) map[rarity] = [];
      map[rarity] = map[rarity].concat(card);
      return map;
    }, 
    /* initialValue = */ {}
  );

  return rarityGroups;
}

/**
 * Sort the given `cards` and `splitCards` arrays into columns by 17Lands GIH WR.
 * 
 * The returned results is a pool data object containing 
 * { columns: [...], splitColumns: [...] } where `splitColumns` is `undefined`
 * if the provided `splitCards` is undefined.
 */
export function cardsToColumnsByGihwr(cards, splitCards) {
  const PARTITIONS = 100;
  // generate quantile ordering groups based on number of partitions
  const quantiles = [...Array(PARTITIONS+1).keys(), NaN].reverse()
    // map to string for matching column group keys
    .map(p => p.toString());

  // round statistics to the same precision as is used when displayed in Card.jsx
  const precision = Math.pow(10, GIH_WR_DIGITS);

  const pool = cardsToColumnsByGroup(
    // group cards by 17Lands GIH WR (ever_drawn_win_rate)
    (cards) => groupCardsByStatistic(cards, GIH_WR_PROPERTY, PARTITIONS, precision),
    quantiles,
    cards, splitCards);
  return filterEmptyColumns(pool);
}

/**
 * Separate the given cards into groups by the specified statistic property name
 */
function groupCardsByStatistic(cards, statistic, partitions, precision) {
  // group cards by numeric range
  const statGroups = cards.reduce((map, card) => {
      let statQuantile = Unknown;
      // get the statistic value to use for card sorting
      if (card.hasOwnProperty(statistic)) {
        // to avoid rounding errors, don't divide by precision
        const statValue = Math.round(Number(card[statistic]) * precision);
        statQuantile = Math.trunc(statValue * partitions / precision);
        if (isNaN(statQuantile)) statQuantile = NaN;
      }
      if (!map.hasOwnProperty(statQuantile)) map[statQuantile] = [];
      map[statQuantile] = map[statQuantile].concat(card);  
      return map;
    }, 
    {}
  );

  return statGroups;
}

/**
 * Sort the given `cards` and `splitCards` arrays into columns by rating OR tier.
 * 
 * The returned results is a pool data object containing 
 * { columns: [...], splitColumns: [...] } where `splitColumns` is `undefined`
 * if the provided `splitCards` is undefined.
 */
export function cardsToColumnsByRating(cards, splitCards) {
  // combine cards to determine if we are sorting by tiers or ratings
  const allCards = !!splitCards ? cards.concat(splitCards) : cards;
  // cards should only have `tier` or `ratings` (not both), group accordingly;
  // but NOT ALL cards will have a tier, so check for some/any
  const someCardsHaveTiers = allCards.some(card => card[RATING_TIER_PROPERTY]);
  if (allCards.length && someCardsHaveTiers) {
    const pool = cardsToColumnsByGroup(groupCardsByTier, OrderedTiers, cards, splitCards);
    return filterEmptyColumns(pool);
  }
  else {
    const pool = cardsToColumnsByGroup(groupCardsByRating, OrderedRatings, cards, splitCards);
    return filterEmptyColumns(pool);
  }
}

export function cardsToTierListColumns(cards, splitCards) {
  // explicitly sort Unknown card tiers as the LAST group
  const ordering = OrderedTiers.slice().concat([Unknown]);
  const tierListColumns = cardsToColumnsByGroup(groupCardsByTier, ordering, cards, splitCards);
  //Note: do not filter empty tier list columns

  // include the OrderedTiers as the `title` for each column
  const columns = tierListColumns.columns.map((column, i) => ({ ...column, title: OrderedTiers[i] }));
  // replace the now-sorted columns
  return { ...tierListColumns, columns: columns };
}

/**
 * Separate the given cards into groups by card rating.
 */
function groupCardsByRating(cards) {
  const ratingGroups = cards.reduce((map, card) => {
      let rating = Unknown;
      if (card[RATING_SCORES_PROPERTY]?.length) {
        // only use the first rating for grouping
        rating = card[RATING_SCORES_PROPERTY][0];
      }
      
      if (!map.hasOwnProperty(rating)) map[rating] = [];
      map[rating] = map[rating].concat(card);
      return map;
    }, 
     /* initialValue = */ {}
  );

  return ratingGroups;
}

/**
 * Separate the given cards into groups by card tier.
 */
function groupCardsByTier(cards) {
  const tierGroups = cards.reduce((map, card) => {
      const tier = card[RATING_TIER_PROPERTY] ?? Unknown;
      if (!map.hasOwnProperty(tier)) map[tier] = [];
      map[tier] = map[tier].concat(card);
      return map;
    }, 
     /* initialValue = */ {}
  );

  return tierGroups;
}

export function spreadCardsAcrossColumns(numColumns, cards, splitCards) {
  // spread cards evenly across `numColumns`
  const columns = new Array(numColumns).fill().map(a => ({ cardIds: [] }));
  cards.forEach((card, idx) => {
    const colIdx = idx % columns.length;
    columns[colIdx].cardIds.push(card.cardId);
  });

  // spread splitCards evenly across `numColumns` or set to undefined
  const splitColumns = splitCards ? new Array(numColumns).fill().map(a => ({ cardIds: [] })) : undefined;
  if (splitCards) {
    cards.forEach((card, idx) => {
      const colIdx = idx % splitColumns.length;
      splitColumns[colIdx].cardIds.push(card.cardId);
    });  
  }

  // return in pool format
  return { columns, splitColumns };
}

/**
 * Sort the given `cards` and `splitCards` arrays into groups using the groupCardsFn.
 * Then order those groups using the specified groupOrder.  The returned results is 
 * a pool data object containing { columns: [...], splitColumns: [...] } where 
 * `splitColumns` is undefined if the provided `splitCards` is undefined.
 */
function cardsToColumnsByGroup(groupCardsFn, groupOrder, cards, splitCards, sortColorlessByIdentity = false) {
  const cardGroups = groupCardsFn(cards);
  let splitCardGroups = undefined;

  // copy the ordering before modifying it
  const ordering = groupOrder.slice();
  // if not specified, order the `Unknown` grouping first
  if (!groupOrder.includes(Unknown)) {
    ordering.unshift(Unknown);
  }

  // helper function to sort by group order
  const sortByGroup = (cardGroups) => {
    const sortedColumns = Array(ordering.length).fill([]);
    Object.keys(cardGroups).forEach(groupName => {
      let groupIdx = ordering.indexOf(groupName);
      if (groupIdx < 0) {
        // if that group name isn't recognized, add it to the end
        groupIdx = ordering.length;
        ordering.push(groupName);
      }
      sortedColumns[groupIdx] = cardGroups[groupName];
    });
    return sortedColumns;
  }

  // group normal pool columns
  let groupedColumns = sortByGroup(cardGroups);
  // handle special case of grouping split pool columns
  let groupedSplitColumns = [];
  if (splitCards) {
    splitCardGroups = groupCardsFn(splitCards);
    groupedSplitColumns = sortByGroup(splitCardGroups);
  }

  // return in pool format
  return { 
    columns: cardGroupsToColumns(groupedColumns, sortColorlessByIdentity),
    splitColumns: splitCardGroups ? cardGroupsToColumns(groupedSplitColumns, sortColorlessByIdentity) : undefined
  };
}

export function cardGroupsToColumns(cardGroups, sortColorlessByIdentity = false) {
  // prepare the card sorting function with the `sortColorlessByIdentity` flag
  const cardSort = (card1, card2) => sort(card1, card2, sortColorlessByIdentity);
  // sort cards and convert each group to columns (of card IDs)
  return cardGroups.map((group, idx) => {
    // sort cards within each group/column
    const column = group.sort(cardSort);
    return {
      cardIds: column.map(card => card.cardId),
    }
  });
}

/**
 * Remove any `columns` (and `splitColumns`) that have no cardIds
 * 
 * @param {Object} pool the pool of cards to filter (i.e., sideboard or deck)
 */
function filterEmptyColumns(pool) {
  // helper function to determine which column indexes are empty
  const nonEmptyColumn = (idx) => {
    return (pool.columns[idx]?.cardIds?.length || pool.splitColumns?.[idx]?.cardIds.length);
  }

  return {
    columns: pool.columns.filter((col, idx) => nonEmptyColumn(idx)),
    splitColumns: pool.splitColumns?.filter((col, idx) => nonEmptyColumn(idx))
  }
}

function sort(card1, card2, colorlessByIdentity = false) {
  // maybe sort by GIHWR, if the property exists
  const card1gihwr = card1[GIH_WR_PROPERTY];
  const card2gihwr = card2[GIH_WR_PROPERTY];
  if (card1gihwr || card2gihwr) {
    // sort NaN after real numbers
    if (isNaN(card1gihwr)) return 1;
    if (isNaN(card2gihwr)) return -1;
    // sort higher GIH WR before lower GIH WR
    if (Number(card1gihwr) > Number(card2gihwr)) return -1;
    if (Number(card1gihwr) < Number(card2gihwr)) return 1;
  }

  // maybe sort by `tier`, if the property exists
  const card1tier = card1[RATING_TIER_PROPERTY];
  const card2tier = card2[RATING_TIER_PROPERTY];
  const card1tierIdx = card1tier ? OrderedTiers.indexOf(card1tier) : Number.MAX_VALUE;
  const card2tierIdx = card2tier ? OrderedTiers.indexOf(card2tier) : Number.MAX_VALUE;

  if (card1tierIdx > card2tierIdx) return 1;
  if (card1tierIdx < card2tierIdx) return -1;

  // maybe sort by the first of any `ratings`, if the property (array) exists
  const card1rating = card1[RATING_SCORES_PROPERTY]?.[0];
  const card2rating = card2[RATING_SCORES_PROPERTY]?.[0];
  if (card1rating || card2rating) {
    if (!card1rating) return 1;
    if (!card2rating) return -1;

    const card1ratingIdx = OrderedRatings.indexOf(card1rating);
    const card2ratingIdx = OrderedRatings.indexOf(card2rating);
    if (card1ratingIdx > card2ratingIdx) return 1;
    if (card1ratingIdx < card2ratingIdx) return -1;
  }

  // use the *visible* card front for sorting
  const card1Front = getVisibleCardFront(card1);
  const card2Front = getVisibleCardFront(card2);

  // sort lands before non-lands (using the card front)
  const card1land = isLand(card1Front);
  const card2land = isLand(card2Front);
  if (card1land || card2land) {
    if (!card1land) return 1;
    if (!card2land) return -1;
  }
  
  // sort individual columns by color...
  let card1colortype = getSortColor(card1);
  let card2colortype = getSortColor(card2);
  // if the flag is enabled and the card is colorless, sort instead by color *identity*
  if (colorlessByIdentity && card1colortype === Colorless)
    card1colortype = getSortColorIdentityGroup(card1);
  if (colorlessByIdentity && card2colortype === Colorless)
    card2colortype = getSortColorIdentityGroup(card2);
  const card1colorIdx = OrderedColorsAndGroups.indexOf(card1colortype);
  const card2colorIdx = OrderedColorsAndGroups.indexOf(card2colortype);
  
  if (card1colorIdx > card2colorIdx) return 1;
  if (card1colorIdx < card2colorIdx) return -1;

  // then by CMC.  Check the card front then fall back to the card itself; 
  // card faces don't have a CMC, but castable (MDFC) card faces do have 
  // a `mana_cost` that we can use instead of CMC
  let cmc1 = getVisibleCardCmc(card1);
  let cmc2 = getVisibleCardCmc(card2);
  if (cmc1 > cmc2) return 1;
  if (cmc1 < cmc2) return -1;

  // then by name; first checking the name of the visible card front
  const card1name = card1Front.name ?? card1.name;
  const card2name = card2Front.name ?? card2.name;
  if (card1name > card2name) return 1;
  if (card1name < card2name) return -1;

  return 0;
}

export function getColorsSortedByPips(cards) {
  // convert cards into card faces (separate split and MDFC faces)
  const cardFaces = cards.flatMap(card =>
    (isSplit(card) || isMdfc(card)) ? 
      [card.card_faces[0], card.card_faces[1]] 
      : getCardFront(card));
  // filter out lands card faces
  const nonLandCardFaces = cardFaces.filter(c => !isLand(c));
  // map and flatten color pips from each card face
  const cardPips = nonLandCardFaces.flatMap(face => getPips(face.mana_cost));

  // reduce card colors to counts by color
  const colorCounts = cardPips.flat()
    .filter(Boolean) // remove any 'undefined' colors (from getPips)
    .reduce((map, color) => {
      if (!map.hasOwnProperty(color)) {
        map[color] = { color: color, count: 0 };
      }
      // increment count
      map[color].count = map[color].count + 1;
      return map;
    }, {});
  
  // sort by count per color
  const colorsSorted = Object.values(colorCounts).sort((a, b) => {
    // compare counts
    const diff = b.count - a.count
    // break ties by WUBRG order
    if (diff === 0) {
      return WUBRG.indexOf(a.color) - WUBRG.indexOf(b.color);
    }
    return diff;
  });
  return colorsSorted;
}

export function groupCardsBySet(cards) {
  return cards.filter(card => card.set)
    .reduce((obj, card) => {
      const set = card.set.toLowerCase();
      if (!obj.hasOwnProperty(set)) obj[set] = [];
      obj[set] = obj[set].concat(card);
      return obj;
    },
    {}
  );
}

/**
 * Count the number of standard basic lands by their name: 
 * Plains, Island, Swamp, Mountain, Forest.
 * 
 * This does not count basic snow lands or non-basic lands with a basic land type.
 * 
 * @param {Object[]} deckCards the cards from which to count basic lands
 * @returns {Object[]} a mapping of basic land names to their count
 */
export function countStandardBasicLands(deckCards) {
  // initialize a map of (lower-case) basic land names and zero count
  const basicLandMap = BasicLandTypes.reduce((map, basicLand) => { 
    map[basicLand] = 0;
    return map;
  }, {});

  // reduce the list of deck cards to a map of basic land counts
  return deckCards.reduce((lands, card) => {
    const name = card.name.toLowerCase();
    // if this card is a basic land, increment its count
    if (lands.hasOwnProperty(name)) {
      lands[name] += 1;
    }
    return lands;
  }, basicLandMap);
}

export function countLandSources(deckCards) {
  // First, initialize an empty set to track basic lands/types in the deck
  const deckBasicLands = new Set();
  const deckBasicTypes = new Set();

  // helper method to add basic land types of a card to a given Set
  const addLandTypesToSet = (card, set) => {
    // check if this card has a standard basic land name
    // (don't need to wait to retrieve card data)
    if (isStandardBasicLand(card)) {
      // add the lower case card name to the provided Set
      set.add(card.name.toLowerCase());
    }
    else {
      // otherwise check if the card type line includes basic land types
      BasicLandTypes.forEach(basicLand => {
        // `type_line` is always capitalized from Scryfall, lower case to comapre with BasicLandTypes
        if (card.type_line && card.type_line.toLowerCase().includes(basicLand)) {
          set.add(basicLand);
        }
      });
    }
  }

  // Then filter out the lands from the deckCards and track the basic lands/types
  const deckLands = deckCards.filter(card => {
    // only keep lands that can be played directly as a land: front side or MDFC
    const isLandCard = isLand(getCardFront(card)) || (isLand(card) && isMdfc(card));
    if (isLandCard) {
      // first check if this land card is a 'Basic' land
      if ((card.type_line && card.type_line.includes("Basic"))
          // also check if this card has a standard basic land name
          // (don't need to wait to retrieve card data)
          || isStandardBasicLand(card)) {
        // add the land types of this card to the basic land Set
        addLandTypesToSet(card, deckBasicLands);
      }
      // add all basic land types of this card to the basic land type Set
      addLandTypesToSet(card, deckBasicTypes);
    }
    return isLandCard;
  });

  // initialize a map of color sources with zero count each
  const sourcesMap = ManaSourceColors.reduce((map, source) => { 
    map[source] = 0;
    return map;
  }, {});

  // Last, reduce the list of deck lands to a map of land sources and their counts
  return deckLands.reduce((sources, card) => {
    const name = card.name.toLowerCase();
    // first check if this card has a standard basic land name
    // (don't need to wait to retrieve card data)
    if (isStandardBasicLand(card)) {
      const mana = BasicLandColors[name];
      sources[mana] += 1;
    }
    //HACK: this is a hack because Scryfall returns `produced_mana` of ['C', 'G', 'U']
    // since 'Drowner of Truth' (the front face) creates an  Eldrazi Spawn token.
    else if (name === 'drowner of truth // drowned jungle') {
      sources['U'] += 1;
      sources['G'] += 1;
    }
    // if this card is a land and produces mana, increment the source count
    else if (card.produced_mana) {
      card.produced_mana.forEach(mana => {
        sources[mana] += 1;
      });
    }
    // if this is a fetch land for basic lands, increment the source count
    if (BasicFetchLands.hasOwnProperty(name)) {
      const basicfetches = BasicFetchLands[name];
      basicfetches.forEach(basicLand => {
        // only count basic fetchland as a source if the basic land also exists
        if (deckBasicLands.has(basicLand)) {
          const mana = BasicLandColors[basicLand];
          sources[mana] += 1;
        }
      });
    }
    // if this card is a fetch land for basic land *types*, increment the source count
    if (BasicTypeFetchLands.hasOwnProperty(name)) {
      const basictypefetches = BasicTypeFetchLands[name];
      basictypefetches.forEach(basicLand => {
        // only count basic fetchland as a source if the basic land also exists
        if (deckBasicTypes.has(basicLand)) {
          const mana = BasicLandColors[basicLand];
          sources[mana] += 1;
        }
      });
    }
    return sources;
  },
  sourcesMap);
}
